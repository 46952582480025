import moment from 'moment/min/moment-with-locales';
import React, { useContext, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  BottomSheetModal,
  BottomSheetBackdrop,
  BottomSheetFooter,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../common/libs/bottom-sheet';
import RNPickerSelect from 'react-native-picker-select';

import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { Tile } from '../common/tile';
import { getColumnStyle, getOrderPrice, getPriceString, getWeekDays, getPlanRatesNames } from '../common/utils.service';
import { AnimateService } from '../common/animate.service';
import { HeaderComponent } from '../common/header.component';
import { ButtonComponent } from '../common/button.component';
import { NumberInputComponent } from '../common/number-input.component';
import { ListComponent } from '../common/list';
import { MenuItemComponent } from '../common/menu-item.component';
import { DynamicFormComponent } from '../common/dynamic-form.component';
import * as Allergens from '../common/allergens';

const styles = StyleSheet.create({
  tileComponent: {
    title: {
      fontSize: 15,
      paddingRight: 50
    }
  },
  arrowLabel: {
    arrowLabel: {
      borderTopWidth: 20,
      borderBottomWidth: 20,
      borderRightColor: Theme.palette.accent
    },
    label: {
      maxWidth: '90%',
      marginRight: -8,
      padding: 6,
      backgroundColor: Theme.palette.accent,
    },
    labelTitle: {
      fontSize: 12,
    }
  },
  handle: {
    position: 'absolute',
    top: 10,
    zIndex: 100,
    alignSelf: 'center',
    width: (7.5 * Theme.width ) / 100,
    height: 4,
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  carouselItem: {
    media: {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15
    }
  },
  menuList: {
    container: {
      marginTop: -10
    },
    sectionHeader: {
      title: {
        textAlign: 'center'
      }
    }
  }
});

export const MenuComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const itemDetailBottomSheetModalRef = useRef(null);
  //const filterBottomSheetModalRef = useRef(null);
  const [order, setOrder] = useState(route.params?.order || []);
  const [itemDetail, setItemDetail] = useState(null);
  const snapPoints = useMemo(() => ['60%', '90%'], []);
  //const [filter, setFilter] = useState(null);
  const { guest, group, service, plan, date, type } = route.params;
  //const [menuItems, setMenuItems] = useState(service.menu);
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState(-1);
  const [currentPlanBookings, setCurrentPlanBookings] = useState([]);
  const locale = getCurrentLocale();

  useEffect(() => {
    const r = [];
    const b = [];
    context.user?.bookings?.forEach(booking => {
      const [ ,group, service, plan] = booking.id.split('#');
      if (group === 'room' && booking.status === 'CONFIRMED' && booking.startDate <= date.start && booking.endDate >= date.start) {
        r.push({
          ...booking,
          plan
        });
      }
      if (group === route.params.group.id && service === route.params.service.id && plan === route.params.plan.id) {
        b.push(booking);
      }
    });
    if (!r.length) {
      r.unshift({
        label: translate('NO_ROOM'),
        value: -1
      });
    } else {
      r.unshift({
        label: translate('SELECT_ROOM'),
        value: -1
      });
    }
    setRooms(r);
    setCurrentPlanBookings(b);
  }, [context, date]);

  useEffect(() => {
    if (rooms.length === 1) {
      setRoom(rooms[0].value);
    } else if (rooms.length === 2) {
      setRooms([{
        ...rooms[1],
        value: rooms[1].id
      }]);
    }
  }, [rooms]);

  /*
  useEffect(() => {
    const filterMenuItems = async () => {
    };
    filterMenuItems();
  }, []);
  */

  // renders
  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);

  const dismissBottomSheets = () => {
    itemDetailBottomSheetModalRef.current?.dismiss();
    //filterBottomSheetModalRef.current?.dismiss();
  };

  const renderFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props}>
        <ButtonComponent safeArea={true} onPress={dismissBottomSheets} text={translate('CLOSE')} />
      </BottomSheetFooter>
    );
  }, []);

  const included = (id, planRates = [], planId = -1, currentBookings = [], currentOrder = []) => {
    if (planId === -1) return 0;
    if (!planRates.length) return 0;

    const foundRate = planRates.find(({plans}) => plans.includes(planId.toString().split('#')[3]));
    if (!foundRate) return 0;
    if (foundRate.weekdays && !foundRate.weekdays.includes(moment(date.start).day())) return 0;

    let totalAmount = foundRate.amount;
    if (foundRate.per === 'attendee') {
      const room = rooms.find(r => r.id === planId);
      const attendees = room.attendees.adults + room.attendees.kids + room.attendees.infants;
      totalAmount = totalAmount * attendees;
    }
    const spanStart = moment(date.start).subtract(foundRate.every[0], foundRate.every[1]).format('YYYY-MM-DD');
    const spanEnd = moment(date.start).add(foundRate.every[0], foundRate.every[1]).format('YYYY-MM-DD');
    currentBookings.forEach(b => {
      if (foundRate.every[1] !== 'stay' && b.startDate >= spanStart && b.startDate <= spanEnd) {
        if (b.order) {
          b.order.forEach(i => totalAmount -= Math.min(i.amount, foundRate.course === i.planRate?.course && i.planRate?.amount || 0));
        } else {
          const amount = b.attendees.adults + b.attendees.kids + b.attendees.infants;
          totalAmount -= amount;
        }
      }
    });
    currentOrder.forEach(i => totalAmount -= Math.min(i.amount, foundRate.course === i.planRate?.course && i.planRate?.amount || 0));
    return Math.max(totalAmount, 0);
  };

  const updateOrder = (item, amount) => {
    if (!item) return false;
    if (item.customizations?.some(field => field.required && !item.customizationsForm?.[field.key])) {
      setItemDetail({...item, submitted: true });
      itemDetailBottomSheetModalRef.current?.present();
      return false;
    }
    const oldOrder = JSON.stringify(order);
    const extras = {};
    Object.keys(item.customizationsForm || {}).forEach(customizationKey => {
      const customization = item.customizations.find(({ key }) => customizationKey === key);
      const option = customization.options.find(({ value }) => item.customizationsForm[customizationKey] === value);
      extras[`${customizationKey}-${option.value}`] = {
        label: option.label[locale] || location.label,
        extraCharge: option.extraCharge
      };
    });
    const found = order.find(i => i.id === item.id && Object.keys(i.extras || {})?.every((key) => extras[key]));
    const amountToDiscount = included(item.id, item.planRates, room, currentPlanBookings, order);
    if (found) {
      if (!amount) {
        // FIXME updating order by removing items does not recalculate discount on non-removed items
        // FIXME updating order by removing items does not recalculate discount on non-removed items
        // FIXME updating order by removing items does not recalculate discount on non-removed items
        order.splice(order.indexOf(found), 1);
      } else if (found.amount !== amount) {
        found.amount = amount;
        if (found.planRate && amountToDiscount) {
          found.planRate.amount += amountToDiscount;
          found.planRate.amount = Math.min(found.amount, found.planRate.amount);
        }
      } else {
        // Nothing to do because amount has not changed
        return;
      }
    } else if (amount) {
      order.push({
        id: item.id,
        price: item.price,
        title: item.title,
        planRate: {
          ...(item.planRates?.find(({ plans }) => plans.includes(room?.toString().split('#')[3]))),
          amount: Math.min(amountToDiscount, amount)
        },
        extras,
        amount
      });
    }
    if (JSON.stringify(order) !== oldOrder) {
      setOrder([...order]);
    }
  };

  /*
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} onPress={() => filterBottomSheetModalRef.current?.present()} disabled={context.offline}>
          <MaterialCommunityIcons name='filter' color={Theme.palette.primaryContrast} size={20} />
          <Text style={[Theme.styles.text, Theme.styles.paddingLeft4, Theme.styles.fontSize16, Theme.styles.description, { color: Theme.palette.primaryContrast }]}>{ translate('FILTERS') }</Text>
        </TouchableOpacity>
      )
    });
  }, [navigation, filter]);
  */

  const list = [{
    title: translate('NO_SECTION'),
    data: service.menu?.filter(i => i.active && !i.section)
  }];

  service.menuSections?.forEach((section) => {
    list.push({
      title: section[locale],
      data: service.menu.filter(i => i.active && i.section === section.id)
    });
  });

  const onChangeItemCustomization = form => {
    setItemDetail({...itemDetail, customizationsForm: form});
  };

  const getOrderItemAmount = (item, withExtras) => {
    let amount = 0;
    order.forEach(i => {
      const itemId = i.id === item.id;
      const extras = withExtras ? Object.entries(item.customizationsForm || {}).every(([key, value]) => i.extras[`${key}-${value}`]) : true;
      if (itemId && extras) {
        amount += i.amount;
      }
    });
    return amount;
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <HeaderComponent title={service.title[locale]} subtitle={getOrderPrice(order)} completed />
      <HeaderComponent title={translate('ORDER')} subtitleComponent={(
        <View>
          <RNPickerSelect
            disabled={!!order.length || rooms.length <= 2}
            style={{
              inputWeb: {
                ...Theme.styles.inputWeb,
                ...Theme.styles.flex,
                ...Theme.styles.text,
                ...Theme.styles.textDark,
                ...Theme.styles.letterSpacing1,
                ...Theme.styles.textRight
              }
            }}
            placeholder={{}}
            value={room}
            onValueChange={setRoom}
            items={rooms.map(r => ({
              label: order.length && room === -1 ? translate('NO_ROOM') : r.label || (translate('ROOM') + ' ' + r.roomNumber),
              value: r.value || r.id
            }))}
          />
        </View>
      )} />
      { service.menuView === 'grid' ? (
        <FlatList
          style={Theme.styles.height}
          numColumns={2 * Theme.numberOfColumns}
          data={service.menu.filter(i => !i.weekdays || i.weekdays.includes(moment(date.start).day()))}
          keyExtractor={(item, index) => index}
          ListEmptyComponent={() => (
            <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.padding40]}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.textCenter]}>{ translate('NOTHING_FOUND') }</Text>
              <MaterialCommunityIcons name='room-service-outline' color={Theme.styles.text.color} size={50} style={[Theme.styles.marginTop20, Theme.styles.marginBottom20]} />
              <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textJustify]}>{ translate('NOTHING_FOUND_DESCRIPTION') }</Text>
            </View>
          )}
          renderItem={({ item, index }) => (
            <AnimateService
              key={index}
              properties={['opacity', 'left', 'top']}
              style={getColumnStyle(index, group.services.length, 2 * Theme.numberOfColumns)}
              delay={index * 150}
              duration={500}
              Component={() => (
                <Tile
                  media={item.media}
                  title={item.title[locale]}
                  label={included(item.id, item.planRates, room, currentPlanBookings) ? {
                    title: item.planRates.find(p => p.discount === 100 || p.price === 0) ? translate('INCLUDED_IN_ROOM_PLAN') : translate('SPECIAL_RATE_IN_ROOM_PLAN')
                  } : {
                    title: translate('PRICE'),
                    value: getPriceString(item.price)
                  }}
                  style={{ ...styles.tileComponent, ...(included(item.id, item.planRates, room, currentPlanBookings) ? styles.arrowLabel : {}) }}
                  badge={getOrderItemAmount(item)}
                  subtitle={service.menuSections?.length && service.menuSections?.find?.(s => s.id === item.section)?.[locale]|| item.subtitle && item.subtitle[locale] || ''}
                  onPress={() => { setItemDetail({...item}); itemDetailBottomSheetModalRef.current?.present(); }}
                />
              )}
            />
          )}
        />
      ) : (
        <ListComponent
          style={styles.menuList}
          list={list}
          renderItem={({ item }) => (<MenuItemComponent item={item} disabledCount={false} onPress={() => { setItemDetail({...item}); itemDetailBottomSheetModalRef.current?.present(); }} onCountChange={(amount) => updateOrder(item, amount)} defaultValue={getOrderItemAmount(item)} sections={service.menuSections || []} /> )}
        />
      )}
      <View style={Theme.styles.actionButtonBorder}>
        <View style={[Theme.styles.width, Theme.styles.actionButtonContainer, Theme.XL ? [Theme.styles.padding16, Theme.styles.paddingRight32] : null]}>
          <ButtonComponent onPress={() => navigation.navigate(`${type}ServiceOrder`, { ...route.params, guest, group, service, plan, type, date, room, order })} text={translate('NEXT')} disabled={!order.length} />
        </View>
      </View>
      <BottomSheetModal
        ref={itemDetailBottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderFooter}
        handleComponent={() =>
          <View>
            <View style={styles.handle} />
            { itemDetail ? (
              <Tile
                media={itemDetail.media}
                title={itemDetail.title[locale]}
                label={{
                  title: translate('PRICE'),
                  value: getPriceString(itemDetail.price)
                }}
                style={styles.carouselItem}
                subtitle={itemDetail.subtitle && itemDetail.subtitle[locale] || ''}
                onPress={() => {}}
              />
            ) : null
            }
          </View>
        }
      >
        { itemDetail ? (
          <BottomSheetScrollView>
            <View style={[Theme.styles.paddingLeft16, Theme.styles.paddingTop16, Theme.styles.row, Theme.styles.alignCenterCenter]}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.fontSize15, Theme.styles.textBold, Theme.styles.flex, Theme.styles.paddingRight8]}>{ translate('AMOUNT') }</Text>
              <NumberInputComponent onChange={(amount) => updateOrder(itemDetail, amount)} defaultValue={getOrderItemAmount(itemDetail, true)} />
            </View>
            <DynamicFormComponent
              template={itemDetail.customizations}
              formValue={itemDetail.customizationsForm || {}}
              onChange={onChangeItemCustomization}
              submitted={itemDetail.submitted}
            />
            { included(itemDetail.id, itemDetail.planRates, room, currentPlanBookings) ? (
              <View style={[Theme.styles.padding16, Theme.styles.marginTop16, Theme.styles.backgroundLight]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('SPECIAL_RATE_IN_DESCRIPTION') + ': ' }<Text style={Theme.styles.textBold}>{ getPlanRatesNames(itemDetail.planRates, context, locale) }</Text></Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description, Theme.styles.paddingTop8]}>{ translate('AVAILABLE_ON') + ': ' }<Text style={Theme.styles.textBold}>{ getWeekDays(itemDetail.weekdays, locale) }</Text></Text>
              </View>
            ) : null }
            <View style={Theme.styles.padding16}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ itemDetail.description[locale] }</Text>
            </View>
            <View style={[Theme.styles.padding16, Theme.styles.paddingTop0, Theme.styles.row, Theme.styles.wrap, Theme.styles.alignCenterSpaceBetween ]}>
              { Object.entries(itemDetail.allergens || {}).map(([allergen, value]) => value ? (
                <View key={allergen} style={[Theme.styles.paddingBottom6, Theme.styles.marginRight6]}>
                  { Allergens[allergen]({}) }
                </View>
              ) : null
              ) }
            </View>
          </BottomSheetScrollView>
        ) : null
        }
      </BottomSheetModal>
      {/*
      <BottomSheetModal
        ref={filterBottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderFooter}
      >
        <BottomSheetScrollView>
          <View style={Theme.styles.paddingLeft8}>
            <HeaderComponent title={translate('FILTERS')} />
          </View>
          <View style={Theme.styles.paddingBottom70}>
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>
      */}
    </View>
  );
};
