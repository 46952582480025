import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  BackHandler,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Image from '../common/libs/image/image';
import Toast from 'react-native-toast-message';
import {
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BottomSheetScrollView } from '../common/libs/bottom-sheet';

import * as API from '../common/api.service';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { DynamicFormComponent, conditionedTemplate } from '../common/dynamic-form.component';

const styles = StyleSheet.create({
  absolute: {
    position: 'absolute'
  },
  text: {
    textAlign: 'center',
    color: Theme.palette.primaryContrast,
    paddingVertical: 16,
    maxWidth: '90%'
  },
  textUnderline: {
    textDecorationLine: 'underline'
  },
  button: {
    width: '60%',
    minWidth: 250,
    backgroundColor: Theme.palette.darkContrast,
    marginVertical: 20,
    padding: 15,
    borderRadius: 24
  },
  dynamicForm: {
    textColor: {
      color: Theme.palette.primaryContrast,
    },
    input: {
      color: Theme.palette.primaryContrast,
    },
    inputs: {
      switch: {
        colors: {
          false: 'rgba(255, 255, 255, 0.6)'
        }
      },
      picker: {
        text: {
          color: Theme.palette.primaryContrast
        }
      }
    }
  },
  dynamicFormContainer: {
    flex: 1,
    width: Theme.width,
    maxWidth: Theme.XL ? 500 : Theme.width
  },
  containerXL: {
    maxWidth: 500
  },
  ...(Theme.styles.signUpScreen || {})
});

export const SignUpComponent = () => {
  const context = useContext(Context);
  const [login, setLogin] = useState(process.env.ALLOW_SIGNUP !== 'true');
  const [submitted, setSubmitted] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const snapPoints = useMemo(() => ['100%'], []);
  const bottomSheetModalRef = useRef(null);
  const currentLocale = getCurrentLocale();

  useEffect(() => {
    setLoading(false);
    setFormValue({});
    setSubmitted(false);
  }, [login, context.showSignUp]);

  useEffect(() => {
    setLogin(false);
    Toast.hide();
  }, [context.showSignUp]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      if (context.showSignUp) {
        bottomSheetModalRef.current.close();
        return true;
      }
      return false;
    });

    return () => backHandler.remove();
  }, [context.showSignUp]);

  const loginTemplate = [{
    key: 'userName',
    label: {
      [currentLocale]: translate(process.env.USERNAME_FIELD || 'USER_NAME')
    },
    required: true
  }, {
    key: 'password',
    label: {
      [currentLocale]: translate(process.env.PASSWORD_FIELD || 'PASSWORD')
    },
    required: true
  }];

  if (context.showSignUp) {
    bottomSheetModalRef.current?.present();
  }

  const handleSheetChanges = useCallback((index) => {
    if (index === -1 && context.showSignUp) {
      if (typeof context.showSignUp === 'function') {
        context.showSignUp(false);
      }
      API.setContext({
        ...context,
        showSignUp: false
      });
    }
  }, [context]);

  const confirm = async () => {
    try {
      setSubmitted(true);
      const template = (login ? loginTemplate : context.hotelConfig?.data?.guests?.accountFields || []).filter(field => conditionedTemplate(field, formValue));
      if (template.every(field => !field.required || formValue[field.key])) {
        Toast.hide();
        setLoading(true);
        let action = login ? API.connect : API.signUp;
        const data = {
          ...formValue,
          features: context.hotelConfig.data.features[formValue.admin ? 'admin' : 'guest'].map((f) => f.id),
          locale: currentLocale,
          username: formValue.userName || formValue[template.find(field => field.useAs === 'userName')?.key || 'fullName'],
          password: formValue.password || formValue[template.find(field => field.useAs === 'password')?.key || 'reservationNumber'] || formValue.email?.toLowerCase(),
        };
        context.user = await action(data);
        await Promise.all([
          AsyncStorage.setItem('userName', data.username),
          AsyncStorage.setItem('password', data.password),
          AsyncStorage.setItem('context.user', JSON.stringify(context.user)),
        ]);
        if (typeof context.showSignUp === 'function' || context.showSignUp?.then) {
          await context.showSignUp(true);
        }
        API.setContext({
          ...context,
          showSignUp: false
        });
        bottomSheetModalRef.current?.dismiss();
        setLoading(false);
      } else {
        return Toast.show({
          type: 'error',
          autoHide: false,
          text1: translate('FORM_SUBMIT_ERROR'),
          text2: translate('FORM_SUBMIT_ERROR_DESCRIPTION'),
        });
      }
    } catch(e) {
      if (e?.error?.statusCode === 409) {
        Toast.show({
          type: 'error',
          autoHide: false,
          text1: translate('USER_ALREADY_EXISTS'),
          text2: translate('USER_ALREADY_EXISTS_DESCRIPTION'),
        });
      } else {
        Toast.show({
          type: 'error',
          autoHide: false,
          text1: translate('UNKNOWN_ERROR'),
          text2: translate('UNKNOWN_ERROR_DESCRIPTION'),
        });
      }
      setLoading(false);
    }
  };

  return (
    <BottomSheetModal
      ref={bottomSheetModalRef}
      style={Theme.XL ? Theme.styles.padding0 : null}
      index={0}
      snapPoints={snapPoints}
      enablePanDownToClose={true}
      onChange={handleSheetChanges}
      handleComponent={() =>
        <View />
      }
    >
      <Image
        style={[styles.absolute, Theme.styles.fullWidth, Theme.styles.fullHeight]}
        resizeMode='cover'
        source={require('../../theme/assets/Update.png')}
      />
      <BottomSheetScrollView contentContainerStyle={[Theme.styles.alignCenterStart]}>
        <View style={[Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.paddingTop50, Theme.styles.paddingBottom30, Theme.XL ? styles.containerXL : null]}>
          <Text style={[Theme.styles.text, Theme.styles.fontSize20, styles.text]}>{ translate('SIGNUP_REQUIRED') }</Text>
          { process.env.ALLOW_SIGNUP === 'true' ? (
            <TouchableOpacity onPress={() => setLogin(!login)} style={Theme.styles.alignCenterCenter}>
              <Text style={[Theme.styles.text, Theme.styles.description, styles.text, styles.textUnderline]}>{ login ? translate('SIGNUP_INSTEAD') : translate('LOGIN_INSTEAD') }</Text>
            </TouchableOpacity>
          ) : null }
          <View style={styles.dynamicFormContainer}>
            { login ? (
              <DynamicFormComponent
                template={loginTemplate}
                formValue={formValue}
                onChange={form => setFormValue(form)}
                submitted={submitted}
                style={styles.dynamicForm}
                submit={confirm}
              />
            ) : (
              <DynamicFormComponent
                template={context.hotelConfig?.data?.guests?.accountFields || []}
                formValue={formValue}
                onChange={form => setFormValue(form)}
                submitted={submitted}
                style={styles.dynamicForm}
                submit={confirm}
                keyboardPadding={100}
              />
            )}
          </View>
          <TouchableOpacity onPress={confirm} style={[Theme.styles.alignCenterCenter, styles.button]} disabled={loading}>
            { loading ? (
              <ActivityIndicator color={Theme.palette.text} />
            ) : (
              <Text style={[Theme.styles.text, Theme.styles.buttonText]}>{translate('CONFIRM')}</Text>
            )}
          </TouchableOpacity>
        </View>
      </BottomSheetScrollView>
    </BottomSheetModal>
  );
};
