import React, {
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
  useState,
  useLayoutEffect
} from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../common/libs/bottom-sheet';
import useKeyboard from '@rnhooks/keyboard';

import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { HeaderComponent } from '../common/header.component';
import { ButtonComponent } from '../common/button.component';

const styles = StyleSheet.create({
  radioButtonContainer: (indent) => ({
    borderBottomWidth: .5,
    borderBottomColor: Theme.palette.backgroundLight,
    paddingLeft: 16 + (32 * indent)
  }),
  radioButton: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: Theme.palette.primary,
    alignItems: 'center',
    justifyContent: 'center'
  },
  activeRadioButton: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: Theme.palette.primary
  },
  badge: {
    position: 'absolute',
    backgroundColor: 'red',
    borderRadius: 7.5,
    paddingHorizontal: 2.5,
    minWidth: 15,
    height: 15,
    zIndex: 10,
    left: -10,
    top: -10
  },
  badgeText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 12
  }
});

export const BookingsFilterComponent = ({ navigation, onChange, filteredBookings }) => {
  const context = useContext(Context);
  const [ visible ] = useKeyboard();
  const bottomSheetModalRef = useRef(null);
  const [filter, setFilter] = useState(context.user.admin ? 'activity' : '');
  const snapPoints = useMemo(() => ['50%', '85%'], []);
  const locale = getCurrentLocale();

  useEffect(() => onChange(filter), [filter]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} onPress={() => bottomSheetModalRef.current?.present()} disabled={context.offline}>
          { filteredBookings > 0 ? (
            <View style={[styles.badge, Theme.styles.row, Theme.styles.alignCenterCenter]}>
              <Text style={styles.badgeText}>{ filteredBookings > 9 ? '9+' : filteredBookings }</Text>
            </View>
          ) : null
          }
          <MaterialCommunityIcons name='filter' color={Theme.palette.primaryContrast} size={20} />
          <Text style={[Theme.styles.text, Theme.styles.paddingLeft4, Theme.styles.fontSize16, Theme.styles.description, { color: Theme.palette.primaryContrast }]}>{ translate('FILTERS') }</Text>
        </TouchableOpacity>
      )
    });
  }, [navigation, filteredBookings]);

  /* Renderers */
  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);

  const renderFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props} >
        <ButtonComponent safeArea={!visible} text={translate('CLOSE')} onPress={() => bottomSheetModalRef.current?.dismiss()} />
      </BottomSheetFooter>
    );
  }, [visible]);

  const renderFilterRow = (row, expanded, parentId = '') => {
    const [expandedChildren, setExpandedChildren] = useState(false);
    const indent = parentId.split('#').length - 1;
    return (
      <View key={row.id}>
        { expanded ? (
          <TouchableOpacity key={row.id} onPress={() => setFilter(parentId + row.id)} style={[Theme.styles.row, Theme.styles.alignCenterStart, Theme.styles.padding8, Theme.styles.paddingRight16, styles.radioButtonContainer(indent)]}>
            <View style={styles.radioButton} >
              { (parentId + row.id).includes(filter) ? (
                <View style={styles.activeRadioButton} />
              ) : null
              }
            </View>
            <Text style={[Theme.styles.text, Theme.styles.paddingLeft8, Theme.styles.flex, indent > 2 ? Theme.styles.letterSpacing1: null]}>{ row.title[locale] || translate(row.title) }</Text>
            { context?.[row.id + 'Groups']?.data.length || row.services?.length || row.plans?.length > 1 ? (
              <TouchableOpacity onPress={() => setExpandedChildren(!expandedChildren)}>
                <MaterialCommunityIcons name={expandedChildren ? 'chevron-down' : 'chevron-right'} color={Theme.palette.primary} size={30} />
              </TouchableOpacity>
            ): null
            }
          </TouchableOpacity>
        ) : null
        }
        { context?.[row.id + 'Groups']?.data.map(childRow => renderFilterRow(childRow, expanded && expandedChildren, parentId + row.id + '#')) }
        { row.services?.map(childRow => renderFilterRow(childRow, expanded && expandedChildren, parentId + row.id + '#')) }
        { row.plans?.length > 1 ? row.plans.map(childRow => renderFilterRow(childRow, expanded && expandedChildren, parentId + row.id + '#')) : null }
      </View>
    );
  };

  return (
    <BottomSheetModal
      ref={bottomSheetModalRef}
      style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
      index={0}
      snapPoints={snapPoints}
      enablePanDownToClose={true}
      backdropComponent={renderBackdrop}
      footerComponent={renderFooter}
    >
      <BottomSheetScrollView>
        <View style={Theme.styles.paddingLeft8}>
          <HeaderComponent title={translate('FILTERS')} />
        </View>
        <View style={Theme.styles.paddingBottom70}>
          { !context.user.admin ? (
            <TouchableOpacity onPress={() => setFilter('')} style={[Theme.styles.row, Theme.styles.alignCenterStart, Theme.styles.padding8, Theme.styles.paddingRight16, styles.radioButtonContainer(0)]}>
              <View style={styles.radioButton} >
                { filter === '' ? (
                  <View style={styles.activeRadioButton} />
                ) : null
                }
              </View>
              <Text style={[Theme.styles.text, Theme.styles.paddingLeft8, Theme.styles.flex]}>{translate('ALL')}</Text>
            </TouchableOpacity>
          ) : null
          }
          { context.hotelConfig.data.features.guest.reduce((agg, feat) => {
            if (feat.id === 'services' || feat.componentId === 'services') agg.push(renderFilterRow({ id: feat.type, title: feat.id.toUpperCase()}, true));
            return agg;
          }, [])}
        </View>
      </BottomSheetScrollView>
    </BottomSheetModal>
  );
};

