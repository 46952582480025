import React, { useContext, useState } from 'react';
import {
  FlatList,
  View,
  Text,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Context } from '../Context';
import { Theme } from '../../theme';
import { translate, getCurrentLocale } from '../../lang';
import { Tile } from '../common/tile';
import { getColumnStyle, getOpeningHours, getDurationString, getLowestPrice, getNextRoute } from '../common/utils.service';
import { AnimateService } from '../common/animate.service';
import { HeaderComponent } from '../common/header.component';

export const ListComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const { guest, group, type } = route.params;
  // TODO allow pull to refresh
  const [servicesList] = useState((group.services || []).filter(service => service.active !== false));
  const locale = getCurrentLocale();

  const open = (item) => {
    const nextRoute = getNextRoute('ServiceList', item, locale);
    navigation.navigate(`${type}${nextRoute}`, { ...route.params, guest, group, service: item });
  };

  return (
    <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded] : null]}>
      { Theme.XL ? (
        <View style={Theme.styles.paddingLeft16}>
          <HeaderComponent title={group.title[locale]} />
        </View>
      ) : null }
      <FlatList
        style={[Theme.styles.height, Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop16] : null]}
        contentContainerStyle={!servicesList.length && Theme.styles.flex}
        numColumns={Theme.numberOfColumns}
        data={servicesList}
        keyExtractor={(item, index) => index}
        ListEmptyComponent={() => (
          <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.padding40]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.textCenter]}>{ translate('NO_CONTENT_YET') }</Text>
            <MaterialCommunityIcons name='kitesurfing' color={Theme.styles.text.color} size={50} style={[Theme.styles.marginTop20, Theme.styles.marginBottom20]} />
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textCenter]}>{ translate('WAIT_FOR_CONTENT') }</Text>
          </View>
        )}
        renderItem={({ item, index }) => (
          <AnimateService
            key={index}
            properties={['opacity', 'left', 'top']}
            style={getColumnStyle(index, servicesList.length, Theme.numberOfColumns, Theme.XL ? 32 : null)}
            delay={index * 150}
            duration={500}
            Component={() => (
              <Tile
                media={item.media}
                title={item.title[locale]}
                label={getLowestPrice(item.plans) ? {
                  title: translate('FROM'),
                  value: getLowestPrice(item.plans)
                } : null}
                subtitle={item.subtitle && item.subtitle[locale] || ''}
                caption={{ icon: 'timer', text: getOpeningHours(item.openingHours) || getDurationString(item.plans?.[0]?.duration, item.plans?.[0]?.dateRange && item.plans?.[0]?.dateRangeUnit === 'night' ? 'NIGHT(S)' : null) }}
                onPress={() => open(item)}
              />
            )}
          />
        )}
      />
    </View>
  );
};
