import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/elements';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Theme } from '../../theme';
import { translate, getCurrentLocale } from '../../lang';
import { Tile } from '../common/tile';
import { Context } from '../Context';
import { AnimateService } from '../common/animate.service';
import { getColumnStyle, getNextRoute } from '../common/utils.service';
import { HeaderComponent } from '../common/header.component';

const styles = StyleSheet.create({
  headerButton: {
    marginLeft: -15,
  }
});

export const GroupsComponent = ({ navigation, route, groups, type }) => {
  const context = useContext(Context);
  // TODO allow pull to refresh
  const [groupsList, setGroupsList] = useState((groups || []).filter(group => group.active !== false));
  const isFocused = useIsFocused();
  const locale = getCurrentLocale();
  const { guest } = (route.params || {});
  const openGroup = (group) => {
    const nextRoute = getNextRoute('ServiceGroups', group, locale);
    navigation.navigate(`${type}${nextRoute}`, {
      ...route.params,
      guest: guest || context.user,
      type,
      group,
      service: group.services?.[0]
    });
  };

  useEffect(() => {
    const loadGroups = async () => {
      const cachedGroups = await AsyncStorage.getItem(`context.hotel.${type}`);
      setGroupsList(JSON.parse(cachedGroups || '[]').filter(group => group.active !== false));
    };
    if (!groupsList || !groupsList.length) {
      loadGroups();
    }
  }, [groupsList]);

  useLayoutEffect(() => {
    if (route.params?.navigateTo === 'GuestDetail') {
      navigation.setOptions({
        headerLeft: () => (
          <HeaderBackButton
            style={styles.headerButton}
            label={translate('GUEST_DETAIL')}
            labelVisible={true}
            tintColor={Theme.palette.primaryContrast}
            onPress={navigation.goBack}
          />
        )
      });
    }
  }, [navigation, route]);

  return isFocused ? (
    <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded] : null]}>
      { Theme.XL ? (
        <View style={Theme.styles.paddingLeft16}>
          <HeaderComponent title={translate(type.toUpperCase() + '_TITLE')} />
        </View>
      ) : null}
      <FlatList
        style={[Theme.styles.height, Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop16] : null]}
        contentContainerStyle={(!groupsList || !groupsList.length) && Theme.styles.flex}
        numColumns={Theme.numberOfColumns}
        data={groupsList}
        keyExtractor={(item, index) => index}
        refreshing={!groupsList || !groupsList.length}
        ListEmptyComponent={() => (
          <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.padding40]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.textCenter]}>{ translate('NO_CONTENT_YET') }</Text>
            <MaterialCommunityIcons name='kitesurfing' color={Theme.styles.text.color} size={50} style={[Theme.styles.marginTop20, Theme.styles.marginBottom20]} />
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textCenter]}>{ translate('WAIT_FOR_CONTENT') }</Text>
          </View>
        )}
        renderItem={({ item, index }) => (
          <AnimateService
            key={index}
            properties={['opacity', 'left', 'top']}
            style={getColumnStyle(index, groupsList.length, Theme.numberOfColumns, Theme.XL ? 32 : null)}
            delay={index * 150}
            duration={500}
            Component={() => (
              <Tile
                media={item.media || item.services?.reduce((r, a) =>  r.concat(a.media), []) || []}
                title={item.title[locale]}
                subtitle={item.subtitle && item.subtitle[locale]}
                onPress={() => openGroup(item)}
              />
            )}
          />
        )}
      />
    </View>
  ) : (
    <View style={[Theme.height, Theme.width, Theme.styles.flex, Theme.styles.alignCenterCenter]}>
      <ActivityIndicator color={Theme.palette.primary} />
    </View>
  );
};

