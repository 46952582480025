import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { Context } from '../Context';
import { translate } from '../../lang';
import { MyBookingsComponent } from './my-bookings';
import { ServicesComponent } from '../services';
import { tabScreenOptions } from '../common/utils.service';

const Stack = createNativeStackNavigator();

export const BookingsComponent = () => {
  const context = useContext(Context);
  return (
    <Stack.Navigator
      screenOptions={tabScreenOptions[Platform.OS] || tabScreenOptions.mobile}
    >
      <Stack.Screen
        name="MyBookings"
        component={MyBookingsComponent}
        options={{
          title: translate('MY_BOOKINGS'),
          headerShadowVisible: false
        }}
      />
      { context.hotelConfig.data.features.guest.reduce((agg, feature) => {
        if (feature.id === 'services' || feature.componentId === 'services') agg.push(
          <Stack.Screen
            key={feature.id}
            name={`MyBookings${feature.name}`}
            options={{
              headerShown: false,
              title: translate(feature.id.toUpperCase())
            }}
          >

            { ({ navigation, route }) => <ServicesComponent navigation={navigation} route={route} type={feature.type} /> }
          </Stack.Screen>
        );
        return agg;
      }, [])}
    </Stack.Navigator>
  );
};
