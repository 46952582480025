import 'react-native-gesture-handler';
import './styles';
import React, { useEffect, useContext, useState } from 'react';
import { Platform, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import {
  BottomSheetModalProvider,
} from '@gorhom/bottom-sheet';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Notifications from 'expo-notifications';

import { Theme } from '../theme';
import { Context, defaultContext } from './Context';
import { LoginComponent } from './login';
import { UpdateComponent } from './update';
import { ServicesComponent } from './services';
import { BookingsComponent } from './bookings';
import { GuestsComponent } from './guests';
import { SignUpComponent } from './guests/signup';
import { ConfigComponent } from './config';
import { MoreComponent } from './more';
import { translate } from '../lang';
import * as API from './common/api.service';
import { toastConfig } from './common/notification.config';
import { OfflineBannerComponent } from './common/offline-banner.component';

const Components = {
  services: ServicesComponent,
  bookings: BookingsComponent,
  guests: GuestsComponent,
  config: ConfigComponent,
  more: MoreComponent,
};

const navigationRef = React.createRef();

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const screenOptions = {
  mobile: {
    headerShown: false,
    tabBarActiveTintColor: Theme.palette.primary
  },
  web: {
    headerShown: false,
    tabBarActiveTintColor: Theme.palette.primaryContrast,
    tabBarInactiveTintColor: Theme.palette.primaryContrast + 'B3',
    tabBarShowLabel: Theme.XL,
    tabBarStyle: {
      position: 'absolute',
      top: 0,
      maxWidth: 800,
      width: Theme.width - 320,
      marginHorizontal: 'auto',
      height: 64,
      background: 'transparent',
      border: 'none'
    }
  }
};

const MainComponent = ({ navigation, route }) => {
  const { user, offline, hotelConfig } = useContext(Context);
  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  useEffect(() => {
    API.initializeNav(navigation);
  }, []);

  useEffect(() => {
    if (lastNotificationResponse) {
      API.onMessage(lastNotificationResponse.notification);
    }
  }, [lastNotificationResponse]);

  const features = hotelConfig.data.features[user?.admin ? 'admin' : 'guest'].filter(feat => !user || user.features?.includes(feat.id));

  return (
    <View style={Theme.styles.flex}>
      <Tab.Navigator screenOptions={screenOptions[Platform.OS] || screenOptions.mobile} >
        { features.map((feature) => {
          const Feature = Components[feature.componentId || feature.id];
          return (<Tab.Screen
            key={feature.id}
            name={feature.name}
            options={{
              title: translate(feature.id.toUpperCase()),
              tabBarIcon: ({ color, size }) => (
                <MaterialCommunityIcons name={feature.icon} color={color} size={size} />
              ),
              tabBarBadge: feature.id === 'bookings' && user?.bookings?.length || null
            }}
          >
            {() => <Feature navigation={navigation} route={route} type={feature.type} />}
          </Tab.Screen>);
        })}
      </Tab.Navigator>
      { offline ? (<OfflineBannerComponent />) : null}
    </View>
  );
};

const linking = {
  prefixes: [],
  config: {
    screens: {
      Login: '',
      Update: '/Update',
      Main: '/App',
    }
  },
};

const App = () => {
  const ctx = useState({ ...defaultContext });
  useEffect(() => {
    API.initializeCtx(ctx);
  }, []);
  return (
    <Context.Provider value={ctx[0]}>
      <ActionSheetProvider>
        <GestureHandlerRootView style={Theme.styles.flex}>
          <SafeAreaProvider>
            <NavigationContainer
              ref={navigationRef}
              linking={linking}
              documentTitle={{
                formatter: (options, route) =>
                  `${options?.title ?? route?.name} - ${process.env.APP_DISPLAY_NAME}`.replace('Login - ', ''),
              }}>
              <BottomSheetModalProvider>
                <Stack.Navigator screenOptions={{ headerShown: false }}>
                  <Stack.Screen
                    name='Login'
                    options={{ gestureEnabled: false }}
                  >
                    { ({ navigation, route }) => <LoginComponent navigation={navigation} route={route} ctx={ctx} /> }
                  </Stack.Screen>
                  <Stack.Screen
                    name='Update'
                    component={UpdateComponent}
                    options={{ gestureEnabled: false }}
                  />
                  <Stack.Screen
                    name='Main'
                    component={MainComponent}
                    options={{ gestureEnabled: false }}
                  />
                </Stack.Navigator>
                <SignUpComponent />
              </BottomSheetModalProvider>
            </NavigationContainer>
          </SafeAreaProvider>
        </GestureHandlerRootView>
      </ActionSheetProvider>
      <Toast config={toastConfig}/>
    </Context.Provider>
  );
};

export default App;
