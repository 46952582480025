import React from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { translate } from '../../lang';

import { Theme } from '../../theme';
import Image from './libs/image/image';

const styles = StyleSheet.create({
  media: {
    width: 25,
    height: 25,
    marginRight: 8
  }
});

export const alcohol = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Alcohol.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('ALCOHOL') }</Text>
      ) : null
      }
    </View>
  );
};

export const molluscs = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Molluscs.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('MOLLUSCS') }</Text>
      ) : null
      }
    </View>
  );
};

export const soy = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Soy.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('SOY') }</Text>
      ) : null
      }
    </View>
  );
};

export const nuts = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Nuts.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('NUTS') }</Text>
      ) : null
      }
    </View>
  );
};

export const sulphites = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Sulphites.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('SULPHITES') }</Text>
      ) : null
      }
    </View>
  );
};

export const corn = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Corn.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('CORN') }</Text>
      ) : null
      }
    </View>
  );
};

export const fish = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Fish.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('FISH') }</Text>
      ) : null
      }
    </View>
  );
};

export const vegan = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Vegan.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('VEGAN') }</Text>
      ) : null
      }
    </View>
  );
};

export const peanuts = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Peanuts.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('PEANUTS') }</Text>
      ) : null
      }
    </View>
  );
};

export const shellfish = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Shellfish.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('SHELLFISH') }</Text>
      ) : null
      }
    </View>
  );
};

export const meat = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Meat.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('MEAT') }</Text>
      ) : null
      }
    </View>
  );
};

export const pork = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Pork.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('PORK') }</Text>
      ) : null
      }
    </View>
  );
};

export const lactose = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Lactose.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('LACTOSE') }</Text>
      ) : null
      }
    </View>
  );
};

export const sugar = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Sugar.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('SUGAR') }</Text>
      ) : null
      }
    </View>
  );
};

export const gmo = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/GMO.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('GMO') }</Text>
      ) : null
      }
    </View>
  );
};

export const mustard = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Mustard.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('MUSTARD') }</Text>
      ) : null
      }
    </View>
  );
};

export const gluten = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Gluten.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('GLUTEN') }</Text>
      ) : null
      }
    </View>
  );
};

export const egg = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Egg.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('EGG') }</Text>
      ) : null
      }
    </View>
  );
};

export const celery = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Celery.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('CELERY') }</Text>
      ) : null
      }
    </View>
  );
};

export const transfat = ({style, hideText}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.paddingTop3, Theme.styles.paddingBottom3]}>
      <Image
        style={[styles.media, style]}
        source={require('../../theme/assets/Transfat.png')}
      />
      { !hideText ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description]}>{ translate('TRANSFAT') }</Text>
      ) : null
      }
    </View>
  );
};

