import moment from 'moment/min/moment-with-locales';
import { Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as API from './api.service';
import { translate } from '../../lang';
import { Theme } from '../../theme';
import { default as calendarDict } from '../../lang/.calendar.dictionary.json';

export const getDuration = (date, plan, unit = 'minutes') => {
  if (plan.dateRange && date.start && date.end) {
    return moment(date.end).diff(date.start, unit);
  } else {
    return plan.duration;
  }
};

export const getDurationString = (duration, label) => {
  if (!duration) return;
  if (duration < 60) {
    return `${duration}${translate('MIN')}`;
  } else if (duration < 1440) {
    const hours = Math.floor(duration / 60);
    const minutes = duration - hours * 60;
    let string = `${hours}${translate('HOURS')}`;
    if (minutes) {
      string += ` ${minutes}${translate('MIN')}`;
    }
    return string;
  } else if (duration === 1440) {
    if (label === 'NIGHT(S)') {
      return translate('PER_NIGHT');
    } else {
      return translate('FULL_DAY');
    }
  } else {
    const days = Math.floor(duration / 1440);
    return `${days} ${translate(label || 'DAYS')}`;
  }
};

export const getOpeningHours = (openingHours) => {
  if (!openingHours) return;
  return openingHours.map(getTimeString).join(' - ');
};

export const getDateString = (date, slot, plan) => {
  let dateString = moment(date.start).format('ll');
  if (date.start && date.end && date.start !== date.end) {
    dateString = `${translate('FROM')}: ${dateString}\n${translate('TO')}: ${moment(date.end).format('ll')}`;
  } else if (slot) {
    dateString += `\n${moment.utc(slot.startTime).format('hh:mm A')} - ${moment.utc(slot.startTime).add(plan.duration, 'minutes').format('hh:mm A')}`;
  }
  return dateString;
};

export const getRecurringDateString = ({ periodicity, startDate }) => {
  const date = moment(startDate, 'YYYY-MM-DD');
  switch (periodicity) {
  default:
  case 'never':
    return date.format('LL');
  case 'daily':
    return translate('EVERY_DAY');
  case 'weekly':
    return translate('EVERY_WEEKDAY', { day : date.format('dddd') });
  case 'monthly':
    return translate('EVERY_MONTH', { day : date.format('D') });
  case 'yearly':
    return translate('EVERY_YEAR', {
      day : getDateStringWithoutYear(date)
    });
  }
};

export const getDateStringWithoutYear = (date, format = 'LL') => {
  date = moment(date);
  return date.format(format)
    .replace(date.format('YYYY'), '')
    .replace(/ de $/, '')
    .replace(/, $/, '')
    .trim();
};

export const getRangePrice = (range, attendees = { adults: 1, kids: 0, infants: 0 }) => {
  const totalAttendees = attendees.adults + attendees.kids + attendees.infants;
  let price = 0;
  if (!range) return 0;
  if (range.basis === 'groupSize') {
    const groupSizePrice = range.groups?.find(({min, max}) => !(max < totalAttendees || min > totalAttendees))?.price || range.groupPrice || 0;
    if (range.groupPriceBasis === 'person') {
      price += groupSizePrice * totalAttendees;
    } else {
      price += groupSizePrice;
    }
  } else {
    const baseAdults = range.baseAdults > 0 ? 1 : 0;
    const baseKids = range.baseKids > 0 ? 1 : 0;
    const baseInfants = range.baseInfants > 0 ? 1 : 0;
    price +=
      Math.min(attendees.adults + 1, 1) * range.baseAdults +
      (parseFloat(range.extraAdult[attendees.adults]) || Math.max(attendees.adults - baseAdults, 0) * range.extraAdult || 0) +
      Math.min(attendees.kids, 1) * range.baseKids +
      (parseFloat(range.extraKid[attendees.kids]) || Math.max(attendees.kids - baseKids, 0) * range.extraKid || 0) +
      Math.min(attendees.infants, 1) * range.baseInfants +
      (parseFloat(range.extraInfant[attendees.infants]) || Math.max(attendees.infants - baseInfants, 0) * range.extraInfant || 0);
  }
  return price;
};

export const getFinalPrice = async (group, date, total, attendees = { adults: 1, kids: 0, infants: 0 }, discount = 0) => {
  if (total && group.externalCrud?._price) {
    const result = await API.crud({
      operation: '_custom',
      custom: group.externalCrud._price,
      query: [date, total]
    });
    if (group.externalCrud._price.addOns) {
      group.externalCrud._price.addOns.forEach(addOn => {
        switch (`${addOn.type}-${addOn.basis}`) {
        case 'constant-day':
          result.grandTotal += moment(date.end).diff(date.start, 'days') * (
            addOn.attendees.adults * attendees.adults +
            addOn.attendees.kids * attendees.kids +
            addOn.attendees.infants * attendees.infants
          );
          break;
        default:
          console.warn('Price addOn not setup');
          break;
        }
      });
    }
    return result.grandTotal;
  }
  return total * ((100 - discount) / 100);
};

export const getPlanPrice = (plan, attendees, date, additional = {}) => {
  if (!plan) return 0;
  if (plan.price && !plan.pricingRanges) return plan.price; // TODO DEPRECATE THIS
  // Getting minimum price by default
  let pricingRanges = plan.pricingRanges?.length ? [plan.pricingRanges?.reduce((previous, current) => {
    return getRangePrice(current, attendees) < getRangePrice(previous, attendees) ? current : previous;
  })] : [];
  if (plan.dateRange && date?.start && date?.end) {
    pricingRanges = [];
    let day = moment(date.start);
    const finalDate = moment(date.end || date.start);
    while (day < finalDate || plan.dateRangeUnit === 'day' && day === finalDate) {
      pricingRanges.push(plan.pricingRanges.find(range => conflictingDateRange(date, range)));
      day = day.add(1, 'day');
    }
  }

  let totalPeriodPrice = pricingRanges.reduce((agg, pricingRange) => {
    if (!pricingRange) return agg;
    agg += getRangePrice(pricingRange, attendees);
    return agg;
  }, 0);
  plan.additionalInformation?.forEach(field => {
    if (field.priceMultiplier) {
      totalPeriodPrice = totalPeriodPrice * (additional[field.key] || 1);
    }
  });
  return plan.dateRangeBasis === 'day' ? totalPeriodPrice : totalPeriodPrice / pricingRanges.length;
};

export const getLowestPrice = (plans = []) => {
  let lowest = getPlanPrice(plans[0]) || 0;
  plans.forEach(plan => {
    const price = getPlanPrice(plan);
    if (price < lowest) lowest = price;
  });
  return lowest ? getPriceString(lowest) : 0;
};

export const getOrderPrice = (order) => {
  let price = 0;
  order.forEach(orderItem => {
    price += (orderItem.price) * orderItem.amount;
    Object.values(orderItem.extras || {}).forEach(extra => price += orderItem.amount * (extra.extraCharge || 0));
    if (orderItem.planRate?.amount) {
      price -= getDiscount(orderItem.price, orderItem.planRate, orderItem.amount);
    }
  });
  return getPriceString(price);
};

export const getPriceString = (price, currency = '$') => {
  if (typeof price === 'string') return price;
  if (price || price === 0) {
    // TODO Format this using locale!!
    // TODO Format this using locale!!
    // TODO Format this using locale!!
    return currency + price.toFixed(2);
  }
};

export const getTimeString = (time) => {
  if (time >= 24) time = 0;
  const hour = Math.floor(time);
  return moment().set('hour', hour).set('minute', (time - hour) * 60).format('LT');
};

export const getTimeValue = (time) => {
  const [hours, minutes] = moment.utc(time).format('HH:mm').split(':').map(Number);
  return hours + (minutes / 60);
};

export const fString = (str = '', replacements = {}) => {
  const placeholders = str.match(/\$\{[a-zA-Z1-9_.]+\}/g) || [];
  placeholders.forEach(placeholder => {
    const path = placeholder.match(/\${(.+)}/)[1] || '';
    let value = replacements;
    path.split('.').forEach(pathPart => {
      value = value[pathPart];
    });
    str = str.replace(placeholder, value);
  });
  return str;
};

export const getBookingContext = (booking, context) => {
  const [type, groupId, serviceId, planId] = booking.id.split('#');
  const group = context[`${type}Groups`]?.data?.find(g => g.id === groupId);
  const service = group?.services?.find(s => s.id === serviceId);
  const plan = service?.plans?.find(p => p.id === planId);
  return {
    type,
    guest: context.guests?.find(g => g.id === booking.accountId) || context.user,
    group,
    service,
    plan
  };
};

export const getColumnStyle = (index, length, columns, gap = 3) => {
  let style = { ...Theme.styles.itemSeparator, borderBottomWidth: gap };
  const rightBorder = { borderRightWidth: gap };
  const leftBorder = { borderLeftWidth: gap };
  if (columns === 1) return style;
  const mod = index % columns;
  if (mod < columns - 1 && !index === length - 1) {
    style = { ...style, ...rightBorder };
  }
  if (mod > 0) {
    style = { ...style, ...leftBorder };
  }
  return style;
};

export const tabScreenOptions = {
  mobile: {
    headerStyle: {
      backgroundColor: Theme.palette.primary
    },
    headerTitleStyle: {
      color: Theme.palette.primaryContrast
    },
    headerTintColor: Theme.palette.primaryContrast
  },
  /*
  web: {
    headerStyle: {
      backgroundColor: Theme.palette.primaryContrast,
      textColor: Theme.palette.primary,
      boxShadow: '0 0 27px 0 rgb(0 0 0 / 23%)',
      zIndex: 1
    },
    headerTitleStyle: {
      color: Theme.palette.primary
    },
    headerTintColor: Theme.palette.primary
  }
  */
};

export const getWeekDays = (weekdays, locale) => {
  if (!weekdays || weekdays.length === 7) return translate('EVERY_DAY');
  if (weekdays.length === 0) return translate('NOT_AVAILABLE');
  let text = '';
  weekdays.forEach((day, idx) => {
    if (idx) {
      if (idx >= weekdays.length - 1) {
        // Last day
        text += ` ${translate('AND')} `;
      } else {
        text += ', ';
      }
    }
    text += calendarDict[locale].dayNames[day];
  });
  return text;
};

export const intersect = (a = [], b = []) => {
  var setB = new Set(b);
  return [...new Set(a)].filter(x => setB.has(x));
};

export const setPlansByDate = async (bookings = [], context = {}) => {
  const plans = {};
  bookings.forEach(booking => {
    const [type, group, , plan] = booking.id.split('#');
    if (type === 'service' && group === 'room' && booking.status === 'CONFIRMED') {
      for (const d = moment(booking.startDate); d.isSameOrBefore(booking.endDate); d.add(1, 'days')) {
        plans[d.format('YYYY-MM-DD')] = plans[d.format('YYYY-MM-DD')] || {};
        plans[d.format('YYYY-MM-DD')][plan] = booking.attendees;
      }
    }
  });
  context.plansByDate = plans;
  await AsyncStorage.setItem('context.plansByDate', JSON.stringify(plans));
  return plans;
};

export const getPlansByDate = async (context = {}) => {
  if (context.plansByDate) return context.plansByDate;
  return setPlansByDate(context.user?.bookings);
};

export const isItemIncluded = (item, plansByDate, filter) => {
  if (!filter) return true;
  if (item.planRates && !(plansByDate?.[filter]?.length)) return false;
  return intersect(Object.keys(item.planRates), plansByDate?.[filter]).length && (!item.weekdays || item.weekdays.includes(moment(filter).day()));
};

export const pick = (obj, ...keys) => Object.fromEntries(keys.filter(key => key in obj).map(key => [key, obj[key]]));

export const getPlanRatesNames = (planRates = [], context, locale) => {
  const names = new Set();
  planRates.forEach(({ plans }) => {
    [context.serviceGroups, context.activityGroups].forEach(group => {
      group?.data?.forEach(({ services }) => {
        services.forEach((service) => {
          service.plans.forEach(plan => {
            if (plans.includes(plan.id)) {
              names.add(plan.title[locale]);
            }
          });
        });
      });
    });
  });
  const result = [...names];
  const last = result.pop();
  return `${result.join(', ')} ${translate('OR').toLowerCase()} ${last}`;
};

export const getDiscount = (price, planRate, amount = 1) => {
  return Math.min(amount, planRate.amount) * ((planRate.discount / 100) || (1 - planRate.price)) * price;
};

export const getDiscountPrice = (price, planRate, amount) => {
  return '-' + getPriceString(getDiscount(price, planRate, amount));
};

export const conflictingDateRange = (target, other) => {
  return !(other.end < target.start || other.start > (target.end || target.start + (target.duration || 0)));
};

export const openLink = async (link) => {
  const supported = await Linking.canOpenURL(link);
  if (supported) return Linking.openURL(link);
};

export const getNextRoute = (current, config) => {
  const routes = ['ServiceGroups', 'ServiceList', 'ServiceDetail', 'ServiceCalendar', 'ServiceAttendees', 'ServiceMenu', 'ServiceAdditionalInfo', 'ServiceOrder'];
  if (config.services?.length === 1) {
    config = {
      ...config,
      ...config.services[0]
    };
  }
  const routeConditions = [true, config.services?.length > 1, !!config.plans?.length, config.needsDates, config.needsAttendees, config.type === 'menu', !!config.additionalInformation?.length, config.type === 'menu'];
  const currentIndex = routes.indexOf(current);

  let nextRoute = '';
  let idx = currentIndex + 1;

  while (idx < routes.length && nextRoute === '') {
    if (routeConditions[idx]) nextRoute = routes[idx];
    idx++;
  }

  return  nextRoute;
};

export const syncMedia = async (item = { media: [] }, mediaToUpload = []) => {
  const originalMedia = item.media || [];
  item.media = await Promise.all(mediaToUpload.map(async (current) => {
    if (current.toUpload) {
      return {
        src: await API.uploadFile(current),
        type: current.type
      };
    } else {
      return current;
    }
  }));
  await Promise.all(originalMedia.map(current => {
    if (!item.media.includes(current) && current.src && !current.toUpload) {
      return API.crud({
        operation: '_deleteFile',
        table: 'S3',
        query: {
          key: current.src
        }
      });
    }
  }));
};
