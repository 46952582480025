import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Theme } from '../../theme';

const styles = StyleSheet.create({
  bottom: {
    position: 'absolute',
    bottom: 0
  },
  defaultText: {
    color: Theme.palette.accentContrast
  },
  success: {
    backgroundColor: Theme.palette.success,
    color: Theme.palette.successContrast
  },
  warning: {
    backgroundColor: Theme.palette.warning,
    color: Theme.palette.warningContrast
  },
  error: {
    backgroundColor: Theme.palette.error,
    color: Theme.palette.errorContrast
  },
  inline: {
    width: 'auto',
    height: 'auto',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5
  },
  inlineText: {
    letterSpacing: 1
  },
  outlined: {
    success: {
      borderColor: Theme.palette.success,
      backgroundColor: 'transparent',
      borderWidth: 1,
      text: {
        backgroundColor: 'transparent',
        color: Theme.palette.success
      }
    },
    warning: {
      borderColor: Theme.palette.warning,
      backgroundColor: 'transparent',
      borderWidth: 1,
      text: {
        backgroundColor: 'transparent',
        color: Theme.palette.warning
      }
    },
    error: {
      borderColor: Theme.palette.error,
      backgroundColor: 'transparent',
      borderWidth: 1,
      text: {
        backgroundColor: 'transparent',
        color: Theme.palette.error
      }
    }
  },
  safeArea: {
    paddingBottom: 10,
    height: 62
  }
});

export const ButtonComponent = ({
  children,
  bottom,
  disabled,
  loading,
  onPress,
  text,
  type,
  safeArea,
  inline,
  outlined
}) => {
  const { bottom: paddingBottom } = useSafeAreaInsets();
  if (!paddingBottom) safeArea = false;
  return (
    <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.button, styles[type], disabled ? Theme.styles.buttonDisabled : null, bottom ? styles.bottom : null, safeArea ? styles.safeArea : null, inline ? styles.inline : null, outlined ? styles.outlined[type || 'warning'] : null]} onPress={onPress} disabled={disabled || loading}>
      { loading ? (
        <ActivityIndicator color={outlined ? styles.outlined[type || 'warning'].text.color : Theme.styles.buttonDisabled.color} />
      ) : children ? children : (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.buttonText, type ? styles[type] : styles.defaultText, disabled ? Theme.styles.buttonDisabled : null, inline ? styles.inlineText : null, outlined ? styles.outlined[type || 'warning'].text : null]}>
          { text }
        </Text>
      )
      }
    </TouchableOpacity>
  );
};
