import React, { useLayoutEffect, useContext, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import CheckBox from 'expo-checkbox';
import Carousel from 'react-native-snap-carousel';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';
import uuid from 'react-native-uuid';
import { useActionSheet } from '@expo/react-native-action-sheet';

import * as API from '../../common/api.service';
import { Theme } from '../../../theme';
import { getCurrentLocale, translate } from '../../../lang';
import { CarouselItem } from '../../common/tile';
import { Context } from '../../Context';
import { LocalizedInput } from '../../common/localized-input.component';
import { HeaderComponent } from '../../common/header.component';
import { ButtonComponent } from '../../common/button.component';
import { InlineInputComponent } from '../../common/inline-input.component';
import { openMediaPicker } from '../../common/media.service';
import { Picker } from '../../common/libs/picker/picker';
import * as Allergens from '../../common/allergens';
import { DynamicFieldComponent } from '../../common/dynamic-field.component';

const styles = StyleSheet.create({
  media: {
    height: 200,
    paddingHorizontal: 8
  },
  menuItemMedia: {
    marginLeft: -8
  }
});

const DEFAULT_MENU_ITEM = {
  active: true,
  type: 'food',
  media: [{
    src: 'restaurant_0.jpg',
    type: 'image'
  }],
  planRates: []
};

export const ContentMenuItemComponent = ({ navigation, route }) => {
  let { groups, group, service, menuItem, menuSections, key } = (route.params || {});
  menuItem = menuItem || { ...DEFAULT_MENU_ITEM };
  const langs = process.env.LANGS.split(',');
  const context = useContext(Context);
  const locale = getCurrentLocale();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(menuItem?.active !== false);
  const [type, setType] = useState(menuItem?.type || 'food');
  const [section, setSection] = useState(menuItem?.section || menuSections?.[0] || null);
  const [title, setTitle] = useState(menuItem?.title || {});
  const [description, setDescription] = useState(menuItem?.description || {});
  const [price, setPrice] = useState(menuItem?.price || 0);
  const [stock, setStock] = useState(menuItem?.stock || { infinity: true });
  const [media, setMedia] = useState(menuItem?.media || []);
  const [allergens, setAllergens] = useState(menuItem?.allergens || {});
  const [customizations, setCustomizations] = useState(menuItem?.customizations || []);
  const { showActionSheetWithOptions } = useActionSheet();

  const syncMedia = async (item = { media: [] }, mediaToUpload = []) => {
    const originalMedia = item.media || [];
    item.media = await Promise.all(mediaToUpload.map(async (current) => {
      if (current.toUpload) {
        return {
          src: await API.uploadFile(current),
          type: current.type
        };
      } else {
        return current;
      }
    }));
    await Promise.all(originalMedia.map(current => {
      if (!item.media.includes(current) && current.src && !current.toUpload) {
        return API.crud({
          operation: '_deleteFile',
          table: 'S3',
          query: {
            key: current.src
          }
        });
      }
    }));
  };

  const save = () => {
    setLoading(true);
    setTimeout(async () => {
      // Making all this logic async to allow loading spinner to change
      menuItem = menuItem || {};
      menuItem.type = type;
      menuItem.section = section;
      menuItem.title = title;
      menuItem.active = active;
      menuItem.description = description;
      menuItem.price = price;
      menuItem.allergens = allergens;
      menuItem.customizations = customizations;
      let uploadMedia = [syncMedia(menuItem, media)];
      await Promise.all(uploadMedia);
      if (!menuItem.id) {
        // Creating new menuItem
        service.menu = service.menu || [];
        service.menu.push({
          id: uuid.v4(),
          ...menuItem
        });
      }
      await (API.crud({
        operation: '_update',
        table: 'hotels',
        query: context[key]
      })).finally(() => setLoading(false));
      navigation.navigate('ContentDetail', {
        ...route.params,
        groups,
        group,
        service
      });
    }, 0);
  };

  const removeMenuItem = () => {
    return Toast.show({
      type: 'action',
      autoHide: false,
      text1: translate('CONFIRM_ACTION'),
      text2: translate('CONFIRM_ACTION_DESCRIPTION'),
      props: {
        actions: [{
          text: translate('NO'),
          onPress: Toast.hide
        }, {
          text: translate('YES'),
          class: 'error',
          onPress: async () => {
            setLoading(true);
            const idx = service.menu.findIndex(p => p.id === menuItem.id);
            service.menu.splice(idx, 1);
            await (API.crud({
              operation: '_update',
              table: 'hotels',
              query: context[key]
            })).finally(() => setLoading(false));
            Toast.hide();
            navigation.navigate('ContentDetail', {
              ...route.params,
              groups,
              group,
              service
            });
          }
        }]
      }
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} onPress={save} disabled={loading}>
          { loading ? (
            <ActivityIndicator color={Theme.palette.primaryContrast} />
          ) : (
            <MaterialCommunityIcons name='check' color={Theme.palette.primaryContrast} size={25} />
          )}
        </TouchableOpacity>
      ),
    });
  }, [
    navigation,
    loading,
    type,
    section,
    title,
    active,
    description,
    price,
    media,
    customizations,
    allergens
  ]);

  const confirmRemove = (array, setArray) => {
    return ({ item }) => {
      return Toast.show({
        type: 'action',
        autoHide: false,
        text1: translate('CONFIRM_ACTION'),
        text2: translate('CONFIRM_ACTION_DESCRIPTION'),
        props: {
          actions: [{
            text: translate('NO'),
            onPress: Toast.hide
          }, {
            text: translate('YES'),
            class: 'error',
            onPress: () => {
              array.splice(array.indexOf(item), 1);
              if (setArray) setArray([...array]);
              Toast.hide();
            }
          }]
        }
      });
    };
  };

  const addMedia = async () => {
    try {
      const { type, extension, uri } = await openMediaPicker(showActionSheetWithOptions);
      const toUpload = {
        source: { uri },
        toUpload: true,
        extension,
        type
      };
      setMedia([...media, toUpload]);
    } catch(e) {
      if (e) {
        Toast.show({
          type: 'error',
          text1: translate('MEDIA_ERROR'),
          text2: translate('MEDIA_ERROR_DESCRIPTION')
        });
      }
    }
  };


  const deleteCustomization = idx => () => {
    customizations.splice(idx, 1);
    setCustomizations([...customizations]);
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <ScrollView style={Theme.styles.height} contentContainerStyle={[Theme.styles.column, Theme.styles.alignStretchStart]}>
        <HeaderComponent title={menuItem.id ? `${translate('EDIT')} "${menuItem.title[langs[0]]}"` : translate('NEW_MENU_ITEM')} subtitleComponent={(
          <Switch
            style={Theme.styles.activeButton}
            onValueChange={setActive}
            value={active}
          />
        )}/>
        <View style={Theme.styles.paddingHorizontal8}>
          <Picker
            placeholder={{}}
            value={type}
            onValueChange={setType}
            label={translate('TYPE')}
            items={[{
              label: translate('FOOD'),
              value: 'food'
            }, {
              label: translate('ITEM'),
              value: 'item'
            }]}
          >
            <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
              <View>
                <Text style={Theme.styles.inputLabel}>{translate('TYPE')}</Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(type.toUpperCase() || 'DEFAULT')}</Text>
              </View>
            </View>
          </Picker>
          <View style={Theme.styles.paddingBottom8} />
          <Picker
            placeholder={{}}
            value={section}
            onValueChange={setSection}
            label={translate('SECTION')}
            items={[{ id: null, label: translate('NO_SECTION') }].concat(menuSections?.map(section => ({
              label: section?.[locale],
              value: section.id
            })) || [])}
          >
            <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
              <View>
                <Text style={Theme.styles.inputLabel}>{translate('SECTION')}</Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{menuSections.find(s => s.id === section)?.[locale] || translate('NO_SECTION')}</Text>
              </View>
            </View>
          </Picker>
        </View>
        <LocalizedInput value={title} onChange={setTitle} label={translate('TITLE')} required />
        <LocalizedInput value={description} onChange={setDescription} label={translate('DESCRIPTION')} textarea recommendedMax={315} />
        <View style={Theme.styles.padding8}>
          <InlineInputComponent label={`${translate('PRICE')} (US$)`} value={price} onChange={setPrice} keyboardType='numeric' inputComponent={TextInput} />
        </View>
        <View style={[Theme.styles.paddingHorizontal16, Theme.styles.paddingTop8]} >
          <TouchableOpacity style={[Theme.styles.flex, Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.alignCenterStart]} onPress={()  => setStock({...stock, outOfStock: !stock.outOfStock})}>
            <CheckBox
              onValueChange={()  => setStock({...stock, outOfStock: !stock.outOfStock})}
              color={stock.outOfStock? Theme.palette.accent : null }
              style={Theme.styles.checkbox}
              value={stock.outOfStock}
            />
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8, Theme.styles.description]}>
              { translate('OUT_OF_STOCK') }
            </Text>
          </TouchableOpacity>
        </View>
        { !stock.outOfStock ? (
          <>
            <View style={[Theme.styles.paddingHorizontal16, Theme.styles.paddingTop8]} >
              <TouchableOpacity style={[Theme.styles.flex, Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.alignCenterStart]} onPress={()  => setStock({...stock, infinity: !stock.infinity})}>
                <CheckBox
                  onValueChange={()  => setStock({...stock, infinity: !stock.infinity})}
                  color={stock.infinity? Theme.palette.accent : null }
                  style={Theme.styles.checkbox}
                  value={stock.infinity}
                />
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8, Theme.styles.description]}>
                  { translate('INFINITE_STOCK') }
                </Text>
              </TouchableOpacity>
            </View>
            { !stock.infinity ? (
              <View style={[Theme.styles.padding8, Theme.styles.paddingBottom0]}>
                <InlineInputComponent label={translate('AMOUNT')} value={stock.amount} onChange={amount => setStock({ ...stock, amount })} keyboardType='numeric' inputComponent={TextInput} />
              </View>
            ) : null }
          </>
        ) : null }
        <View style={Theme.styles.padding8} />
        <HeaderComponent title={translate('MEDIA')} actionText={translate('ADD_MEDIA')} onPress={() => addMedia()} outlined />
        <View style={Theme.styles.paddingBottom8} />
        { media.length ? (
          <View style={[styles.media, styles.menuItemMedia]}>
            <Carousel
              data={media}
              renderItem={(props) => (<CarouselItem {...props} onPress={confirmRemove(media, () => setMedia({...media}))} />)}
              sliderWidth={Theme.width}
              itemWidth={Theme.width}
              autoplay={true}
              autoplayInterval={5000}
              loop={true}
            />
          </View>
        ) : (
          <View style={[styles.media, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
              { translate('NO_MEDIA_YET') }
            </Text>
          </View>
        )}
        <View style={Theme.styles.padding8} />
        <HeaderComponent title={translate('CUSTOMIZATION')} actionText={translate('ADD')} onPress={() => setCustomizations([...customizations, {}])} outlined />
        { customizations.length ? customizations.map((customization, idx) => <DynamicFieldComponent key={idx} config={customization} onChange={() => setCustomizations([...customizations])} onDelete={deleteCustomization(idx)} />
        ) : (
          <View style={[styles.media, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
              { translate('NO_CUSTOMIZATIONS_YET') }
            </Text>
          </View>
        )}
        { type === 'food' ? (
          <View>
            <View style={Theme.styles.padding8} />
            <HeaderComponent title={translate('ALLERGENS')} />
            <View style={[Theme.styles.row, Theme.styles.wrap, Theme.styles.alignCenterStart, Theme.styles.paddingLeft8, Theme.styles.paddingRight8, Theme.styles.paddingBottom8]}>
              { Object.keys(Allergens).map((allergen, idx) => (
                <TouchableOpacity key={idx} style={[Theme.styles.flex31, Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.alignCenterStart]} onPress={()=> setAllergens({ ...allergens, [allergen]: !allergens[allergen] })}>
                  <CheckBox
                    onValueChange={()=> setAllergens({ ...allergens, [allergen]: !allergens[allergen] })}
                    color={allergens[allergen]? Theme.palette.accent : null }
                    style={Theme.styles.checkbox}
                    value={allergens[allergen]}
                  />
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8, Theme.styles.description]}>
                    { translate(allergen.toUpperCase()) }
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        ) : null}
        <View style={Theme.styles.padding8} />
        { menuItem.id ? (
          <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]}>
            <ButtonComponent text={translate('DELETE_MENU_ITEM')} type='error' onPress={removeMenuItem} outlined />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};
