import moment from 'moment';
import React, {
  useContext,
  useState,
} from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';

import * as API from '../common/api.service';
import { Theme } from '../../theme';
import { translate, getCurrentLocale } from '../../lang';
import { Context } from '../Context';
import { DynamicFormComponent } from '../common/dynamic-form.component';
import { ButtonComponent } from '../common/button.component';
import { getBookingContext, getDurationString, getDateStringWithoutYear, syncMedia } from '../common/utils.service';
import { ListItemComponent } from '../common/list-item.component';
import { StatusLabelComponent } from '../common/status-label.component';
import { ICONS } from '../bookings/detail';

const DEFAULT_BOOKING_OFFER_TITLE = {
  en: 'Exclusive limited offer!',
  es: '¡Oferta exclusiva por tiempo limitado!',
  ru: 'Эксклюзивное ограниченное предложение!',
  de: 'Exklusives begrenztes Angebot!',
  it: 'Offerta esclusiva a tempo limitato!',
  fr: 'Offre exclusive à durée limitée !',
  hi: 'विशेष सीमित प्रस्ताव!',
  zh: '独家限时优惠！',
};

const DEFAULT_BOOKING_OFFER_BODY = {
  en: 'Come join us on this awesome activity and make the most out of your vacations. What are you waiting for? Join us now!',
  es: 'Ven y únete a nosotros en esta increíble actividad y aprovecha al máximo tus vacaciones. ¿Qué estás esperando? ¡Únete a nosotros!',
  ru: 'Присоединяйтесь к нам в этой потрясающей активности и получите максимум удовольствия от отпуска. На что вы ждете? Присоединяйтесь к нам!',
  de: 'Komm zu dieser großartigen Aktivität und nutze deine Ferien optimal. Woran wartest du? Mach mit!',
  it: 'Vieni a unirti a noi in questa fantastica attività e sfrutta al massimo le tue vacanze. Cosa stai aspettando? Unisciti a noi!',
  fr: 'Venez vous joindre à nous pour cette activité incroyable et profitez au maximum de vos vacances. Qu\'attendez-vous ? Rejoignez-nous !',
  hi: 'इस शानदार गतिविधि में हमारे साथ आइए और अपनी छुट्टियों का सर्वाधिक आनंद उठाएं। आप किस पर इंतजार कर रहे हैं? हमारे साथ जुड़ें!',
  zh: '赶快加入我们的精彩活动，充分享受您的假期。还在等什么？快来加入我们吧！'
};

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    color: Theme.palette.primary
  },
  dynamicForm: {
    input: {
      borderWidth: 0,
      borderBottomWidth: 1,
      borderColor: Theme.palette.backgroundDark
    },
    inputs: {
      datepicker: {
        input: {
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: Theme.palette.backgroundDark
        }
      },
      picker: {
        container: {
          maxWidth: '100%',
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: Theme.palette.backgroundDark
        }
      }
    }
  }
});

const publicBookings = {
  operation: '_read',
  table: 'bookings',
  query: {},
  filter: {
    dynamoDB: {
      FilterExpression: '#p = :t AND #d >= :d',
      ExpressionAttributeNames: {
        '#p': 'public',
        '#d': 'startDate',
      },
      ExpressionAttributeValues: {
        ':t': true,
        ':d': new Date().toISOString().split('T')[0],
      },
    }
  },
  sort: 'ASC',
  sortKey: 'startDate',
};

export const SendNotificationComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const locale = getCurrentLocale();
  const [submitted, setSubmitted] = useState(false);
  const [formValue, setFormValue] = useState({
    sticky: true,
    receivers: route.params?.receivers?.length ? 'select' : null,
    receiversSubValue: route.params?.receivers?.length ? route.params.receivers : null,
  });
  const [loading, setLoading] = useState(false);
  const template = [{
    /*
    key: 'sticky',
    label: translate('PERMANENT_NOTIFICATION'),
    required: false,
    hint: {
      [locale]: translate('PERMANENT_NOTIFICATION_HINT')
    },
    hide: true,
    type: 'switch',
  }, {
  */
    key: 'receivers',
    label: translate('RECEIVERS'),
    required: false,
    type: 'picker',
    options: [{
      label: translate('ALL'),
      value: null
      /*
    }, {
      label: translate('ACTIVE'),
      value: 'active'
    }, {
      label: translate('INACTIVE'),
      value: 'inactive'
      */
    }, {
      label: translate('NOT_SIGNED_UP'),
      value: 'temp'
    }, {
      label: translate('SELECT_RECEIVERS'),
      value: 'select',
      subOptions: {
        title: 'SELECT_GUESTS',
        options: () => context.guests,
        titleField: 'fullName',
        parser: (option) => option.id,
        required: true,
      }
    }],
  }, {
    key: 'booking',
    label: translate('SCHEDULED_ACTIVITY'),
    required: true,
    type: 'picker',
    options: [{
      label: translate('NONE'),
      value: 'none'
    }, {
      label: translate('SELECT'),
      value: 'select',
      subOptions: {
        title: 'SELECT_SCHEDULED_ACTIVITY',
        options: () => API.crud(publicBookings),
        renderTitleField: (option) => getBookingContext(option, context).plan.title[locale],
        renderSubTitleField: (option) => getBookingContext(option, context).group.title[locale],
        renderValueField: (option) => getDateStringWithoutYear(option.startDate, 'll'),
        renderer: (value, option, resolvedOptions) => {
          if (!value.length) return;
          const valueBooking = resolvedOptions.find(o => o.id === value[0]);
          const bookingContext = {
            ...valueBooking,
            ...(getBookingContext(valueBooking, context)),
          };
          return (
            <ListItemComponent
              title={bookingContext.service.title[locale]}
              subtitle={(bookingContext.plan?.title?.[locale] || '') + (bookingContext.duration ? '\n' + getDurationString(bookingContext.duration, bookingContext.plan?.dateRange ? (bookingContext.plan?.dateRangeUnit === 'night' ? 'NIGHT(S)' : 'DAY(S)') : '') : '')}
              image={ICONS[bookingContext.service.icon]}
              style={styles.booking}
              ActionComponent={() => (
                <View style={[Theme.styles.column, Theme.styles.alignEndCenter]}>
                  <StatusLabelComponent status={context.user.admin || !bookingContext.public || bookingContext.bookings?.[context.user?.id] ? bookingContext.status : 'JOIN_NOW'} />
                  <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, bookingContext.startTime ? Theme.styles.paddingTop5 : Theme.styles.paddingTop10]}>
                    <MaterialCommunityIcons name='human-greeting' color={Theme.styles.text.color} size={16} />
                    <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize12]}> {bookingContext.total || 0}{bookingContext.maxAttendees ? ' / ' + bookingContext.maxAttendees : ''}</Text>
                    { bookingContext.startTime ? (
                      <View style={[Theme.styles.row, Theme.styles.marginLeft5]}>
                        <MaterialCommunityIcons name='timer-outline' color={Theme.styles.text.color} size={16} />
                        <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize12]}> {moment.utc(bookingContext.startTime).format('hh:mm a')}</Text>
                      </View>
                    ) : null }
                  </View>
                </View>
              )}
            />
          );
        },
        required: true,
        parser: (option) => option.id,
        single: true,
      }
    }]
  }, {
    key: 'discount',
    type: 'numeric',
    label: translate('DISCOUNT_OFFER'),
    required: false,
    condition: {
      booking: '!none'
    }
  }, {
    key: 'title',
    type: 'localized-text',
    label: translate('TITLE'),
    required: true,
  }, {
    key: 'body',
    type: 'localized-text',
    label: translate('CONTENT'),
    required: true,
  }, {
    key: 'media',
    type: 'mediapicker',
    label: translate('MEDIA'),
    required: false,
  }];
  const dynamicFormApi = {};

  const send = async () => {
    try {
      setLoading(true);
      setSubmitted(true);
      Toast.hide();
      let uploadMedia = [syncMedia(formValue, formValue.media)];
      await Promise.all(uploadMedia);
      await API.sendMessage({
        ...formValue,
        receivers: formValue.receiversSubValue || formValue.receivers,
        booking: formValue.bookingSubValue?.[0] || formValue.booking,
      });
      navigation.navigate('GuestList');
      Toast.show({
        type: 'success',
        visibilityTime: 10000,
        text1: translate('NOTIFICATION_SENT'),
        text2: translate('NOTIFICATION_SENT_DESCRIPTION')
      });
    } catch(e) {
      setLoading(false);
    }
  };

  const onChange = (form) => {
    if (form.bookingSubValue?.length && !form.title) {
      form.title = DEFAULT_BOOKING_OFFER_TITLE;
    }
    if (form.bookingSubValue?.length && !form.body) {
      form.body = DEFAULT_BOOKING_OFFER_BODY;
    }
    setFormValue({ ...form });
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper, Theme.styles.padding32] : null]}>
      { Theme.XL ? (
        <Text style={[Theme.styles.text, Theme.styles.textBold, Theme.styles.paddingBottom16, styles.title]}>
          { translate('SEND_NOTIFICATION') }
        </Text>
      ) : null}
      <View style={Theme.styles.flex}>
        <DynamicFormComponent
          api={dynamicFormApi}
          template={template}
          formValue={formValue}
          onChange={onChange}
          submitted={submitted}
          style={styles.dynamicForm}
          submit={send}
        />
      </View>
      <View style={Theme.styles.actionButtonBorder}>
        <View style={[Theme.styles.width, Theme.styles.actionButtonContainer, Theme.XL ? [Theme.styles.padding16, Theme.styles.paddingRight32] : null]}>
          <ButtonComponent onPress={() => dynamicFormApi.validate()} text={translate('SEND')} loading={loading} />
        </View>
      </View>
    </View>
  );
};
