import React, { useLayoutEffect, useContext, useState, useCallback } from 'react';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import {
  View,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Theme } from '../../../theme';
import { translate, getCurrentLocale } from '../../../lang';
import { Context } from '../../Context';
import { ListComponent } from '../../common/list';
import { isResourceAvailable, ResourceTypes } from './detail';
import { HeaderComponent } from '../../common/header.component';

export const ResourcesListComponent = ({ navigation, route, nested, renderItem }) => {
  const context = useContext(Context);
  const locale = getCurrentLocale();
  const [list, setList] = useState([]);

  const open = ({ _resource: resource } = {}) => {
    context.navigation.title = resource?.name?.[locale] || null;
    context.hotelConfig = context.hotelConfig || {
      hotelId: context.hotelId,
      key: 'hotelConfig',
    };
    context.hotelConfig.data = context.hotelConfig.data || {
      info: {},
      guests: {},
      resources: []
    };
    navigation.navigate('ConfigResourceDetail', {
      ...route.params,
      config: context.hotelConfig?.data,
      resource
    });
  };

  useFocusEffect(useCallback(() => {
    setList(
      Object.values(ResourceTypes).map(type => ({
        id: type.value,
        title: type.labelPlural || type.label,
        icon: type.icon,
        data: context.hotelConfig?.data?.resources?.filter(resource => resource.type === type.value).map(resource => {
          const isAvailable = isResourceAvailable(resource);
          return {
            icon: isAvailable ? (
              <MaterialIcons name='check' size={25} color='green' />
            ) : (
              <MaterialIcons name='close' size={25} color='red' />
            ),
            title: resource.name?.[locale] || translate('NO_NAME'),
            subtitle: isAvailable ? translate('AVAILABLE') : translate('NOT_AVAILABLE'),
            onPress: open,
            _resource: resource
          };
        })
      }))
    );
  }, [route.params]));

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} onPress={() => open()}>
          <MaterialCommunityIcons name='plus' color={Theme.palette.primaryContrast} size={25} />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  return (
    <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      { Theme.XL && !nested ? (
        <View style={Theme.styles.paddingLeft16}>
          <HeaderComponent title={translate('RESOURCES')} />
        </View>
      ) : null}
      <ListComponent
        navigation={navigation}
        list={list}
        renderItem={renderItem}
        nested={nested}
        style={{
          ...(Theme.XL ? { container: Theme.styles.paddingHorizontal22 } : {}),
          ...(nested || Theme.XL ? {
            sectionHeader: {
              container: {
                backgroundColor: 'white'
              }
            }
          } : {})
        }}
      />
    </View>
  );
};
