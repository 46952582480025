import { Platform, Dimensions } from 'react-native';
import StyleSheet from 'react-native-media-query';
import merge from 'deepmerge';

import layout from './layout';

export default class BaseStyles {

  getPalette() {
    return {
      /* Colors Palette */
      primary: '',
      primaryContrast: '',
      accent: '',
      accentContrast: '',
      secondary: '',
      secondaryContrast: '',
      text: '#666',
      textDark: '#333',
      modeBackgroundColor: 'white',
      background: '#ddd',
      backgroundLight: '#efefef',
      backgroundContrast: 'white',
      backgroundDark: '#ccc',
      backgroundDarkContrast: 'white',
      stripped: '#eaeaea',
      nativeBackground: '#f2f2f2',
      dark: '',
      darkContrast: '#F3F8FF',
      service: '#FF8330',
      activity: '#2F8EBC',
      spa: '#663399',
      notification: 'darkgreen',
      success: '#37B066',
      successContrast: 'white',
      warning: '#FFAB40',
      warningContrast: 'white',
      error: '#FA2200',
      errorContrast: 'white',
    };
  }

  getStyles() {
    const { width, height } = Dimensions.get('window');
    const XL = width > 1200;
    const palette = this.getPalette();
    const styles = {
      /* Styles */
      flex: {
        flex: 1
      },
      height: {
        height: '100%',
      },
      fullHeight: {
        height
      },
      heightAuto: {
        height: 'auto',
      },
      width: {
        width: '100%',
      },
      fullWidth: {
        width
      },
      disabled: {
        opacity: .5
      },
      overflowHidden: {
        overflow: 'hidden'
      },
      background: {
        backgroundColor: palette.background
      },
      backgroundLight: {
        backgroundColor: palette.backgroundLight
      },
      actionButtonBorder: {
        borderTopWidth: 1,
        borderTopColor: palette.backgroundLight
      },
      actionButtonContainer: {
        maxWidth: XL ? 420 : width,
        width: '100%',
        marginLeft: 'auto'
      },
      activeButton: {
        transform: [{ scaleX: .7 }, { scaleY: .7 }],
        marginRight: -7
      },
      borderBottom: {
        borderBottomWidth: 1,
        borderBottomColor: palette.background
      },
      button: {
        backgroundColor: palette.accent,
        color: palette.accentContrast,
        width: '100%',
        height: 45
      },
      buttonDisabled: {
        opacity: .5,
        borderColor: 'transparent',
        backgroundColor: palette.backgroundDark,
        color: palette.backgroundDarkContrast
      },
      checkbox: {
        width: 20,
        height: 20
      },
      buttonText: {
        fontWeight: 'bold',
      },
      success: {
        color: palette.success,
        borderColor: palette.success
      },
      warning: {
        color: palette.warning,
        borderColor: palette.warning
      },
      error: {
        color: palette.error,
        borderColor: palette.error
      },
      input: {
        outline: 'none',
        minHeight: 40,
        marginBottom: 30,
        borderWidth: 1,
        borderRadius: 2,
        color: palette.textDark,
        borderColor: palette.accent,
        paddingLeft: 15,
      },
      inputFieldError: {
        top: -25,
        textAlign: 'left',
        position: 'relative',
        color: palette.error
      },
      text: {
        letterSpacing: 3,
        color: palette.text,
        textTransform: 'uppercase',
      },
      textDark: {
        color: palette.textDark
      },
      textPrimary: {
        color: palette.primary
      },
      textBold: {
        fontWeight: 'bold'
      },
      textNormal: {
        fontWeight: 'normal'
      },
      textTransformNone: {
        textTransform: 'none',
      },
      textContrast: {
        color: palette.primaryContrast
      },
      description: {
        letterSpacing: 0,
        textTransform: 'none',
        textAlign: 'justify'
      },
      textLeft: {
        textAlign: 'left'
      },
      textCenter: {
        textAlign: 'center'
      },
      textRight: {
        textAlign: 'right'
      },
      textJustify: {
        textAlign: 'justify'
      },
      container: {
        padding: 8
      },
      itemSeparator: {
        flex: 1,
        borderBottomWidth: 3,
        borderColor: 'transparent'
      },
      inputWeb: {
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none'
      },
      webContainer: {
        width: '100%',
        padding: 16,
        maxWidth: 1200,
        ...(Platform.OS === 'web' ? {
          justifySelft: 'center',
          alignSelf: 'center',
          marginHorizontal: 'auto',
          marginLeft: 'auto',
        } : {
          marginLeft: (width - 1200) / 2,
        })
      },
      webContainerWrapper: {
        padding: 0,
        backgroundColor: 'white',
      },
      webContainerBackground: {
        padding: 0,
      },
      webContainerExpanded: {
        maxWidth: 1500,
        justifySelft: 'center',
        alignSelf: 'center',
        marginHorizontal: 'auto',
        marginLeft: 'auto',
      },
      pickerInput: {
        ...layout.alignCenterStart,
        padding: 10,
        borderBottomWidth: 1,
        borderColor: palette.background,
        width: width,
        maxWidth: '100%',
        ...(Platform.OS !== 'web' ? {
          paddingHorizontal: 0,
          marginRight: 4,
          maxWidth: width - 16
        }: {})
      }
    };
    /* Paddings & Margins */
    for (let i = 0; i < 2000; i++) {
      styles[`padding${i}`] = { padding: i };
      styles[`paddingLeft${i}`] = { paddingLeft: i };
      styles[`paddingRight${i}`] = { paddingRight: i };
      styles[`paddingTop${i}`] = { paddingTop: i };
      styles[`paddingBottom${i}`] = { paddingBottom: i };
      styles[`paddingVertical${i}`] = { paddingTop: i, paddingBottom: i };
      styles[`paddingHorizontal${i}`] = { paddingLeft: i, paddingRight: i };
      styles[`margin${i}`] = { margin: i };
      styles[`marginLeft${i}`] = { marginLeft: i };
      styles[`marginRight${i}`] = { marginRight: i };
      styles[`marginTop${i}`] = { marginTop: i };
      styles[`marginBottom${i}`] = { marginBottom: i };
      styles[`marginVertical${i}`] = { marginTop: i, marginBottom: i };
      styles[`marginHorizontal${i}`] = { marginLeft: i, marginRight: i };
    }
    /* Letter Spacings */
    for (let i = 0; i < 5; i++) {
      styles[`letterSpacing${i}`] = { letterSpacing: i };
    }
    /* Font Sizes */
    for (let i = 0; i < 70; i++) {
      styles[`fontSize${i}`] = { fontSize: i };
    }
    /* Widths */
    for (let i = 0; i < 500; i++) {
      styles[`width${i}`] = { width: i };
    }
    /* Circular dependency styles */
    styles['inputLabel'] = {
      ...styles.text,
      ...styles.textDark,
      padding: 8,
      fontSize: 16
    };
    return merge(layout, styles);
  }

  getTheme() {
    const { height, width } = Dimensions.get('window');
    const numberOfColumns = Math.max(1, Math.ceil(width / 1000));
    const { ids, styles } = StyleSheet.create(this.getStyles());
    return {
      palette: this.getPalette(),
      ids,
      styles,
      height,
      width,
      numberOfColumns,
      XL: width > 1200,
      appStore: require('./assets/AppStore.png'),
      googlePlay: require('./assets/GooglePlay.png'),
      columnHeight: (width / numberOfColumns) * .5
    };
  }
}
