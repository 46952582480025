import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  SectionList,
  View,
} from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Theme } from '../../theme';
import { translate, getCurrentLocale } from '../../lang';
import { HeaderComponent } from '../common/header.component';

const styles = StyleSheet.create({
  sectionHeader: {
    container: {
      ...Theme.styles.text,
      height: 55,
      paddingTop: 20,
      paddingBottom: 10,
      paddingLeft: 10,
      backgroundColor: Theme.palette.nativeBackground
    },
    title: {
      fontSize: 20
    }
  },
  listItem: {
    ...Theme.styles.row,
    ...Theme.styles.alignCenterCenter,
    minHeight: 50,
    paddingHorizontal: 12,
  },
  firstListItem: {
    borderTopWidth: 1,
    borderTopColor: Theme.palette.background,
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.background,
  },
  middleListItem: {
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.background
  }
});

export const ListComponent = ({ list, renderItem, style }) => {
  list = list.filter(({ data }) => data?.length);
  const locale = getCurrentLocale();

  renderItem = renderItem || function({item, ...props}) {
    const first = props.index === 0;

    return (
      <TouchableOpacity onPress={() => item.onPress(item)} style={[styles.listItem, first ? styles.firstListItem : styles.middleListItem]}>
        { item.icon }
        <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignStartCenter]}>
          <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft10, Theme.styles.paddingRight5, Theme.styles.fontSize16]} numberOfLines={1}>{ item.title[locale] || item.title.en || item.title }</Text>
          { item.subtitle ? (
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description, Theme.styles.paddingLeft10, Theme.styles.paddingRight5, Theme.styles.fontSize12]} numberOfLines={1}>{ item.subtitle[locale] || item.subtitle.en || item.subtitle }</Text>
          ) : null }
        </View>
        <MaterialIcons name='chevron-right' size={20} color={Theme.styles.text.color} />
      </TouchableOpacity>
    );
  };

  return (
    <View style={[Theme.styles.flex, style?.container]}>
      <SectionList
        sections={list}
        style={Theme.styles.flex}
        contentContainerStyle={(!list || !list.length) && Theme.styles.flex}
        keyExtractor={(item, index) => index}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title, icon } }) => (
          <HeaderComponent icon={icon} title={title} style={{
            ...styles.sectionHeader,
            ...(style?.sectionHeader || {})
          }} />
        )}
        ListEmptyComponent={() => (
          <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.padding40]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.textCenter]}>{ translate('NO_CONTENT_YET') }</Text>
            <MaterialCommunityIcons name='cog' color={Theme.styles.text.color} size={50} style={[Theme.styles.marginTop20, Theme.styles.marginBottom20]} />
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textCenter]}>{ translate('ADD_TO_GET_STARTED') }</Text>
          </View>
        )}
      />
    </View>
  );
};
