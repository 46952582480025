import React, {
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';

import * as API from '../common/api.service';
import { Theme } from '../../theme';
import { getCurrentLocale, translate } from '../../lang';
import { Context } from '../Context';
import { DynamicFormComponent } from '../common/dynamic-form.component';
import { adminFields, DEFAULT_ACCOUNT_INFO_FIELDS, ICONS } from '../more/account/info';

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    color: Theme.palette.primary
  }
});

export const CreateGuestComponent = ({ navigation }) => {
  const context = useContext(Context);
  const [submitted, setSubmitted] = useState(false);
  const [formValue, setFormValue] = useState({
    admin: false
  });
  const [loading, setLoading] = useState(false);
  const dynamicFormApi = {};

  const accountInfo = (context.hotelConfig?.data?.guests?.accountFields || DEFAULT_ACCOUNT_INFO_FIELDS(getCurrentLocale() || 'en')).map(field => ({
    ...field,
    icon: field.type !== 'switch' ? ICONS[field.key] || ICONS[field.type] || ICONS.default : null
  }));
  const template = context.user.admin ? adminFields(context.hotelConfig.data, formValue).concat(accountInfo) : accountInfo;

  const save = async () => {
    try {
      setLoading(true);
      setSubmitted(true);
      Toast.hide();
      await API.signUp({
        ...formValue,
        username: formValue.userName || formValue[template.find(field => field.useAs === 'userName')?.key || 'fullName'],
        password: formValue.password || formValue[template.find(field => field.useAs === 'password')?.key || 'reservationNumber'] || formValue.email?.toLowerCase(),
      });
      navigation.navigate('GuestList');
    } catch(e) {
      if (e?.error?.statusCode === 409) {
        Toast.show({
          type: 'error',
          autoHide: false,
          text1: translate('USER_ALREADY_EXISTS'),
          text2: translate('USER_ALREADY_EXISTS_DESCRIPTION'),
        });
      } else {
        Toast.show({
          type: 'error',
          autoHide: false,
          text1: translate('UNKNOWN_ERROR'),
          text2: translate('UNKNOWN_ERROR_DESCRIPTION'),
        });
      }
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity onPress={dynamicFormApi.validate} style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16: null]} disabled={loading}>
          { loading ? (
            <ActivityIndicator color={Theme.palette.primaryContrast} />
          ) : (
            <MaterialCommunityIcons name='check' color={Theme.palette.primaryContrast} size={25} />
          )}
        </TouchableOpacity>
      ),
    });
  }, [navigation, loading, formValue]);

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper, Theme.styles.padding32] : null]}>
      { Theme.XL ? (
        <Text style={[Theme.styles.text, Theme.styles.textBold, Theme.styles.paddingBottom16, styles.title]}>
          { translate('CREATE_GUEST') }
        </Text>
      ) : null}
      <View style={Theme.styles.flex}>
        <DynamicFormComponent
          type='no-border'
          template={template}
          formValue={formValue}
          onChange={form => setFormValue(form)}
          submitted={submitted}
          style={styles.dynamicForm}
          submit={save}
          keyboardPadding={48}
          api={dynamicFormApi}
        />
      </View>
    </View>
  );
};
