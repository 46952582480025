import React, {
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import uuid from 'react-native-uuid';

import * as API from '../../../common/api.service';
import { syncMedia } from '../../../common/utils.service';
import { Theme } from '../../../../theme';
import { Context } from '../../../Context';
import { translate } from '../../../../lang';
import { ContentDetailDetailsComponent } from './details';
import { ContentDetailScheduledComponent } from './scheduled';

const Tab = createMaterialTopTabNavigator();

const styles = StyleSheet.create({
  tabNavigationComponents: {
    tabBarIndicatorStyle: { backgroundColor: Theme.palette.accent },
    tabBarLabelStyle: { fontSize: 12, color: Theme.palette.primaryContrast },
    tabBarStyle: { backgroundColor: Theme.palette.primary }
  },
});

export const ContentDetailComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  let { groups, group, service, key } = (route.params || {});
  const [loading, setLoading] = useState(false);

  const _state = {};
  const save = async () => {
    setLoading(true);
    service = service || {};
    service = {
      ...service,
      ..._state.scheduled,
      ..._state.details,
    };
    let uploadMedia = [syncMedia(service, service.newMedia)];
    delete service.newMedia;
    await Promise.all(uploadMedia);
    if (!service.id) {
      // Creating new service
      group.services = group.services || [];
      group.services.push({
        id: uuid.v4(),
        ...service
      });
    } else {
      const serviceIndex = group.services.findIndex(s => s.id === service.id);
      group.services[serviceIndex] = service;
    }
    await (API.crud({
      operation: '_update',
      table: 'hotels',
      query: context[key]
    })).finally(() => setLoading(false));
    navigation.navigate('ContentGroupDetail', {
      ...route.params,
      groups,
      group
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} onPress={save} disabled={loading}>
          { loading ? (
            <ActivityIndicator color={Theme.palette.primaryContrast} />
          ) : (
            <MaterialCommunityIcons name='check' color={Theme.palette.primaryContrast} size={25} />
          )}
        </TouchableOpacity>
      ),
    });
  }, [navigation, loading, _state]);

  return (
    <Tab.Navigator
      screenOptions={styles.tabNavigationComponents}
    >
      <Tab.Screen
        name='ContentDetailDetails'
        options={{ title: translate('DETAILS') }}
      >
        { () => <ContentDetailDetailsComponent navigation={navigation} route={route} state={_state} /> }
      </Tab.Screen>
      <Tab.Screen
        name='ContentDetailSchedules'
        options={{ title: translate('SCHEDULED') }}
      >
        { () => <ContentDetailScheduledComponent navigation={navigation} route={route} state={_state} /> }
      </Tab.Screen>
    </Tab.Navigator>
  );
};

