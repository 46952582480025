import React, { useState, useContext } from 'react';
import {
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { LinearGradient } from 'expo-linear-gradient';
import Carousel from 'react-native-snap-carousel';
import { Video } from 'expo-av';

import { Theme } from '../../theme';
import { Context } from '../Context';
import Image from './libs/image/image';

const MEDIA_PLACEHOLDER = {
  type: 'image',
  source: require('../../theme/assets/MediaPlaceholder.png')
};

const {ids, styles} = StyleSheet.create({
  disabled: {
    opacity: .2
  },
  tileGradient: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    minHeight: 60,
    paddingHorizontal: 8,
    paddingVertical: 10
  },
  media: {
    width: '100%',
    height: Theme.columnHeight, //200
    transform: [{ scale: 1}],
    transition: 'all .5s',
    ':hover': {
      transform: [{ scale: 1.1}]
    }
  },
  poster: {
    position: 'absolute',
    zIndex: 10
  },
  cornerLabel: {
    position: 'absolute',
    minHeight: 40,
    top: 8,
    right: 8
  },
  text: {
    color: '#f4f4f4',
    fontSize: 10,
    textAlign: 'left',
    letterSpacing: 1,
    fontWeight: 'bold',
    textTransform: 'none'
  },
  title: {
    flex: 1,
    textAlign: 'left',
    fontSize: 20
  },
  label: {
    alignSelf: Theme.XL ? 'flex-end' : 'auto',
    textAlign: 'right'
  },
  labelValue: {
    textAlign: 'right',
    fontSize: 25
  },
  secondaryText: {
    textAlign: 'left',
    color: '#e4e4e4',
    fontWeight: '300',
    fontSize: 14,
    paddingLeft: 1
  },
  caption: {
    flex: 1,
    textAlign: 'left',
    fontSize: 12,
    marginTop: -1,
    paddingLeft: 3
  },
  carouselItemButton: {
    position: 'absolute',
    zIndex: 1,
    top: 10,
    right: 10,
    borderRadius: 100,
    backgroundColor: 'rgba(0, 0, 0, .1)',
    width: 30,
    height: 30,
  },
  arrowLabel: {
    position: 'absolute',
    top: 0,
    left: -10,
    borderTopWidth: 21,
    borderRightWidth: 10,
    borderBottomWidth: 21,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
  },
  badge: {
    marginRight: 8,
    width: 25,
    height: 25,
    borderRadius: 25,
    backgroundColor: Theme.palette.primary
  },
  badgeText: {
    textAlign: 'center',
    lineHeight: 25,
    fontSize: 12,
    fontWeight: 'bold',
    color: Theme.palette.primaryContrast
  }
});

const tileGradient = ['rgba(0, 0, 0, 0.7)', 'rgba(0, 0, 0, 0.3)', 'rgba(0, 0, 0, 0)'];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const CarouselItem = ({item, index, ...props}) => {
  const context = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  if (item.local || !item.source) {
    item.source = Theme.assets[item.local];
  }
  return (
    <View>
      { item.type === 'video' ? (
        <View>
          <Video
            source={item.source || {
              uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID.replace('.admin', '') + (context.ref ? `.${context.ref}` : ''), item.src].join('/')
            }}
            shouldPlay={true}
            style={[styles.media, props?.style?.media]}
            isLooping
            isMuted={true}
            resizeMode='cover'
            rate={1.0}
            onPlaybackStatusUpdate={status => setIsLoading(!status.isLoaded)}
          />
          { isLoading ? (
            <Image
              style={[styles.media, props?.style?.media, styles.poster]}
              source={item.source || {
                uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID.replace('.admin', '') + (context.ref ? `.${context.ref}` : ''), item.poster || 'placeholder.png'].join('/')
              }}
            />
          ) : null
          }
        </View>
      ) : (
        <Image
          style={[styles.media, props?.style?.media]}
          dataSet={{media: ids.media}}
          source={item.source || {
            uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID.replace('.admin', '') + (context.ref ? `.${context.ref}` : ''), item.src || 'placeholder.png'].join('/')
          }}
        />
      )
      }
      { props.onPress ? (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, styles.carouselItemButton]} onPress={() => props.onPress({ item, index, ...props })}>
          <MaterialCommunityIcons name={props.buttonIcon || 'delete'} color={props.buttonColor || (Theme.palette.error + 'CC')} size={15} />
        </TouchableOpacity>
      ) : null
      }
    </View>
  );
};

export const Tile = ({ onPress, ...props }) => {
  const [width, setWidth] = useState(Theme.width);
  if (!Array.isArray(props.media)) {
    props.media = [props.media || MEDIA_PLACEHOLDER];
  }
  if (!props.media.length) {
    props.media = [MEDIA_PLACEHOLDER];
  }
  const carouselInterval = Math.floor((Math.random() * (props.minInterval || 5)) + 1);
  const onLayout = (event) => setWidth(event.nativeEvent.layout.width);
  return (
    <TouchableHighlight onPress={onPress} disabled={props.disabled} style={props.disabled ? styles.disabled : null} >
      <View onLayout={onLayout}>
        { /* Tile Media */ }
        <Carousel
          data={shuffleArray(props.media)}
          renderItem={(renderProps) => <CarouselItem {...renderProps} {...props}/>}
          sliderWidth={width}
          itemWidth={width}
          autoplay={true}
          autoplayDelay={carouselInterval * 1000}
          autoplayInterval={5000}
          loop={true}
        />
        {
          /* Tile Corner Label */
          props.label ? (
            <View style={[styles.cornerLabel, Theme.XL ? Theme.styles.alignCenterCenter : null, props.style?.label]}>
              <View style={[styles.arrowLabel, props.style?.arrowLabel]} />
              <Text style={[Theme.styles.text, styles.text, styles.label, props.style?.labelTitle]} numberOfLines={2}>
                { props.label.title }
              </Text>
              { props.label.value ? (
                <Text style={[Theme.styles.text, styles.text, styles.labelValue, props.style?.labelValue]}>
                  { props.label.value }
                </Text>
              ) : null}
            </View>
          ) : null
        }
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
          locations={[0, 0.6, 0.75]}
          colors={tileGradient}
          style={[styles.tileGradient, Theme.styles.alignStartCenter]}
        >
          <View style={Theme.styles.width}>
            <View style={[Theme.styles.width, Theme.styles.row]}>
              { props.badge ? (
                <View style={[styles.badge, props?.style?.badge]}>
                  <Text style={[styles.badgeText, props?.style.badgeText]}>{ props.badge }</Text>
                </View>
              ) : null}
              <Text style={[Theme.styles.text, styles.text, styles.title, props?.style?.title]}>
                { props.title }
              </Text>
            </View>
            {
              /* Tile subtitle */
              props.subtitle ? (
                <Text style={[Theme.styles.text, styles.text, styles.secondaryText, {maxWidth: '75%'}, props?.style?.subtitle]}>
                  { props.subtitle }
                </Text>
              ) : null
            }
            {
              /* Tile caption */
              props.caption ? (
                <View style={[Theme.styles.flex, Theme.styles.row, Theme.styles.marginTop5]}>
                  <MaterialCommunityIcons name={props.caption.icon} color={styles.secondaryText.color} size={12} />
                  <Text style={[Theme.styles.text, styles.text, styles.secondaryText, styles.caption]}>
                    { props.caption.text }
                  </Text>
                </View>
              ) : null
            }
          </View>
        </LinearGradient>
      </View>
    </TouchableHighlight>
  );
};
