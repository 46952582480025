import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import {
  Keyboard,
  Platform,
  FlatList,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import CheckBox from 'expo-checkbox';
import Carousel from 'react-native-snap-carousel';
import Toast from 'react-native-toast-message';
import { useActionSheet } from '@expo/react-native-action-sheet';
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../common/libs/bottom-sheet';
import useKeyboard from '@rnhooks/keyboard';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Picker } from './libs/picker/picker';
import { Theme } from '../../theme';
import { translate, getCurrentLocale } from '../../lang';
import { LocalizedInput } from './localized-input.component';
import { DatePickerComponent } from './datepicker.component';
import { NumberInputComponent } from './number-input.component';
import { HeaderComponent } from './header.component';
import { SelectorComponent } from './selector.component';
import { TimePicker } from './libs/time-picker/time-picker';
import { openMediaPicker } from './media.service';
import { CarouselItem } from './tile';
import { ButtonComponent } from './button.component';

const IconComponents = {
  MaterialIcons,
  MaterialCommunityIcons,
};

const KEYBOARD_TYPE = {
  text: 'default',
  textarea: 'default',
  email: 'email-address',
  phone: 'phone-pad',
  numeric: 'number-pad'
};

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  inputs: {
    datepicker: {
      default: {
        withIcon: {
          input: {
            paddingLeft: 35,
          }
        }
      },
      'no-border': {
        withIcon: {
          input: {
            paddingLeft: 35,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: Theme.palette.background,
          }
        },
        default: {
          input: {
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: Theme.palette.background,
          }
        }
      },
    },
    mediapicker: {
      container: {
        position: 'absolute',
        right: 16,
        top: Theme.XL ? -30 : 8,
      },
      imageContainer: {
        marginLeft: -16,
        marginRight: -16,
        minHeight: 200,
        height: 200
      }
    },
    localizedText: {
      input: {
        container: {
          marginLeft: -16,
          marginRight: -16
        }
      }
    },
    timepicker: {
      textContainer: {
        ...Theme.styles.row,
        ...Theme.styles.alignCenterStart,
        maxWidth: (Theme.width / Theme.numberOfColumns) - 150,
        height: 36,
        paddingTop: 5,
      },
      container: {
        position: 'absolute',
        right: 16,
        top: -36
      }
    },
    number: {
      textContainer: {
        ...Theme.styles.row,
        ...Theme.styles.alignCenterStart,
        maxWidth: (Theme.width / Theme.numberOfColumns) - 180,
      },
      text: {
        minHeight: 30,
      },
      container: {
        position: 'absolute',
        right: -16,
        top: -36
      },
      message: {
        marginTop: Theme.XL ? 0 : -16
      }
    },
    switch: {
      textContainer: {
        width: '100%',
        maxWidth: (Theme.width / Theme.numberOfColumns) - 78,
      },
      parentContainer: {
        ...Theme.styles.row,
        ...Theme.styles.alignCenterCenter,
        maxWidth: (Theme.width / Theme.numberOfColumns) - (Theme.XL ? 118 : 0),
        paddingHorizontal: 40,
        paddingBottom: 30,
      },
      hintContainer: {
        marginTop: -15
      },
      text: {
        paddingBottom: 0,
        position: 'relative',
        paddingRight: 16
      }
    },
    picker: {
      container: {
        default: {
          flex: 1,
          maxWidth: (Theme.width / Theme.numberOfColumns) - 32,
          borderWidth: 1,
          borderColor: Theme.palette.accent,
          ...Theme.styles.alignStartCenter
        },
        'no-border': {
          flex: 1,
          width: '100%',
          maxWidth: (Theme.width / Theme.numberOfColumns) - 32,
          ...Theme.styles.alignStartCenter
        }
      }
    }
  },
  // eslint-disable-next-line react-native/no-unused-styles
  iconContainer: {
    default: {
      top: 10,
      marginLeft: 10,
      position: 'absolute',
    },
    'no-border': {
      top: -2,
      position: 'absolute',
    }
  },
  // eslint-disable-next-line react-native/no-unused-styles
  labelContainer: {
    'no-border': {
      marginLeft: 35,
      height: 13
    }
  },
  // eslint-disable-next-line react-native/no-unused-styles
  inputContainer: {
    'no-border': {
      withIcon: {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Theme.palette.background,
      },
      default: {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Theme.palette.background,
        paddingLeft: 0,
        minHeight: 30,
        paddingBottom: 10,
      }
    }
  }
});

export const conditionedTemplate = (field, formValue) => {
  if (!field.condition) return true;
  // TODO Implement a proper condition filter
  return Object.entries(field.condition).every(([key, value]) => {
    if (value?.startsWith?.('!')) {
      return formValue[key] != value.substring(1);
    } else {
      return formValue[key] == value;
    }
  });
};

export const DynamicFormComponent = ({
  template,
  formValue,
  onChange,
  style,
  submit,
  getMessage,
  buildInput,
  TextInputComponent,
  FlatListComponent,
  api,
  type,
  keyboardPadding
}) => {

  TextInputComponent = TextInputComponent || TextInput;
  FlatListComponent = FlatListComponent || FlatList;
  buildInput = buildInput || (i => i);
  api = api || {};
  const [submitted, setSubmitted] = useState(false);
  const [width, setWidth] = useState(Theme.width);
  const [subOptions, setSubOptions] = useState(null);
  const bottomSheetModalRef = useRef(null);
  const snapPoints = useMemo(() => ['90%'], []);
  const { showActionSheetWithOptions } = useActionSheet();
  const locale = getCurrentLocale();
  const inputs = {};
  const langs = process.env.LANGS.split(',');
  const [resolvedSubOptions, setResolvedSubOptions] = useState({});
  const [ visible ] = useKeyboard();
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    function onKeyboardDidShow(e) {
      setKeyboardHeight(e.endCoordinates.height);
    }

    function onKeyboardDidHide() {
      setKeyboardHeight(0);
    }

    const showSubscription = Keyboard.addListener('keyboardDidShow', onKeyboardDidShow);
    const hideSubscription = Keyboard.addListener('keyboardDidHide', onKeyboardDidHide);
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const handleSubOptions = async (input, value) => {
    const inputOption = input.options?.find(o => o.value === value);
    if (inputOption?.subOptions) {
      resolvedSubOptions[input.key + value] = resolvedSubOptions[input.key + value] || await inputOption.subOptions.options(formValue);
      setSubOptions({
        key: input.key,
        ...inputOption.subOptions,
        options: resolvedSubOptions[input.key + value]
      });
      bottomSheetModalRef.current?.present();
      formValue[input.key + 'SubValue'] = formValue[input.key + 'SubValue'] || [];
      setResolvedSubOptions({...resolvedSubOptions});
    } else {
      delete formValue[input.key + 'SubValue'];
      bottomSheetModalRef.current?.dismiss();
    }
  };

  const changeFormValue = async (key, value, input) => {
    if (input) await handleSubOptions(input, value);
    return onChange({
      ...formValue,
      [key]: value
    });
  };

  const changeFormValues = async (changes) => {
    const valueChanges = {};
    for (let [key, value, input ]of changes) {
      if (input) await handleSubOptions(input, value);
      valueChanges[key] = value;
    }
    return onChange({
      ...formValue,
      ...valueChanges
    });
  };

  const validateField = (field = {}) => {
    const option = field.options?.find(o => o.value === formValue[field.key]);
    return !conditionedTemplate(field, formValue) || (
      (!field.required || (
        formValue[field.key] && (field.type !== 'localized-text' || langs.every(l => formValue[field.key]?.[l]))
      )) &&
      (!option || !option.subOptions || !option.subOptions.required || formValue[field.key + 'SubValue']?.length) &&
      (!field.min || field.min <= formValue[field.key]) &&
      (!field.max || field.max >= formValue[field.key])
    );
  };

  api.validate = () => {
    setSubmitted(true);
    if(template.every(validateField)) submit(formValue);
  };

  const toggleMultiPicker = (key, value) => {
    const values = formValue[key] || [];
    let index = -1;
    if (value?.id) {
      index = values.findIndex(v => v.id === value.id);
    } else {
      index = values.indexOf(value);
    }
    if (index >= 0) {
      values.splice(index, 1);
    } else {
      values.push(value);
    }
    changeFormValue(key, [...values]);
  };

  const getOptionLabel = (option) => (option.label[locale] || option.label) + (option.extraCharge ? ` (+$${parseFloat(option.extraCharge).toFixed(2)})` : '');

  const confirmRemove = (key) => {
    return ({ item }) => {
      return Toast.show({
        type: 'action',
        autoHide: false,
        text1: translate('CONFIRM_ACTION'),
        text2: translate('CONFIRM_ACTION_DESCRIPTION'),
        props: {
          actions: [{
            text: translate('NO'),
            onPress: Toast.hide
          }, {
            text: translate('YES'),
            class: 'error',
            onPress: async () => {
              formValue[key].splice(formValue[key].indexOf(item), 1);
              changeFormValue(key, formValue[key]);
              Toast.hide();
            }
          }]
        }
      });
    };
  };

  const addMedia = async (key, callback) => {
    try {
      const { type, extension, uri } = await openMediaPicker(showActionSheetWithOptions);
      const toUpload = {
        source: { uri },
        toUpload: true,
        extension,
        type
      };
      if (callback) return callback(toUpload);
      else changeFormValue(key, [...(formValue[key] || []), toUpload]);
    } catch(e) {
      if (e) {
        Toast.show({
          type: 'error',
          text1: translate('MEDIA_ERROR'),
          text2: translate('MEDIA_ERROR_DESCRIPTION')
        });
      }
    }
  };

  const onLayout = (event) => setWidth(event.nativeEvent.layout.width);

  const next = (index) => {
    inputs[index + 1]?.focus();
  };

  const toggleSubOption = (key, optionId, optionContext) => {
    let values = formValue[key] || [];
    let contexts = formValue[key + 'Contexts'] || [];
    const index = values.indexOf(optionId);
    if (index !== -1) {
      values.splice(index, 1);
      contexts.splice(index, 1);
    } else {
      if (subOptions.single) {
        values = [optionId];
        contexts = [optionContext];
      } else {
        values.push(optionId);
        contexts.push(optionContext);
      }
    }
    changeFormValues([[key, values], [key + 'Contexts', contexts]]);
  };

  // renders
  const renderIcon = (input) => {
    const IconComponent = IconComponents[input.icon.family];
    return (
      <View style={styles.iconContainer[type] || styles.iconContainer.default}>
        <IconComponent name={typeof input.icon.name === 'object' && input.icon.name[formValue[input.key]] || input.icon.name} size={input.icon.size} color={input.icon.color} />
      </View>
    );
  };

  const renderInput = (input, index, next, submit, style) => {
    const failedField = submitted && !validateField(input);

    if (input.options) input.options = input.options?.filter(o => o.active !== false);

    switch (input.type) {
    case 'mediapicker':
      return (
        <>
          <View style={styles.inputs.mediapicker.container}>
            <ButtonComponent onPress={() => addMedia(input.key)} text={translate('ADD_MEDIA')} inline outlined />
          </View>
          <View style={Theme.styles.paddingBottom8} />
          { formValue[input.key]?.length ? (
            <View style={styles.inputs.mediapicker.imageContainer} onLayout={onLayout}>
              <Carousel
                data={formValue[input.key]}
                renderItem={(props) => (<CarouselItem {...props} onPress={confirmRemove(input.key)} />)}
                sliderWidth={width}
                itemWidth={width}
                autoplay={true}
                autoplayInterval={5000}
                loop={true}
              />
            </View>
          ) : (
            <View style={[styles.inputs.mediapicker.imageContainer, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
                { translate('NO_MEDIA_YET') }
              </Text>
            </View>
          )}
        </>
      );
    case 'datepicker':
      return (
        <DatePickerComponent
          ref={input => inputs[index] = input}
          value={formValue[input.key]}
          onChange={(value) => changeFormValue(input.key, value)}
          style={{...(styles.inputs.datepicker[type] || styles.inputs.datepicker.default)[input.icon ? 'withIcon': 'default'], ...style?.inputs?.datepicker}}
          error={failedField}
          returnKeyType={submit ? 'done' : 'next'}
          onSubmitEditing={api.validate || next.bind(this, index)}
        />
      );
    case 'timepicker':
      return (
        <View style={[styles.inputs.timepicker.container, style?.inputs?.timepicker?.container]}>
          <TimePicker
            value={formValue[input.key]}
            onChange={(value) => changeFormValue(input.key, value)}
          />
        </View>
      );
    case 'number':
      return (
        <View style={[styles.inputs.number.container, style?.inputs?.number?.container]}>
          <NumberInputComponent
            onChange={(value) => changeFormValue(input.key, value)}
            min={input.min}
            max={input.max}
            defaultValue={formValue[input.key] || input.default || 0}
          />
        </View>
      );
    case 'picker':
      let selected = input.options.find(o => o.value === formValue[input.key]);
      if (!selected) {
        selected = input.options[0];
        changeFormValue(input.key, selected.value);
      }
      return (
        <Picker
          ref={input => inputs[index] = input}
          placeholder={{}}
          value={formValue[input.key]}
          onValueChange={value => changeFormValue(input.key, value, input)}
          label={Platform.OS!== 'web' && input.label[locale]}
          items={input.options.map(o => ({
            label: getOptionLabel(o),
            value: o.value
          }))}
          style={style?.inputs?.picker?.parent}
          hasIcon={!!input.icon}
        >
          <View style={[Theme.styles.pickerInput, styles.inputs.picker.container[type] || styles.inputs.picker.container.default, style?.inputs?.picker?.container]}>
            <View style={[Theme.styles.flex, input.icon ? Theme.styles.paddingLeft35 : Theme.styles.paddingLeft15]} >
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, style?.inputs?.picker?.text]}>{getOptionLabel(selected)}</Text>
            </View>
          </View>
        </Picker>
      );
    case 'multi-picker':
      return (
        <View style={[input.row ? Theme.styles.row : Theme.styles.column, Theme.styles.alignStartStart, Theme.styles.paddingRight8, Theme.styles.paddingBottom8]}>
          { input.options.map(option => (
            <TouchableOpacity key={option.value?.id ?? option.value} style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.paddingVertical8]} onPress={() => toggleMultiPicker(input.key, option.value)}>
              { Theme.XL ? (
                <CheckBox
                  color={formValue[input.key]?.includes(option.value) ? Theme.palette.accent : null }
                  style={Theme.styles.checkbox}
                  value={input.checked?.(formValue, option) ?? formValue[input.key]?.includes(option.value)}
                />
              ) : (
                <CheckBox
                  onValueChange={() => toggleMultiPicker(input.key, option.value)}
                  color={input.checked?.(formValue, option) ?? formValue[input.key]?.includes(option.value) ? Theme.palette.accent : null }
                  style={Theme.styles.checkbox}
                  value={input.checked?.(formValue, option) ?? formValue[input.key]?.includes(option.value)}
                />
              )}
              <View style={[Theme.styles.column, Theme.styles.alignStartCenter]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8]}>
                  { getOptionLabel(option) }
                </Text>
                { option.hint?.[locale] ? (
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8, Theme.styles.fontSize10, Theme.styles.letterSpacing1]}>
                    { option.hint[locale] }
                  </Text>
                ) : null}
              </View>
            </TouchableOpacity>
          ))}
        </View>
      );
    case 'switch':
      if (typeof formValue[input.key] !== 'boolean') {
        // Forcing boolean
        changeFormValue(input.key, !!formValue[input.key]);
      }
      return (
        <View style={[styles.inputs.switch.container, style?.inputs?.switch?.container]}>
          <Switch
            style={Theme.styles.activeButton}
            onValueChange={value => changeFormValue(input.key, value)}
            value={formValue[input.key]}
            trackColor={style?.inputs?.switch?.colors}
            ios_backgroundColor={style?.inputs?.switch?.colors?.false}
          />
        </View>
      );
    case 'localized-text':
      return (
        <LocalizedInput
          style={styles.inputs.localizedText.input}
          value={formValue[input.key]}
          onChange={value => changeFormValue(input.key, value)}
          label={input.label}
          hideLabel
          required={input.required}
        />
      );
    case 'numeric':
    case 'textarea':
    case 'text':
    case 'phone':
    case 'email':
    default:
      return (
        <TextInputComponent
          ref={input => inputs[index] = input}
          style={[Theme.styles.input, Theme.styles.marginBottom0, input.type  === 'textarea' && Theme.styles.paddingVertical10, style?.input, failedField && Theme.styles.error, input.icon ? Theme.styles.paddingLeft35 : null, styles.inputContainer[type]?.[input.icon ? 'withIcon' : 'default']]}
          keyboardType={KEYBOARD_TYPE[input.type] || KEYBOARD_TYPE.text}
          multiline={input.type === 'textarea' ? true : false}
          onChangeText={value => changeFormValue(input.key, value)}
          value={formValue[input.key]}
          placeholderTextColor='rgba(255, 255, 255, .5)'
          secureTextEntry={input.secured}
          returnKeyType={submit ? 'done' : 'next'}
          onSubmitEditing={submit || next.bind(this, index)}
          autoCapitalize={input.autoCapitalize || 'none'}
        />
      );
    }
  };

  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);

  const renderFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props}>
        <View style={[Theme.styles.width, Theme.styles.actionButtonContainer, Theme.XL ? [Theme.styles.padding16, Theme.styles.paddingRight32] : null]}>
          <ButtonComponent safeArea={!visible} onPress={() => bottomSheetModalRef.current?.dismiss()} text={translate('CLOSE')} />
        </View>
      </BottomSheetFooter>
    );
  }, [visible]);

  return (
    <>
      <View style={[Theme.styles.column, Theme.styles.alignStretchCenter, Theme.styles.flex, keyboardPadding >= 0 ? { paddingBottom: keyboardHeight - (keyboardPadding ?? 100)} : null]}>
        <FlatListComponent
          data={template.filter(field => conditionedTemplate(field, formValue))}
          numColumns={Theme.numberOfColumns}
          keyExtractor={(item, index) => index}
          removeClippedSubviews={false}
          renderItem={({ item, index }) => {
            const input = buildInput(item);
            const selectedOption = input.options?.find(o => o.value === formValue[input.key]);
            return (
              <View style={[Theme.styles.padding16, Theme.styles.flex, styles.inputs[input.type || 'default']?.parentContainer]}>
                {/* Input label */}
                <View style={[styles.inputs[input.type]?.textContainer, input.icon ? styles.labelContainer[type] : null]}>
                  <Text style={[Theme.styles.text, Theme.styles.paddingBottom6, styles.inputs[input.type || 'default']?.text, style?.textColor, submitted && !validateField(input) && Theme.styles.error]}>
                    { input.label[locale] || input.label }{ input.required ? '*' : '' }
                  </Text>
                </View>
                {/* Input field */}
                <View>
                  { input.icon ? renderIcon(input) : null }
                  { renderInput.call(this, input, index, next, index === template.length - 1 ? submit : null, style)}
                </View>
                <View style={[Theme.styles.flex, styles.inputs[input.type || 'default']?.hintContainer]}>
                  { formValue[input.key + 'SubValue'] && (
                    <View>
                      <TouchableOpacity onPress={() => handleSubOptions(input, formValue[input.key])} style={Theme.styles.paddingTop10}>
                        { selectedOption?.subOptions?.renderer?.(formValue[input.key + 'SubValue'], selectedOption, resolvedSubOptions[input.key + formValue[input.key]]) || (
                          <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize14, Theme.styles.letterSpacing1, submitted && !validateField(input) && Theme.styles.error]}>
                            { translate('SUB_VALUES_SELECTED', { count: formValue[input.key + 'SubValue'].length }) }
                          </Text>
                        ) }
                      </TouchableOpacity>
                    </View>
                  )}
                  {/* Input hint */}
                  { input.hint?.[locale] ? (
                    <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize10, Theme.styles.letterSpacing1]}>
                      { input.hint[locale] }
                    </Text>
                  ) : null}
                  {/* Input message */}
                  { input.message?.[locale] ? (
                    <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize10, Theme.styles.letterSpacing1, Theme.styles.paddingTop8, Theme.styles.textRight, styles.inputs[input.type]?.message]}>
                      { getMessage ? getMessage(input.message[locale], input, formValue) : input.message[locale] }
                    </Text>
                  ) : null}
                </View>
              </View>
            );
          }}
        />
      </View>
      <BottomSheetModal
        ref={bottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderFooter}
      >
        <BottomSheetScrollView>
          { subOptions ? (
            <View style={[Theme.styles.paddingLeft8, Theme.styles.paddingRight8, Theme.styles.paddingBottom80]}>
              <HeaderComponent
                title={translate(subOptions?.title)}
              />
              <View style={Theme.styles.padding16}>
                { subOptions.options.map(option => (
                  <SelectorComponent
                    key={subOptions.parser(option)}
                    onPress={() => toggleSubOption(subOptions.key + 'SubValue', subOptions.parser(option), option)}
                    selected={formValue[subOptions.key + 'SubValue']?.includes(subOptions.parser(option))}
                    title={subOptions.renderTitleField?.(option) || option[subOptions.titleField] || ''}
                    subtitle={subOptions.renderSubtitleField?.(option) || option[subOptions.subtitleField] || ''}
                    value={subOptions.renderValueField?.(option) || option[subOptions.valueField] || ''}
                  />
                )) }
              </View>
            </View>
          ) : null}
        </BottomSheetScrollView>
      </BottomSheetModal>
    </>
  );
};


