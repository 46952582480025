import React, { useContext, useState } from 'react';
import {
  View,
  Text,
} from 'react-native';
import uuid from 'react-native-uuid';
//import Toast from 'react-native-toast-message';

import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { getPlanPrice } from '../common/utils.service';
//import * as API from '../common/api.service';
import { HeaderComponent } from '../common/header.component';
import { ButtonComponent } from '../common/button.component';
import { DynamicFormComponent } from '../common/dynamic-form.component';
import { upsertBooking } from '../bookings/service';

export const TemplateComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const { guest, group, service, plan, type } = route.params;
  const locale = getCurrentLocale();
  const [submitted, setSubmitted] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  const confirm = async () => {
    /*
    if (!context.user || !context.user.status === 'CONFIRMED') return Toast.show({
      type: 'error',
      visibilityTime: 20000,
      text1: translate('ACTIVE_USER_REQUIRED'),
      text2: translate('ACTIVE_USER_REQUIRED_DESCRIPTION')
    });
    */
    setSubmitted(true);
    if (plan.template.every(field => !field.required || formValue[field.key])) {
      //setLoading(true);
      const booking = {
        oldId: route.params.id,
        id: `${type}#${group.id}#${service.id}#${plan.id}#${today}#${route.params.id ? route.params.id.split('#').pop() : '%' + uuid.v4() + '%'}`,
        accountId: guest?.id,
        plan: plan.id,
        startDate: formValue.startDate || formValue.date || today,
        endDate: formValue.endDate || formValue.startDate || formValue.date || today,
        status: 'PENDING',
        price: getPlanPrice(plan),
        createdAt: Date.now()
      };
      const upserted = await upsertBooking(context, group, plan, booking, setLoading);
      /*
      const operation = route.params.id ? '_update' : '_create';
      await API.crud({
        operation,
        table: 'bookings',
        query: booking
      });
      setLoading(false);
      context.user.bookings = context.user.bookings || [];
      context.user.bookings.push(booking);
      */
      if (upserted) {
        navigation.navigate(route.params.navigateTo || `${type}ServiceGroups`, { ...route.params.navigateToParams });
      }
      setSubmitted(upserted);
      /*
      Toast.show({
        type: 'success',
        visibilityTime: 10000,
        text1: translate('BOOKING_REQUESTED'),
        text2: translate('BOOKING_REQUESTED_DESCRIPTION')
      });
      */
    }
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter]}>
      <HeaderComponent title={service.title[locale]} subtitle={getPlanPrice(plan)} completed />
      <HeaderComponent title={plan.title[locale]} />
      <DynamicFormComponent
        template={plan.template}
        formValue={formValue}
        onChange={form => setFormValue(form)}
        submitted={submitted}
      />
      <Text style={[Theme.styles.container, Theme.styles.textJustify]}>
        { translate('SERVICE_CONFIRMATION_POLICY').replace(/<br>/g, '\n') }
      </Text>
      <ButtonComponent onPress={confirm} text={translate('CONFIRM')} disabled={loading} loading={loading} />
    </View>
  );
};

