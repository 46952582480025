import React from 'react';
import {
  Text,
  StyleSheet
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Theme } from '../../theme';
import { translate } from '../../lang';

const styles = StyleSheet.create({
  offlineBanner: {
    position: 'absolute',
    left: 0,
    right: 0,
    backgroundColor: Theme.palette.accent,
    padding: 4,
    fontSize: 12,
    color: Theme.palette.accentContrast,
    textAlign: 'center'
  }
});

export const OfflineBannerComponent = () => {
  const { top: paddingTop } = useSafeAreaInsets();
  const topSpace = {
    top: 38 + paddingTop
  };
  return (
    <Text style={[styles.offlineBanner, topSpace]}>{ translate('OFFLINE_BANNER') }</Text>
  );
};
