import React, { useEffect, useLayoutEffect, useContext, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';

import * as API from '../../common/api.service';
import { Theme } from '../../../theme';
import { Context } from '../../Context';
import { translate, getCurrentLocale, setLocale } from '../../../lang';
import { DynamicFormComponent } from '../../common/dynamic-form.component';
import { ACCOUNT_STATUS, STATUS_ICONS } from '../../common/status-label.component';
import { ButtonComponent } from '../../common/button.component';

export const ICONS = {
  default: {
    family: 'MaterialCommunityIcons',
    name: 'information-outline',
    size: 20,
    color: Theme.styles.text.color,
  },
  fullName: {
    family: 'MaterialCommunityIcons',
    name: 'format-color-text',
    size: 20,
    color: Theme.styles.text.color,
  },
  email: {
    family: 'MaterialCommunityIcons',
    name: 'email-outline',
    size: 20,
    color: Theme.styles.text.color,
  },
  phone: {
    family: 'MaterialCommunityIcons',
    name: 'phone',
    size: 20,
    color: Theme.styles.text.color,
  },
  datepicker: {
    family: 'MaterialCommunityIcons',
    name: 'calendar',
    size: 20,
    color: Theme.styles.text.color,
  }
};

export const DEFAULT_ACCOUNT_INFO_FIELDS = (locale) => [{
  key: 'fullName',
  label: {
    [locale]: translate('FULL_NAME'),
  },
}, {
  key: 'email',
  label: {
    [locale]: translate('EMAIL_ADDRESS'),
  },
}];

export const adminFields = (hotelConfig, formValue) => ([{
  icon: {
    family: 'MaterialIcons',
    name: 'verified-user',
    size: 20,
    color: Theme.styles.text.color,
  },
  key: 'admin',
  label: translate('ADMIN'),
  required: false,
  type: 'picker',
  options: [{
    label: translate('YES'),
    value: true,
  }, {
    label: translate('NO'),
    value: false,
  }],
}, {
  icon: {
    family: 'MaterialCommunityIcons',
    name: STATUS_ICONS,
    size: 20,
    color: Theme.styles.text.color,
  },
  key: 'status',
  label: translate('STATUS'),
  required: false,
  type: 'picker',
  options: ACCOUNT_STATUS.map(s => ({
    label: translate(s),
    value: s
  }))
}, {
  key: 'features',
  label: translate('FEATURES'),
  required: true,
  type: 'multi-picker',
  options: hotelConfig.features[formValue.admin ? 'admin' : 'guest'].map((o) => ({
    value: o.id,
    label: translate(o.id.toUpperCase()),
  })),
}, {
  key: 'roomNumber',
  label: translate('ROOM_NUMBER'),
  required: false,
  type: 'text',
  icon: {
    family: 'MaterialCommunityIcons',
    name: 'key-variant',
    size: 20,
    color: Theme.styles.text.color,
  },
  condition: {
    admin: false
  }
}]);

const styles = StyleSheet.create({
  width: {
    width: '100%',
    maxWidth: '100%'
  },
  guestAccount: {
    width: Theme.XL ? '100%' : Theme.width,
    flex: 1,
    ...Theme.styles.alignCenterCenter
  }
});

export const AccountInfoComponent = ({ navigation, route, onSave, onDelete, keyboardPadding }) => {
  const context = useContext(Context);
  const [loading, setLoading] = useState(false);
  const isFocused = useIsFocused();
  const [currentLocale, setCurrentLocale] = useState(getCurrentLocale());
  const [formValue, setFormValue] = useState(route.params.guest || context.user);

  useEffect(() => {
    setFormValue(route.params.guest || context.user);
  }, [route.params.guest, context.user]);

  const accountInfo = (context.hotelConfig?.data?.guests?.accountFields || DEFAULT_ACCOUNT_INFO_FIELDS(currentLocale || 'en')).map(field => ({
    ...field,
    icon: field.type !== 'switch' ? ICONS[field.key] || ICONS[field.type] || ICONS.default : null
  }));
  const template = context.user.admin && context.user.id !== formValue.id ? adminFields(context.hotelConfig.data, formValue).concat(accountInfo) : accountInfo;

  const save = async() => {
    setLoading(true);
    // Safe filtering features only belonging to that type of user
    formValue.features = context.hotelConfig.data.features[formValue.admin ? 'admin' : 'guest'].reduce((agg, feat) => {
      if (formValue.features.includes(feat.id)) agg.push(feat.id);
      return agg;
    }, []);
    if (formValue.id === context.user.id) {
      context.user = { ...formValue };
    }
    await (API.crud({
      operation: '_update',
      table: 'accounts',
      query: formValue,
    })).finally(() => setLoading(false));
    return onSave();
  };

  const remove = () => {
    return Toast.show({
      type: 'action',
      autoHide: false,
      text1: translate('CONFIRM_ACTION'),
      text2: translate('CONFIRM_ACTION_DESCRIPTION'),
      props: {
        actions: [{
          text: translate('NO'),
          onPress: Toast.hide
        }, {
          text: translate('YES'),
          class: 'error',
          onPress: async () => {
            setLoading(true);
            await (API.crud({
              operation: '_delete',
              table: 'accounts',
              query: {
                Key: {
                  hotelId: formValue.hotelId,
                  id: formValue.id
                }
              }
            })).finally(() => setLoading(false));
            Toast.hide();
            if (onDelete && typeof onDelete === 'function') {
              return onDelete();
            }
          }
        }]
      }
    });
  };

  const onChange = (value) => {
    updateLocale(value.locale);
    setFormValue({...value});
  };

  const updateLocale = async (value) => {
    if (value && value !== currentLocale) {
      await setLocale(value);
      setCurrentLocale(value);
      context.user.locale = value;
      API.setContext({
        ...context,
        locale: value
      });
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: onSave && isFocused ? () => (
        <TouchableOpacity onPress={save} style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} disabled={loading}>
          { loading ? (
            <ActivityIndicator color={Theme.palette.primaryContrast} />
          ) : (
            <MaterialCommunityIcons name='check' color={Theme.palette.primaryContrast} size={25} />
          )}
        </TouchableOpacity>
      ) : null
    });
  }, [navigation, loading, formValue, isFocused]);

  return (
    <View style={Theme.styles.flex}>
      <View style={[Theme.styles.column, Theme.styles.alignCenterStart, Theme.styles.flex]}>
        <View style={[Theme.styles.flex, Theme.styles.width]}>
          <View style={Theme.styles.flex}>
            { !context.user.guest ? (
              <DynamicFormComponent
                type='no-border'
                template={template}
                formValue={formValue}
                onChange={onChange}
                style={styles.dynamicForm}
                keyboardPadding={keyboardPadding}
                submit={save}
              />
            ) : (
              <View style={styles.guestAccount}>
                <TouchableOpacity onPress={() => API.setContext({ ...context, showSignUp: true })}>
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.description, Theme.styles.fontSize16, Theme.styles.padding32]}>{ translate('NO_ACCOUNT_INFO') }</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
      { onDelete ? (
        <View style={[!Theme.XL ? Theme.styles.padding10 : Theme.styles.paddingBottom10, Theme.styles.width]}>
          <ButtonComponent text={translate('REMOVE_ACCOUNT')} type='error' onPress={remove} loading={loading} outlined={!Theme.XL} />
        </View>
      ) : null}
    </View>
  );
};
