import moment from 'moment';
import React, { useEffect, useLayoutEffect, useContext, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import CheckBox from 'expo-checkbox';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';
import uuid from 'react-native-uuid';

import * as API from '../../common/api.service';
import { Theme } from '../../../theme';
import { translate } from '../../../lang';
import { Context } from '../../Context';
import { LocalizedInput } from '../../common/localized-input.component';
import { HeaderComponent } from '../../common/header.component';
import { ButtonComponent } from '../../common/button.component';
import { InlineInputComponent } from '../../common/inline-input.component';
import { DatePickerComponent } from '../../common/datepicker.component';
import { Picker } from '../../common/libs/picker/picker';

const styles = StyleSheet.create({
  inputBorder: {
    borderBottomWidth: 1,
    borderBottomColor: 'black'
  },
  weekday: {
    borderRadius: 20,
    width: 35,
    height: 35,
    borderWidth: 1,
    borderColor: Theme.palette.backgroundDark
  },
  weekdayActive: {
    borderWidth: 0,
    backgroundColor: Theme.palette.accent
  },
  stripped: {
    backgroundColor: Theme.palette.stripped,
    marginHorizontal: -8,
    paddingHorizontal: 16
  },
  actionColumn: {
    width: 30,
    ...Theme.styles.alignEndCenter
  },
});

export const isResourceAvailable = (resource, date) => {
  // TODO Allow date to be daterange
  const today = new Date().toISOString().split('T')[0];
  date = date || today;
  const nextUnavailability = resource.unavailabilities?.
    filter(u => u.end < today).
    sort((u1, u2) => u1.start > u2.start ? -1 : 1)?.[0];
  return nextUnavailability?.start > date || resource.weekdays.includes(new Date().getDay());
};

export const ResourceTypes = {
  room: {
    label: translate('ROOM'),
    labelPlural: translate('ROOMS'),
    icon: 'bed',
    value: 'room',
  },
  staff: {
    label: translate('STAFF'),
    icon: 'account-circle',
    value: 'staff',
  },
  transport: {
    label: translate('TRANSPORT'),
    icon: 'sail-boat',
    value: 'transport',
  },
  equipment: {
    label: translate('EQUIPMENT'),
    icon: 'tools',
    value: 'equipment',
  },
  other: {
    label: translate('OTHER'),
    labelPlural: translate('OTHERS'),
    icon: 'dots-vertical',
    value: 'other',
  }
};

export const ResourceDetailComponent = ({ navigation, route }) => {
  const today = new Date().toISOString().split('T')[0];
  let { config, resource } = (route.params || {});
  const context = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(resource?.name || {});
  const [type, setType] = useState(resource?.type || 'other');
  const [weekdays, setWeekdays] = useState(resource?.weekdays || [0, 1, 2, 3, 4, 5, 6]);
  const [unavailabilities, setUnavailabilities] = useState(
    resource?.unavailabilities?.
      filter(u => u.end > today).
      sort((u1, u2) => u1.start > u2.start ? -1 : 1) ||
    []
  );
  const [nextUnavailability, setNextUnavailability] = useState(unavailabilities?.[0] || null);
  const [allowance, setAllowance] = useState(resource?.allowance || {
    adults: true,
    kids: true,
    infants: true,
    min: 1,
    max: 10
  });
  const [shareable, setShareable] = useState(resource?.shareable || false);

  useEffect(() => {
    const next = [...(unavailabilities || [])].sort((u1, u2) => u1.start > u2.start ? -1 : 1)[0];
    setNextUnavailability(next?.start && next?.end ? next : null);
  }, [unavailabilities]);

  const setUnavailability = (idx, value, field) => {
    unavailabilities[idx][field] = value;
    setUnavailabilities([...unavailabilities]);
  };

  const toggleWeekDay = (day) => {
    const idx = weekdays.indexOf(day);
    if (idx === -1) {
      weekdays.push(day);
    } else {
      weekdays.splice(idx, 1);
    }
    setWeekdays([ ...weekdays ]);
  };

  const save = () => {
    if (!Object.values(name).some(val => val)) {
      return Toast.show({
        type: 'error',
        autoHide: true,
        text1: translate('FORM_SUBMIT_ERROR'),
        text2: translate('FORM_SUBMIT_ERROR_DESCRIPTION'),
      });
    }
    Toast.hide();
    setLoading(true);
    setTimeout(async () => {
      // Making all this logic async to allow loading spinner to change
      resource = resource || {};
      resource.name = name;
      resource.type = type;
      resource.weekdays = weekdays;
      resource.unavailabilities = unavailabilities;
      resource.allowance = allowance;
      resource.shareable = shareable;
      if (!resource.id) {
        // Creating new resource
        config.resources = config.resources || [];
        config.resources.push({
          id: uuid.v4(),
          ...resource
        });
      }
      await (API.crud({
        operation: '_update',
        table: 'hotels',
        query: context.hotelConfig,
      })).finally(() => setLoading(false));
      navigation.navigate(route.params.navigateTo || 'ConfigResourcesList', {
        ...route.params
      });
    }, 0);
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16 : null]} onPress={save} disabled={loading}>
          { loading ? (
            <ActivityIndicator color={Theme.palette.primaryContrast} />
          ) : (
            <MaterialCommunityIcons name='check' color={Theme.palette.primaryContrast} size={25} />
          )}
        </TouchableOpacity>
      ),
    });
  }, [navigation, loading, name, type, weekdays, unavailabilities, allowance, shareable]);

  const confirmRemove = (array, setArray) => {
    return ({ item }) => {
      return Toast.show({
        type: 'action',
        autoHide: false,
        text1: translate('CONFIRM_ACTION'),
        text2: translate('CONFIRM_ACTION_DESCRIPTION'),
        props: {
          actions: [{
            text: translate('NO'),
            onPress: Toast.hide
          }, {
            text: translate('YES'),
            class: 'error',
            onPress: () => {
              array.splice(array.indexOf(item), 1);
              if (setArray) setArray([...array]);
              Toast.hide();
            }
          }]
        }
      });
    };
  };

  const deleteResource = () => {
    return Toast.show({
      type: 'action',
      autoHide: false,
      text1: translate('CONFIRM_ACTION'),
      text2: translate('CONFIRM_ACTION_DESCRIPTION'),
      props: {
        actions: [{
          text: translate('NO'),
          onPress: Toast.hide
        }, {
          text: translate('YES'),
          class: 'error',
          onPress: async () => {
            setLoading(true);
            const index = config.resources.indexOf(resource);
            if (index !== -1) {
              config.resources.splice(index, 1);
            }
            await (API.crud({
              operation: '_update',
              table: 'hotels',
              query: context.hotelConfig,
            })).finally(() => setLoading(false));
            Toast.hide();
            navigation.navigate('ConfigResourcesList', {
              ...route.params
            });
          }
        }]
      }
    });
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <ScrollView style={Theme.styles.height} contentContainerStyle={[Theme.styles.column, Theme.styles.alignStretchStart, Theme.XL ? Theme.styles.paddingHorizontal16 : null]}>
        <HeaderComponent title={translate('RESOURCE')} />
        <LocalizedInput value={name} onChange={setName} label={translate('NAME')} required />
        <View style={Theme.styles.paddingHorizontal8}>
          <Picker
            placeholder={{}}
            value={type || 'other'}
            onValueChange={setType}
            label={translate('TYPE')}
            items={Object.values(ResourceTypes)}
          >
            <View style={[Theme.styles.pickerInput, Theme.styles.row]}>
              <View>
                <Text style={Theme.styles.inputLabel}>{translate('TYPE')}</Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(type?.toUpperCase() || 'OTHER')}</Text>
              </View>
            </View>
          </Picker>
        </View>
        <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]} />
        <HeaderComponent title={translate('AVAILABLE_DAYS')} />
        <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween, Theme.styles.paddingLeft8, Theme.styles.paddingRight8]}>
          { moment.weekdaysMin().map((day, idx) => (
            <TouchableOpacity key={idx} style={[Theme.styles.row, Theme.styles.alignCenterCenter, styles.weekday, (weekdays || []).includes(idx) ? styles.weekdayActive : null]} onPress={() => toggleWeekDay(idx)}>
              <Text>{day}</Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]} />
        <HeaderComponent title={translate('UNAVAILABILITIES')} actionText={translate('ADD')} onPress={() => setUnavailabilities([...unavailabilities, {start: today, end: today}])} outlined />
        <Text style={[Theme.styles.padding8, Theme.styles.text, Theme.styles.description]}>
          { !nextUnavailability
            ? translate('RESOURCE_AVAILABLE')
            : nextUnavailability.start > today
              ? translate('RESOURCE_AVAILABLE_UNTIL', nextUnavailability)
              : translate('RESOURCE_UNAVAILABLE_UNTIL', nextUnavailability)
          }
        </Text>
        { unavailabilities.length ? (
          <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
            <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex]}>
              {translate('START_DATE')}
            </Text>
            <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.flex]}>
              {translate('END_DATE')}
            </Text>
            <View style={styles.actionColumn}>
            </View>
          </View>
        ) : null}
        { unavailabilities.map((unavailability, idx) => (
          <View key={idx} style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween, idx % 2 !== 0 ? styles.stripped : null]}>
            <View style={[Theme.styles.flex, Theme.styles.paddingRight6]}>
              <DatePickerComponent
                value={unavailability.start}
                onChange={(value) => setUnavailability(idx, value, 'start')}
              />
            </View>
            <View style={[Theme.styles.flex, Theme.styles.paddingLeft6]}>
              <DatePickerComponent
                minDate={unavailability.start}
                value={unavailability.end}
                onChange={(value) => setUnavailability(idx, value, 'end')}
              />
            </View>
            <TouchableOpacity style={styles.actionColumn} onPress={() => confirmRemove(unavailabilities, () => setUnavailabilities([...unavailabilities]))({ item: unavailability })}>
              <MaterialCommunityIcons name='delete' color={Theme.palette.text} size={20} />
            </TouchableOpacity>
          </View>
        ))}
        <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]} />
        <HeaderComponent title={translate('ALLOWANCE')} />
        <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween, Theme.styles.paddingLeft8, Theme.styles.paddingRight8, Theme.styles.paddingBottom8]}>
          <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter]} onPress={()=> setAllowance({...allowance, adults: !allowance.adults})}>
            <CheckBox
              onValueChange={() => setAllowance({...allowance, adults: !allowance.adults})}
              color={allowance.adults? Theme.palette.accent : null }
              style={Theme.styles.checkbox}
              value={allowance.adults}
            />
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8]}>
              { translate('ADULTS') }
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter]} onPress={()=> setAllowance({...allowance, kids: !allowance.kids})}>
            <CheckBox
              onValueChange={() => setAllowance({...allowance, kids: !allowance.kids})}
              color={allowance.kids? Theme.palette.accent : null }
              style={Theme.styles.checkbox}
              value={allowance.kids}
            />
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8]}>
              { translate('KIDS') }
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignCenterCenter]} onPress={()=> setAllowance({...allowance, infants: !allowance.infants})}>
            <CheckBox
              onValueChange={() => setAllowance({...allowance, infants: !allowance.infants})}
              color={allowance.infants? Theme.palette.accent : null }
              style={Theme.styles.checkbox}
              value={allowance.infants}
            />
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8]}>
              { translate('INFANTS') }
            </Text>
          </TouchableOpacity>
        </View>
        <InlineInputComponent label={translate('MINIMUM')} value={allowance.min} onChange={value => setAllowance({...allowance, min: value})} keyboardType='numeric' inputComponent={TextInput} />
        <InlineInputComponent label={translate('MAXIMUM')} value={allowance.max} onChange={value => setAllowance({...allowance, max: value})} keyboardType='numeric' inputComponent={TextInput} />
        <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]} />
        <HeaderComponent title={translate('SHAREABLE')} subtitleComponent={
          <Switch
            style={Theme.styles.activeButton}
            onValueChange={setShareable}
            value={shareable}
          />
        } />
        <Text style={[Theme.styles.flex, Theme.styles.text, Theme.styles.textDark, Theme.styles.description, Theme.styles.paddingHorizontal8, Theme.styles.paddingBottom16]}>
          { translate('SHAREABLE_DESCRIPTION') }
        </Text>
        { resource?.id ? (
          <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]}>
            <ButtonComponent text={translate('DELETE_RESOURCE')} type='error' onPress={deleteResource} outlined />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};
