import React, {
  useContext,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  View,
  ActivityIndicator,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import BottomSheet, {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetTextInput,
  BottomSheetModal,
  BottomSheetModalProvider,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../../common/libs/bottom-sheet';
import uuid from 'react-native-uuid';
import Toast from 'react-native-toast-message';
import useKeyboard from '@rnhooks/keyboard';
import StarRating from 'react-native-star-rating-widget';

import { Context } from '../../Context';
import { Theme } from '../../../theme';
import { translate } from '../../../lang';
import * as API from '../../common/api.service';
import { HeaderComponent } from '../../common/header.component';
import { ButtonComponent } from '../../common/button.component';

const styles = StyleSheet.create({
  input: {
    ...Theme.styles.padding8,
    ...Theme.styles.text,
    ...Theme.styles.textDark,
    borderBottomColor: Theme.palette.textDark,
    borderBottomWidth: 1,
    paddingTop: 0
  },
  inputPlaceholder: {
    color: Theme.palette.text
  },
  textInput: {
    marginTop: 8,
    marginBottom: 10,
    borderRadius: 2,
    fontSize: 16,
    lineHeight: 20,
    padding: 8,
    borderWidth: 1,
    borderColor: Theme.palette.textDark,
    color: Theme.palette.textDark
  },
  translateButton: {
    marginLeft: 16,
    marginRight: 8
  },
  recommendedMax: {
    marginTop: -4
  }
});

export const ReviewResponseComponent = ({ booking, onChange }) => {
  const context = useContext(Context);
  const [ visible ] = useKeyboard();
  const bottomSheetModalRef = useRef(null);
  const [response, setResponse] = useState(booking?.review?.response || {});
  const [loading, setLoading] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const snapPoints = useMemo(() => ['50%'], []);

  const edit = () => {
    bottomSheetModalRef.current?.present();
  };
  const save = async (remove = false) => {
    Toast.hide();
    const { bookingContext, ...bookingItem } = booking;
    let update = {
      ...bookingItem,
      review: {
        ...bookingItem.review,
        response
      }
    };
    if (remove) {
      setLoading('remove');
      delete update.review.response;
    } else {
      if (!response.comment) {
        return setSubmitted(true);
      }
      setLoading('save');
      update.review.response.date = new Date().toISOString();
    }
    await Promise.all([
      API.crud({
        operation: '_update',
        table: 'reviews',
        query: update.review
      }),
      API.crud({
        operation: '_update',
        table: 'bookings',
        query: update
      })
    ]);
    onChange({
      bookingContext,
      ...update
    });
    setLoading('');
    bottomSheetModalRef.current?.dismiss();
  };

  // renders
  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);


  const renderFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props} >
        <View style={Theme.styles.row}>
          { booking?.review?.response ? (
            <View style={Theme.styles.flex}>
              <ButtonComponent safeArea={true} onPress={() => Toast.show({
                type: 'action',
                autoHide: false,
                text1: translate('CONFIRM_ACTION'),
                text2: translate('REMOVE_REVIEW_DESCRIPTION'),
                props: {
                  actions: [{
                    text: translate('NO'),
                    onPress: Toast.hide
                  }, {
                    text: translate('YES'),
                    class: 'error',
                    onPress: () => save(true)
                  }]
                }
              })} text={translate('REMOVE')} type='error' loading={loading === 'remove'} disabled={loading} />
            </View>
          ) : null
          }
          <View style={Theme.styles.flex}>
            <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={() => save()} loading={loading === 'save'} disabled={loading} />
          </View>
        </View>
      </BottomSheetFooter>
    );
  }, [visible, response, loading]);

  return (
    <View style={Theme.styles.flex}>
      <ButtonComponent safeArea={true} text={translate('ANSWER_REVIEW')} onPress={edit} />
      <BottomSheetModal
        ref={bottomSheetModalRef}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderFooter}
      >
        <BottomSheetScrollView>
          <View style={Theme.styles.paddingLeft8}>
            <HeaderComponent title={translate('ANSWER_REVIEW')} />
          </View>
          <View style={[Theme.styles.padding16, Theme.styles.paddingBottom60]}>
            <View style={Theme.styles.paddingTop8}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1]}>{translate('COMMENT')}</Text>
              <View style={[Theme.styles.row, Theme.styles.alignCenterStart]}>
                <BottomSheetTextInput style={[Theme.styles.flex, styles.textInput]} onChangeText={text => setResponse({...response, comment: text})} value={response.comment} numberOfLines={10} multiline={true} />
              </View>
            </View>
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>
    </View>
  );
};

