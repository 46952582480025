import moment from 'moment';
import React, { useEffect, useContext, useState } from 'react';
import {
  ActivityIndicator,
  View,
} from 'react-native';
import Toast from 'react-native-toast-message';

import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { getDateString, getPlanPrice, getFinalPrice, getNextRoute, getPriceString } from '../common/utils.service';
import { HeaderComponent } from '../common/header.component';
import { ButtonComponent } from '../common/button.component';
import { DynamicFormComponent } from '../common/dynamic-form.component';
import { upsertBooking } from '../bookings/service';
import { PolicyComponent } from '../common/policy.component';

export const AdditionalInformationComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const { group, service, plan, slot, date, type, booking } = route.params;
  const locale = getCurrentLocale();
  const [submitted, setSubmitted] = useState(false);
  const [formValue, setFormValue] = useState(booking?.additionalInformation || {});
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [price, setPrice] = useState(route.params?.price || 0);
  const [loading, setLoading] = useState(false);
  const nextRoute = getNextRoute('ServiceAdditionalInfo', service);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const getPrice = async () => {
      setLoadingPrice(true);
      const finalPrice = await getFinalPrice(group, date, getPlanPrice(plan, booking.attendees, date, formValue), booking.attendees, route.params?.discount);
      setLoadingPrice(false);
      return setPrice(getPriceString(parseFloat(finalPrice)));
    };
    if (mounted) {
      getPrice();
    }
  }, [formValue, mounted]);

  const confirm = async () => {
    setSubmitted(true);
    if (plan.additionalInformation.every(field => !field.required || (Array.isArray(formValue[field.key]) ? formValue[field.key].length : formValue[field.key] !== undefined))) {
      //setLoading(true);
      const upsertingBooking = {
        ...booking,
        additionalInformation: formValue,
        price: await getFinalPrice(group, date, getPlanPrice(plan, booking.attendees, date, formValue), booking.attendees, route.params?.discount),
      };
      const upserted = await upsertBooking(context, group, slot || plan, upsertingBooking, setLoading);
      if (upserted) {
        navigation.navigate(route.params.navigateTo || `${type}ServiceGroups`, { ...route.params.navigateToParams });
      }
      setSubmitted(upserted);
    } else {
      return Toast.show({
        type: 'error',
        autoHide: false,
        text1: translate('FORM_SUBMIT_ERROR'),
        text2: translate('FORM_SUBMIT_ERROR_DESCRIPTION'),
      });
    }
  };

  const replaceMessagePlaceholders = (text, input, values) => {
    // TODO Generalize placeholders a bit more
    const totalAttendees = booking.attendees.total;
    const totalDays = !booking.endDate ? 1 : moment(booking.endDate).diff(booking.startDate, 'days');
    const totalPerDay = values[input.key] / totalDays;
    const totalPerDayFloor = Math.floor(totalPerDay);
    const totalPerDayCeil = Math.ceil(totalPerDay);
    const totalPerDayRange = totalPerDay === totalPerDayFloor ? totalPerDay : `${totalPerDayFloor}-${totalPerDayCeil}`;
    const grandTotal = values[input.key] * totalAttendees;
    return text.replace('${totalPerDayRange}', totalPerDayRange).replace('${grandTotal}', grandTotal);
  };

  const buildInput = (input) => {
    const totalDays = !booking.endDate ? 1 : moment(booking.endDate).diff(booking.startDate, 'days');
    let min = input.min;
    let max = input.max;
    let def = input.default;
    if (input.minBasis === 'day') {
      min = input.min * totalDays;
    }
    if (input.maxBasis === 'day') {
      max = input.max * totalDays;
    }
    if (input.defaultBasis === 'day') {
      def = input.default * totalDays;
    } else if (input.defaultBasis === 'average') {
      // Default as average
      def = Math.floor((input.min + input.max) / 2);
    }
    return {
      ...input,
      min,
      max,
      default: def
    };
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <HeaderComponent title={service.title[locale]} subtitle={price} subtitleComponent={loadingPrice ? (<ActivityIndicator color={Theme.palette.textDark} />) : null} completed />
      <HeaderComponent title={plan.dateRange ? translate('DATES') : translate('DATE')} subtitle={getDateString(date, slot, plan)} subtitleMultiline={true} completed />
      <HeaderComponent title={translate('ATTENDEES')} subtitle={booking.attendees.total || 1} completed />
      <HeaderComponent title={translate('ADDITIONAL_INFORMATION')} />
      <DynamicFormComponent
        template={plan.additionalInformation}
        formValue={formValue}
        onChange={form => setFormValue(form)}
        submitted={submitted}
        getMessage={replaceMessagePlaceholders}
        buildInput={buildInput}
      />
      { !nextRoute ? (
        <PolicyComponent service={service} plan={plan} locale={locale} />
      ) : null}
      <ButtonComponent onPress={confirm} text={translate('CONFIRM')} disabled={loading} loading={loading} />
    </View>
  );
};

