import moment from 'moment';
import React, { useState } from 'react';

export const toUTC = (dateTime) => {
  return new Date(new Date(dateTime).getTime() - 60000 * new Date().getTimezoneOffset());
};

export const fromUTC = (dateTime) => {
  return new Date(new Date(dateTime).getTime() + 60000 * new Date().getTimezoneOffset());
};

export const TimePicker = ({ value, onChange }) => {
  const [_value, setValue] = useState(value ? moment(fromUTC(value)).format('HH:mm') : '09:00');
  const _onChange = (evt) => {
    const [hour, minute] = (evt?.target?.value || '09:00').split(':');
    if (hour && minute) {
      value = moment(value).set({ hour, minute }).toDate();
    }
    setValue(`${hour}:${minute}`);
    return onChange(toUTC(value));
  };

  return (
    <input
      type="time"
      value={_value}
      onChange={_onChange}
    />
  );
};
