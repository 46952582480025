import moment from 'moment/min/moment-with-locales';
import React, { useCallback, useMemo, useRef, useContext, useState } from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  Platform,
  View,
} from 'react-native';
import ActionButton from 'react-native-action-button';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';
import uuid from 'react-native-uuid';
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../../../common/libs/bottom-sheet';
import useKeyboard from '@rnhooks/keyboard';

import { Theme } from '../../../../theme';
import { translate, getCurrentLocale } from '../../../../lang';
import { Context } from '../../../Context';
import { HeaderComponent } from '../../../common/header.component';
import { ButtonComponent } from '../../../common/button.component';
import { getRecurringDateString } from '../../../common/utils.service';
import { AnimateService } from '../../../common/animate.service';
import { ListItemComponent } from '../../../common/list-item.component';
import { DynamicFormComponent } from '../../../common/dynamic-form.component';
import { toUTC, fromUTC } from '../../../common/libs/time-picker/time-picker';

const DEFAULT_SCHEDULE = {
  plan: '',
  startDate: '',
  startTime: toUTC(new Date()),
  periodicity: {
    id: 'none'
  },
  span: 0,
  duration: 0,
  maxAtt: 0,
};

const styles = StyleSheet.create({
  dynamicForm: {
    input: {
      borderWidth: 0,
      borderBottomWidth: 1,
      borderColor: Theme.palette.backgroundDark
    },
    inputs: {
      datepicker: {
        input: {
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: Theme.palette.backgroundDark
        }
      },
      picker: {
        container: {
          maxWidth: Theme.width - 32,
          borderWidth: 0,
          borderBottomWidth: 1,
          borderColor: Theme.palette.backgroundDark
        }
      }
    }
  }
});

const SPAN_MAP = {
  'none': 0,
  'daily': 7,
  'weekly': 4,
  'monthly': 4,
  'yearly': 1
};

export const ContentDetailScheduledComponent = ({ route, state }) => {
  let { service } = (route.params || {});
  const context = useContext(Context);
  const [ visible ] = useKeyboard();
  const bottomSheetModalRef = useRef(null);
  const locale = getCurrentLocale();
  const [scheduled, setScheduled] = useState([
    ...(service?.scheduled?.filter(s => s.endDate || s.startDate >= new Date().toISOString().split('T')[0]) || [])
  ]);
  const [formValue, setFormValue] = useState(null);
  const snapPoints = useMemo(() => ['85%'], []);
  const dynamicFormApi = {};
  const plansMap = service.plans.reduce((map, current) => {
    map[current.id] = current;
    return map;
  }, {});

  const template = [{
    key: 'startDate',
    type: 'datepicker',
    label: translate('DATE'),
    required: true
  }, {
    key: 'startTime',
    type: 'timepicker',
    label: translate('TIME'),
    required: true
  }, {
    key: 'periodicity',
    label: translate('PERIODICITY'),
    required: true,
    type: 'picker',
    options: [{
      label: translate('NONE'),
      value: 'none'
    }, {
      label: translate('DAILY'),
      value: 'daily'
    }, {
      label: translate('WEEKLY'),
      value: 'weekly'
    }, {
      label: translate('MONTHLY'),
      value: 'monthly'
    }, {
      label: translate('YEARLY'),
      value: 'yearly'
    }]
  }, {
    key: 'endDate',
    type: 'datepicker',
    label: translate('END_DATE'),
    required: true,
    condition: {
      periodicity: '!none'
    }
  }, {
    key: 'span',
    type: 'number',
    label: translate('ANTICIPATION'),
    message: {
      [locale]: translate('ANTICIPATION_HINT')
    },
    min: 1,
    required: true,
    condition: {
      periodicity: '!none'
    }
  }, {
    key: 'duration',
    type: 'numeric',
    label: `${translate('DURATION')} (${translate('MIN')})`,
    required: false
  }, {
    key: 'maxAtt',
    type: 'number',
    label: translate('MAXIMUM_ATTENDEES'),
    required: false,
    min: 1,
  }];
  if (service?.plans?.length) {
    template.unshift({
      key: 'plan',
      label: translate('PLAN'),
      required: true,
      type: 'picker',
      options: service.plans.map(p => ({
        label: p.title[locale],
        value: p.id
      }))
    });
  }

  service = service || {};

  const openSchedule = (schedule = { ...DEFAULT_SCHEDULE }) => {
    setFormValue(schedule);
    bottomSheetModalRef.current?.present();
  };

  const dynamicFormOnChange = (form) => {
    if (form.plan && form.plan !== formValue.plan) {
      const plan = plansMap[form.plan];
      if (!form.duration) {
        form.duration = plan.duration?.toString() || form.duration;
      }
      if (!form.maxAtt) {
        form.maxAtt = plan.attendees?.total?.toString() || form.maxAtt;
      }
    }
    if (form.periodicity !== formValue.periodicity) {
      form.span = SPAN_MAP[form.periodicity] || SPAN_MAP.none;
    }
    setFormValue({...form});
  };

  const confirmRemove = ({ item }) => {
    return Toast.show({
      type: 'action',
      autoHide: false,
      text1: translate('CONFIRM_ACTION'),
      text2: translate('CONFIRM_ACTION_DESCRIPTION'),
      props: {
        actions: [{
          text: translate('NO'),
          onPress: Toast.hide
        }, {
          text: translate('YES'),
          class: 'error',
          onPress: async () => {
            scheduled.splice(scheduled.indexOf(item), 1);
            setScheduled([...scheduled]);
            state.scheduled = { scheduled };
            Toast.hide();
            bottomSheetModalRef.current?.dismiss();
          }
        }]
      }
    });
  };

  const saveSchedule = () => {
    if (formValue.id) {
      const index = scheduled.findIndex(s => s.id === formValue.id);
      scheduled[index] = formValue;
    } else {
      formValue.id = uuid.v4();
      scheduled.push(formValue);
    }
    setScheduled([
      ...scheduled,
    ]);
    service = service || {};
    state.scheduled = { scheduled };
    bottomSheetModalRef.current?.dismiss();
  };

  const replaceMessagePlaceholders = (text, input, values) => {
    return text.replace('${span}', values.span || 0).replace('${unit}', translate(`${values.periodicity}_unit`.toUpperCase()));
  };

  /* Renderers */
  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);


  const renderFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props} >
        <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={dynamicFormApi.validate} />
      </BottomSheetFooter>
    );
  }, [visible, formValue, scheduled]);

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <FlatList
        data={scheduled}
        style={[Theme.styles.height, Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop16] : null]}
        contentContainerStyle={(!scheduled || !scheduled.length) && Theme.styles.flex}
        numColumns={Theme.numberOfColumns}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => (
          <AnimateService
            properties={['opacity', 'left', 'top']}
            style={Theme.XL ? Theme.styles.flex50 : null}
            delay={index * 50}
            duration={500}
            Component={() => (
              <TouchableOpacity onPress={() => openSchedule(item)} >
                <ListItemComponent
                  title={plansMap[item.plan].title[locale]}
                  subtitle={getRecurringDateString(item)}
                  ActionComponent={() => (
                    <View style={[Theme.styles.column, Theme.styles.alignEndCenter]}>
                      <View style={Theme.styles.row}>
                        <MaterialCommunityIcons name='human-greeting' color={Theme.styles.text.color} size={16} />
                        <Text style={[Theme.styles.text, Theme.styles.letterSpacing0]}> { item.maxAtt } </Text>
                      </View>
                      <View style={Theme.styles.row}>
                        <MaterialCommunityIcons name='timer-outline' color={Theme.styles.text.color} size={16} />
                        <Text style={[Theme.styles.text, Theme.styles.letterSpacing0]}> { moment(fromUTC(item.startTime)).format('LT') } </Text>
                      </View>
                    </View>
                  )}
                />
              </TouchableOpacity>
            )}
          />
        )}
      />
      <BottomSheetModal
        ref={bottomSheetModalRef}
        style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.padding0] : null}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderFooter}
      >
        <BottomSheetScrollView>
          <View style={Theme.styles.paddingHorizontal8}>
            <HeaderComponent title={translate(formValue?.id ? 'EDIT_SCHEDULE' : 'CREATE_SCHEDULE')} />
          </View>
          <View>
            <DynamicFormComponent
              api={dynamicFormApi}
              template={template}
              formValue={formValue}
              onChange={dynamicFormOnChange}
              style={styles.dynamicForm}
              submit={saveSchedule}
              getMessage={replaceMessagePlaceholders}
              keyboardPadding={50}
            />
          </View>
          <View style={[Theme.styles.paddingHorizontal8, Theme.styles.paddingBottom80]}>
            { formValue?.id ? (
              <View style={[Theme.styles.padding8, Theme.styles.paddingTop16]}>
                <ButtonComponent text={translate('REMOVE')} type='error' onPress={() => confirmRemove(formValue)} outlined />
              </View>
            ) : null}
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>
      <ActionButton buttonColor={Theme.palette.accent} onPress={openSchedule} hideShadow={Platform.OS === 'web'} />
    </View>
  );
};
