import * as ImagePicker from 'expo-image-picker';
import * as Device from 'expo-device';

import { translate } from '../../lang';
import * as Compressor from './libs/compressor/compressor';

const DEFAULT_OPTIONS = {
  PHONE: [translate('GALLERY'), translate('CAMERA'), translate('CLOSE')],
  TABLET: [translate('GALLERY'), translate('CAMERA'), translate('CLOSE')],
  DESKTOP: [translate('GALLERY'), translate('CLOSE')],
};

const DEFAULT_PICKER_PARAMS = {
  mediaTypes: ImagePicker.MediaTypeOptions.All,
  allowsEditing: true,
  base64: false,
  //aspect: [4, 3],
  quality: 1
};

const IMAGE_COMPRESSION_OUTPUTS = {
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpg'
};

const parseFileMime = (file) => {
  let type = file.type;
  let extension = file.extension;
  if (!type || !extension) {
    const base64Uri = file.uri.substring(0, 100);
    const [ isBase64, base64Type, base64Extension ] = base64Uri.match(/data:(.+)\/(.+);base64,/) || [];
    if (isBase64) {
      type = base64Type;
      extension = base64Extension;
    } else {
      const [ isFilePath, filePathExtension ] = file.uri.match(/\.([0-9a-z]+)$/i) || [];
      if (isFilePath) {
        extension = filePathExtension;
        switch (extension) {
        case 'gif':
        case 'tiff':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'heic':
          type = 'image';
          break;
        case 'mp4':
        case 'mov':
        case '3gp':
          type = 'video';
          break;
        }
      }
    }
  }
  return { type, extension };
};

const compress = async file => {
  let uri;
  let { extension, type } = parseFileMime(file);
  switch (type) {
  case 'image':
    uri = await Compressor.Image.compress(file.uri, {
      maxWidth: 1000,
      quality: 0.8,
      output: IMAGE_COMPRESSION_OUTPUTS[extension.toUpperCase()] || IMAGE_COMPRESSION_OUTPUTS.PNG,
      //returnableOutputType: 'base64'
    });
    break;
  case 'video':
    uri = await Compressor.Video.compress(file.uri, {
      maxSize: 1000,
      quality: 0.8
    });
    /*
    if (Platform.OS !== 'web') {
      uri = await readFile(uri, 'base64');
    }
    */
    break;
  }
  return {
    type,
    extension,
    uri
  };
};

export const openMediaPicker = async (actionSheet, options, params = DEFAULT_PICKER_PARAMS) => {
  if (!options) {
    options = DEFAULT_OPTIONS[Device.DeviceType[await Device.getDeviceTypeAsync()]] || DEFAULT_OPTIONS.PHONE;
  }
  return new Promise((resolve, reject) => {
    const cancelButtonIndex = 2;

    actionSheet({
      options,
      cancelButtonIndex,
    }, async (selectedIndex) => {
      let permissions;
      let file ;
      switch (selectedIndex) {
      case 0:
        permissions = await ImagePicker.getMediaLibraryPermissionsAsync();
        if (permissions) {
          file = await ImagePicker.launchImageLibraryAsync(params);
          if (file?.uri) {
            resolve(compress(file));
          } else {
            reject();
          }
        } else {
          reject();
        }
        break;
      case 1:
        permissions = await ImagePicker.getCameraPermissionsAsync();
        if (permissions) {
          file = await ImagePicker.launchCameraAsync(params);
          if (file?.uri) {
            resolve(compress(file));
          } else {
            reject();
          }
        } else {
          reject();
        }
        break;
      case cancelButtonIndex:
        return reject();
      }});
  });
};
