import React, { useContext } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { translate } from '../../lang';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { ListComponent } from './list';
import { DetailComponent } from './detail';
import { CreateGuestComponent } from './create';
import { ServicesComponent } from '../services';
import { SendNotificationComponent } from './send-notification';

const Stack = createNativeStackNavigator();

export const GuestsComponent = () => {
  const context = useContext(Context);
  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Theme.palette.primary,
          textColor: Theme.palette.primaryContrast
        },
        headerTitleStyle: {
          color: Theme.palette.primaryContrast
        },
        headerTintColor: Theme.palette.primaryContrast
      }}
    >
      <Stack.Screen
        name="GuestList"
        component={ListComponent}
        options={{ title: translate('GUESTS') }}
      />
      <Stack.Screen
        name="CreateGuest"
        component={CreateGuestComponent}
        options={{
          title: translate('NEW_GUEST'),
          headerShadowVisible: false
        }}
      />
      <Stack.Screen
        name="GuestDetail"
        component={DetailComponent}
        options={{
          title: translate('GUEST_DETAIL'),
          headerShadowVisible: false
        }}
      />
      <Stack.Screen
        name="SendNotification"
        component={SendNotificationComponent}
        options={{
          title: translate('SEND_NOTIFICATION'),
          headerShadowVisible: false
        }}
      />
      { context.hotelConfig.data.features.guest.reduce((agg, feature) => {
        if (feature.id === 'services' || feature.componentId === 'services') agg.push(
          <Stack.Screen
            key={feature.id}
            name={`GuestDetail${feature.name}`}
            options={{
              headerShown: false,
              title: translate(feature.id.toUpperCase())
            }}
          >

            { ({ navigation, route }) => <ServicesComponent navigation={navigation} route={route} type={feature.type} /> }
          </Stack.Screen>
        );
        return agg;
      }, [])}
    </Stack.Navigator>
  );
};
