import React, {
  useContext,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  View,
  StyleSheet,
  Text,
} from 'react-native';
import {
  BottomSheetBackdrop,
  BottomSheetFooter,
  BottomSheetTextInput,
  BottomSheetModal,
} from '@gorhom/bottom-sheet';
import { BottomSheetScrollView } from '../../common/libs/bottom-sheet';
import uuid from 'react-native-uuid';
import Toast from 'react-native-toast-message';
import useKeyboard from '@rnhooks/keyboard';
import StarRating from 'react-native-star-rating-widget';

import { Context } from '../../Context';
import { Theme } from '../../../theme';
import { translate } from '../../../lang';
import * as API from '../../common/api.service';
import { HeaderComponent } from '../../common/header.component';
import { ButtonComponent } from '../../common/button.component';

const styles = StyleSheet.create({
  input: {
    ...Theme.styles.padding8,
    ...Theme.styles.text,
    ...Theme.styles.textDark,
    borderBottomColor: Theme.palette.textDark,
    borderBottomWidth: 1,
    paddingTop: 0
  },
  inputPlaceholder: {
    color: Theme.palette.text
  },
  textInput: {
    marginTop: 8,
    marginBottom: 10,
    borderRadius: 2,
    fontSize: 16,
    lineHeight: 20,
    padding: 8,
    borderWidth: 1,
    borderColor: Theme.palette.textDark,
    color: Theme.palette.textDark
  },
  translateButton: {
    marginLeft: 16,
    marginRight: 8
  },
  recommendedMax: {
    marginTop: -4
  }
});

export const ReviewComponent = ({ booking, onChange }) => {
  const context = useContext(Context);
  const [ visible ] = useKeyboard();
  const bottomSheetModalRef = useRef(null);
  const [review, setReview] = useState(booking.review || {
    fullName: context.user.fullName,
    accountId: context.user.id,
    plan: booking.plan,
    date: booking.startDate,
    rating: 0
  });
  const [loading, setLoading] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const snapPoints = useMemo(() => ['50%'], []);

  const edit = () => {
    bottomSheetModalRef.current?.present();
  };
  const save = async (remove = false) => {
    Toast.hide();
    let operation = '_create';
    const { bookingContext, ...bookingItem } = booking;
    let update = {
      ...bookingItem,
      review
    };
    if (remove) {
      setLoading('remove');
      delete update.review;
      operation = '_delete';
    } else {
      if (!review.title) {
        return setSubmitted(true);
      }
      setLoading('save');
      if (booking.review) {
        // Editting
        operation = '_update';
        update.review.edited = new Date().toISOString();
      } else {
        update.review.id = Date.now() + '#' + uuid.v4();
        update.review.hotelService = booking.id.split('#').slice(0, 3).join('#'); // hotelId will be added by the backend
      }
    }
    await Promise.all([
      API.crud({
        operation: operation,
        table: 'reviews',
        query: update.review || {
          Key: {
            hotelService: review.hotelService,
            id: review.id
          }
        }
      }),
      API.crud({
        operation: '_update',
        table: 'bookings',
        query: update
      })
    ]);
    onChange({
      bookingContext,
      ...update
    });
    setLoading('');
    bottomSheetModalRef.current?.dismiss();
  };

  // renders
  const renderBackdrop = useCallback(props => (
    <BottomSheetBackdrop
      {...props}
      disappearsOnIndex={-1}
      appearsOnIndex={0}
    />
  ), []);


  const renderFooter = useCallback(props => {
    return (
      <BottomSheetFooter {...props} >
        <View style={Theme.styles.row}>
          { booking.review ? (
            <View style={Theme.styles.flex}>
              <ButtonComponent safeArea={true} onPress={() => Toast.show({
                type: 'action',
                autoHide: false,
                text1: translate('CONFIRM_ACTION'),
                text2: translate('REMOVE_REVIEW_DESCRIPTION'),
                props: {
                  actions: [{
                    text: translate('NO'),
                    onPress: Toast.hide
                  }, {
                    text: translate('YES'),
                    class: 'error',
                    onPress: () => save(true)
                  }]
                }
              })} text={translate('REMOVE')} type='error' loading={loading === 'remove'} disabled={loading} />
            </View>
          ) : null
          }
          <View style={Theme.styles.flex}>
            <ButtonComponent safeArea={!visible} text={translate('SAVE')} onPress={() => save()} loading={loading === 'save'} disabled={loading} />
          </View>
        </View>
      </BottomSheetFooter>
    );
  }, [visible, review, loading]);

  return (
    <View style={Theme.styles.flex}>
      <ButtonComponent safeArea={true} text={booking.review ? translate('EDIT_REIVEW') : translate('REVIEW')} onPress={edit} />
      <BottomSheetModal
        ref={bottomSheetModalRef}
        index={0}
        snapPoints={snapPoints}
        enablePanDownToClose={true}
        backdropComponent={renderBackdrop}
        footerComponent={renderFooter}
      >
        <BottomSheetScrollView>
          <View style={Theme.styles.paddingLeft8}>
            <HeaderComponent title={booking.review ? translate('EDIT_REVIEW') : translate('NEW_REVIEW') } />
          </View>
          <View style={[Theme.styles.padding16, Theme.styles.paddingBottom60]}>
            <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.paddingBottom5]}>
              <StarRating
                color={Theme.palette.accent}
                rating={review.rating}
                onChange={rating => setReview({...review, rating})}
              />
            </View>
            <View style={Theme.styles.paddingTop8}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, submitted && !review.title && Theme.styles.error]}>{translate('TITLE')}*</Text>
              <View style={[Theme.styles.row, Theme.styles.alignCenterStart]}>
                <BottomSheetTextInput style={[Theme.styles.flex, styles.textInput, submitted && !review.title && Theme.styles.error]} onChangeText={text => setReview({...review, title: text})} value={review.title} numberOfLines={1}/>
              </View>
            </View>
            <View style={Theme.styles.paddingTop8}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1]}>{translate('COMMENT')}</Text>
              <View style={[Theme.styles.row, Theme.styles.alignCenterStart]}>
                <BottomSheetTextInput style={[Theme.styles.flex, styles.textInput]} onChangeText={text => setReview({...review, comment: text})} value={review.comment} numberOfLines={10} multiline={true} />
              </View>
            </View>
          </View>
        </BottomSheetScrollView>
      </BottomSheetModal>
    </View>
  );
};

