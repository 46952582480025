import React, { useContext } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { translate } from '../../lang';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { ListComponent } from './list';
import { ResourcesListComponent } from './resources/list';
import { ResourceDetailComponent } from './resources/detail';
import { ContentListComponent } from './content/list';
import { ContentGroupsComponent } from './content/groups';
import { ContentGroupDetailComponent } from './content/group-detail';
import { ContentDetailComponent } from './content/detail/index';
import { ContentPlanComponent } from './content/plan';
import { ContentMenuItemComponent } from './content/menu-item';

const Stack = createNativeStackNavigator();

export const ConfigComponent = ({ nested }) => {
  const context = useContext(Context);
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: !nested,
        headerStyle: {
          backgroundColor: Theme.palette.primary,
          textColor: Theme.palette.primaryContrast
        },
        headerTitleStyle: {
          color: Theme.palette.primaryContrast
        },
        headerTintColor: Theme.palette.primaryContrast
      }}
    >
      <Stack.Screen
        name="ConfigList"
        component={ListComponent}
        options={{ title: translate('CONFIG') }}
      />
      <Stack.Screen
        name="ContentList"
        component={ContentListComponent}
        options={{ title: translate('CONTENT') }}
      />
      <Stack.Screen
        name="ContentGroups"
        component={ContentGroupsComponent}
        options={{ title: context.navigation.title }}
      />
      <Stack.Screen
        name="ContentGroupDetail"
        component={ContentGroupDetailComponent}
        options={{ title: context.navigation.title || translate('NEW_GROUP') }}
      />
      <Stack.Screen
        name="ContentDetail"
        component={ContentDetailComponent}
        options={{
          title: context.navigation.title || translate('NEW_SERVICE'),
          headerShadowVisible: false
        }}
      />
      <Stack.Screen
        name="ContentPlan"
        component={ContentPlanComponent}
        options={{ title: context.navigation.title || translate('NEW_PLAN') }}
      />
      <Stack.Screen
        name="ContentMenuItem"
        component={ContentMenuItemComponent}
        options={{ title: context.navigation.title || translate('NEW_MENU_ITEM') }}
      />
      <Stack.Screen
        name="ContentResourceDetail"
        component={ResourceDetailComponent}
        options={{ title: context.navigation.title || translate('NEW_RESOURCE') }}
      />
      <Stack.Screen
        name="ConfigResourcesList"
        component={ResourcesListComponent}
        options={{ title: translate('RESOURCES') }}
      />
      <Stack.Screen
        name="ConfigResourceDetail"
        component={ResourceDetailComponent}
        options={{ title: context.navigation.title || translate('NEW_RESOURCE') }}
      />
    </Stack.Navigator>
  );
};
