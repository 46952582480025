import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Linking,
  Platform,
} from 'react-native';
import Image from '../common/libs/image/image';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { Theme } from '../../theme';
import { translate } from '../../lang';

const styles = StyleSheet.create({
  container: {
    position: 'absolute'
  },
  text: {
    color: Theme.palette.primaryContrast,
    paddingVertical: 16,
    maxWidth: '90%'
  },
  button: {
    marginTop: 32,
    minWidth: 250,
    backgroundColor: Theme.palette.darkContrast,
    padding: 15,
    borderRadius: 24
  }
});

export const UpdateComponent = () => {

  const openStore = async () => {
    if (Platform.OS === 'ios') {
      const link = `itms-apps://apps.apple.com/app/${process.env.APP_STORE_ID}`;
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
    } else if (Platform.OS === 'android') {
      Linking.openURL(`http://play.google.com/store/apps/details?id=${process.env.BUNDLE_ID}`);
    } else {
      //navigation.navigate('Main')
    }
  };

  return (
    <View style={[Theme.styles.flex, Theme.styles.alignCenterCenter]}>
      <Image
        style={[Theme.styles.flex, Theme.styles.width]}
        resizeMode='cover'
        source={require('../../theme/assets/Update.png')}
      />
      <View style={[styles.container, Theme.styles.column, {bottom: 40}]}>
        <Image
          source={Theme.logo}
          style={{}}
          width={100}
          height={100}
          resizeMode='contain'
        />
      </View>
      <View style={[styles.container, Theme.styles.column, Theme.styles.alignCenterCenter]}>
        <Text style={[Theme.styles.text, Theme.styles.fontSize20, styles.text]}>{ translate('UPDATE_NEEDED') }</Text>
        <MaterialIcons name='arrow-circle-up' color={Theme.palette.primaryContrast} size={100} />
        <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.letterSpacing2, styles.text]}>{ translate('UPDATE_NEEDED_DESCRIPTION') }</Text>
        <TouchableOpacity onPress={openStore} style={[Theme.styles.alignCenterCenter, styles.button]}>
          <Text style={[Theme.styles.text, Theme.styles.buttonText]}>{translate('UPDATE_NOW')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
