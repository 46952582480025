import React, { useState } from 'react';
import {
  Text,
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  TextInput,
} from 'react-native';
import { Calendar } from 'react-native-calendars';

import { Theme } from '../../theme';
import { AnimateService } from '../common/animate.service';
import { getCurrentLocale, translate } from '../../lang';

const styles = StyleSheet.create({
  calendarContainer: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    padding: 16,
    zIndex: 100,
  },
  calendar: {
    alignSelf: 'center',
    justifySelf: 'center',
    borderRadius: 6,
    width: 300,
    maxWidth: '90%',
  },
  unsetableLabelContainer: {
    padding: 8,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    backgroundColor: Theme.palette.accent,
    position: 'relative',
    top: -4
  },
  unsetableLabel: {
    color: Theme.palette.accentContrast
  }
});

export const DatePickerComponent = ({
  children,
  disabledByDefault,
  error,
  markedDates,
  minDate,
  onChange,
  setShow,
  show,
  style,
  type,
  unsetable,
  value,
}) => {
  const locale = getCurrentLocale();
  let showCalendar, setShowCalendar;
  if (show && setShow) {
    showCalendar = show;
    setShowCalendar = setShow;
  } else {
    const [internalShowCalendar, internalSetShowCalendar] = useState(false);
    showCalendar = internalShowCalendar;
    setShowCalendar = internalSetShowCalendar;
  }
  const pressedDate = (date) => {
    if (unsetable && date.dateString === value) {
      onChange(null);
    } else {
      onChange(date.dateString);
    }
    setShowCalendar(null);
  };

  return (
    <View style={Theme.styles.flex}>
      <Modal
        animationType='none'
        transparent={true}
        visible={showCalendar}
        onRequestClose={() => setShowCalendar(false)}
      >
        <TouchableOpacity style={[styles.calendarContainer, Theme.styles.column, Theme.styles.alignStretchCenter]} onPress={() => setShowCalendar(null)}>
          <AnimateService
            key='calendar'
            properties={['top']}
            duration={300}
            Component={() => (
              <View>
                <Calendar
                  key={locale}
                  style={styles.calendar}
                  current={minDate}
                  minDate={minDate || null}
                  onDayPress={pressedDate}
                  enableSwipeMonths={true}
                  disabledDaysIndexes={[]}
                  disabledByDefault={!!disabledByDefault}
                  markedDates={{
                    ...(markedDates || {}),
                    [value]: { selected: true, selectedColor: Theme.palette.accent }
                  }}
                  theme={{
                    arrowColor: Theme.palette.primary,
                    todayTextColor: Theme.palette.primary
                  }}
                />
                { unsetable && value ? (
                  <View style={[styles.calendar, styles.unsetableLabelContainer]}>
                    <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textCenter, styles.unsetableLabel]}>{ translate('PRESS_SAME_DATE_TO_UNSET') }</Text>
                  </View>
                ) : null }
              </View>
            )}
          />
        </TouchableOpacity>
      </Modal>
      { type !== 'only-modal' ? (
        <TouchableOpacity onPress={() => setShowCalendar(true)}>
          <View pointerEvents='none'>
            { children ? children : (
              <TextInput
                editable={false}
                style={[Theme.styles.input, Theme.styles.marginBottom0, style?.input, error && Theme.styles.error]}
                value={value}
              />
            )}
          </View>
        </TouchableOpacity>
      ) : null }
    </View>
  );
};
