import React, { useContext } from 'react';
import {
  Platform,
  FlatList,
  Text,
  View,
} from 'react-native';
import ActionButton from 'react-native-action-button';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Theme } from '../../../theme';
import { getCurrentLocale } from '../../../lang';
import { Tile } from '../../common/tile';
import { Context } from '../../Context';
import { translate } from '../../../lang';
import { AnimateService } from '../../common/animate.service';
import { getColumnStyle } from '../../common/utils.service';
import { HeaderComponent } from '../../common/header.component';

export const ContentGroupsComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const locale = getCurrentLocale();
  const { groups } = (route.params || {});

  const openGroup = (group) => {
    context.navigation.title = group && group.title && group.title[locale] || null;
    navigation.navigate('ContentGroupDetail', {
      ...route.params,
      groups,
      group
    });
  };

  return (
    <>
      <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded] : null]}>
        { Theme.XL ? (
          <View style={Theme.styles.paddingLeft16}>
            <HeaderComponent title={context.navigation.title} />
          </View>
        ) : null}
        <FlatList
          style={[Theme.styles.height, Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop16] : null]}
          contentContainerStyle={(!groups || !groups.length) && Theme.styles.flex}
          numColumns={Theme.numberOfColumns}
          data={groups}
          keyExtractor={item => item.id}
          ListEmptyComponent={() => (
            <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.padding40]}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.textCenter]}>{ translate('NOTHING_HERE_YET') }</Text>
              <MaterialCommunityIcons name='kitesurfing' color={Theme.styles.text.color} size={50} style={[Theme.styles.marginTop20, Theme.styles.marginBottom20]} />
              <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textCenter]}>{ translate('ADD_TO_GET_STARTED') }</Text>
            </View>
          )}
          renderItem={({ item, index }) => (
            <AnimateService
              key={index}
              properties={['opacity', 'left', 'top']}
              style={getColumnStyle(index, groups.length, Theme.numberOfColumns, Theme.XL ? 32 : null)}
              delay={index * 50}
              duration={500}
              Component={() => (
                <Tile
                  media={item.media || (item.services || []).reduce((r, a) =>  r.concat(a.media), [])}
                  title={item.title?.[locale]}
                  subtitle={item.subtitle && item.subtitle[locale]}
                  onPress={() => openGroup(item)}
                />
              )}
            />
          )}
        />
      </View>
      <ActionButton buttonColor={Theme.palette.accent} onPress={openGroup} hideShadow={Platform.OS === 'web'} />
    </>
  );
};
