/* Generating the base StyleSheet */
const base = {
  flex: {
    display: 'flex',
    flex: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    placeContent: 'center center'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    placeContent: 'center center'
  }
};

/* Layout Flex */
for (let i = 1; i <= 100; i++) {
  base[`flex${i}`] = {
    minWidth: `${i}%`,
    width: `${i}%`,
    maxWidth: `${i}%`
  };
}

/* Layout Alignments */
const hAlign = {
  'Start': 'flex-start',
  'Center': 'center',
  'End': 'flex-end',
  'SpaceAround': 'space-around',
  'SpaceBetween': 'space-between',
  'SpaceEvenly': 'space-evenly'
};
const vAlign = {
  'Start': 'flex-start',
  'Center': 'center',
  'End': 'flex-end',
  'Stretch': 'stretch',
};

for (const vKey in vAlign) {
  for (const hKey in hAlign) {
    base[`align${vKey}${hKey}`] = {
      placeContent: `${vAlign[vKey]} ${hAlign[hKey]}`,
      justifyContent: hAlign[hKey],
      alignItems: vAlign[vKey],
      alignContent: vAlign[vKey]
    };
  }
}

/* Responsive with BrakePoints */
const brakePoints = {
  'XXS': [0,299],
  'XS': [300,599],
  'SM': [600, 959],
  'MD': [960, 1279],
  'LG': [1280, 1919],
  'XL': [1919,]
};
const responsive = {};

for (const point in brakePoints) {
  let [min, max] = brakePoints[point];
  if (min) {
    min = `(min-width: ${min}px)`;
  }
  if (max) {
    max = `(max-width: ${max}px)`;
  }
  let query = '@media ';
  if (min && max) {
    query += `${min} and ${max}`;
  } else {
    query += min || max;
  }
  for (const key in base) {
    responsive[`${key}${point}`] = {
      [query]: base[key]
    };
  }
}

const layout = {
  ...base,
  ...responsive
};

export default layout;