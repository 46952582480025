import React, { useState } from 'react';
import {
  Modal,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Theme } from '../../theme';
import { translate } from '../../lang';

const styles = StyleSheet.create({
  centeredView: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  scrollView: {
  },
  modalView: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: '80%',
    maxHeight: '90%',
    backgroundColor: 'white',
    borderRadius: 2,
    paddingVertical: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonClose: {
    borderRadius: 20,
    position: 'absolute',
    top: 16,
    right: 16,
  },
});

export const PolicyComponent = ({ service, plan, locale }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showModal}
        onRequestClose={() => setShowModal(false)}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <ScrollView style={styles.scrollView} contentContainerStyle={Theme.styles.alignStartStart}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.marginBottom10, Theme.styles.paddingHorizontal35]}>{ translate('SERVICE_POLICY') }</Text>
              <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.paddingHorizontal35]}>{ plan.policy?.[locale] || service.policy?.[locale] || translate('SERVICE_CONFIRMATION_POLICY').replace(/<br>/g, '\n') }</Text>
            </ScrollView>
            <TouchableOpacity
              style={styles.buttonClose}
              onPress={() => setShowModal(false)}>
              <MaterialCommunityIcons name='close' color={Theme.palette.textDark} size={25} />
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <TouchableOpacity onPress={() => setShowModal(true)} style={[Theme.styles.row, Theme.styles.width, Theme.styles.paddingHorizontal6]}>
        <MaterialCommunityIcons name='information' color={Theme.palette.textDark} size={25} />
        <Text style={[Theme.styles.container, Theme.styles.textJustify, Theme.styles.flex]}>
          { translate('CLICK_TO_SEE_SERVICE_POLICY') }
        </Text>
      </TouchableOpacity>
    </>
  );
};
