import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { translate } from '../../lang';
import { Theme } from '../../theme';

const styles = StyleSheet.create({
  option: {
    borderColor: Theme.palette.backgroundDark,
    borderRadius: 2,
    borderWidth: 1,
    marginBottom: 8,
    height: 45,
    paddingLeft: 16,
    paddingRight: 16
  },
  selected: {
    borderColor: Theme.palette.accent,
    color: Theme.palette.accent
  },
  text: {
    paddingLeft: 8,
    paddingRight: 8,
    letterSpacing: 1,
    fontWeight: 'bold'
  }
});

const Icon = ({ Component, name, color }) => {
  return (
    <Component name={name} color={color || Theme.palette.textDark} size={16} />
  );
};

export const SelectorComponent = ({ selected, onPress, titleIcon, titleIconComponent, title, subtitle, value, valueIcon, valueIconComponent, style }) => {
  return (
    <TouchableOpacity onPress={onPress} >
      <View style={[Theme.styles.width, Theme.styles.row, Theme.styles.alignCenterCenter, styles.option, selected ? styles.selected : null]}>
        { titleIcon ? (
          <Icon name={titleIcon} Component={titleIconComponent || MaterialCommunityIcons} color={style?.titleIcon?.color} />
        ) : null
        }
        <View style={[Theme.styles.flex, Theme.styles.alignStartCenter]}>
          <Text style={[Theme.styles.text, Theme.styles.textDark, styles.text]} numberOfLines={1}>
            { title }
          </Text>
          { subtitle ? (
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize10, styles.text]} numberOfLines={1}>
              { subtitle }
            </Text>
          ) : null
          }
        </View>
        <Text style={[Theme.styles.text, Theme.styles.textDark, styles.text, selected ? styles.selected : null]}>
          { selected ? translate('SELECTED') : value }
        </Text>
        { !selected && valueIcon ? (
          <Icon name={valueIcon} Component={valueIconComponent || MaterialCommunityIcons} />
        ) : null
        }
      </View>
    </TouchableOpacity>
  );
};
