import React from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ContextMenu from './libs/context-menu-view/context-menu-view';

import { Theme } from '../../theme';
import { translate } from '../../lang';

const styles = StyleSheet.create({
  container: {
    ...Theme.styles.row,
    padding: 3,
    borderRadius: 3,
  },
  text: {
    ...Theme.styles.text,
    ...Theme.styles.letterSpacing1,
    ...Theme.styles.fontSize12,
    ...Theme.styles.paddingLeft5,
  },
  CONFIRMED: {
    backgroundColor: Theme.palette.success
  },
  CONFIRMEDText: {
    color: Theme.palette.successContrast
  },
  PENDING: {
    backgroundColor: Theme.palette.warning
  },
  PENDINGText: {
    color: Theme.palette.warningContrast
  },
  CANCELLED: {
    backgroundColor: Theme.palette.error
  },
  CANCELLEDText: {
    color: Theme.palette.errorContrast
  },
  EXPIRED: {
    backgroundColor: Theme.palette.error
  },
  EXPIREDText: {
    color: Theme.palette.errorContrast
  },
  NOT_JOINED: {
    backgroundColor: Theme.palette.error
  },
  NOT_JOINEDText: {
    color: Theme.palette.errorContrast
  },
  JOIN_NOW: {
    backgroundColor: Theme.palette.primary
  },
  JOIN_NOWText: {
    color: Theme.palette.primaryContrast
  }
});

export const STATUS_ICONS = {
  CONFIRMED: 'check',
  PENDING: 'clock-alert-outline',
  CANCELLED: 'alert-octagram',
  EXPIRED: 'alert',
  NOT_JOINED: 'close-circle',
  JOIN_NOW: 'account-check'
};

export const ACCOUNT_STATUS = ['PENDING', 'CONFIRMED'];

export const StatusLabelComponent = ({ status, hideText, text, icon, editable, onChange }) => {
  const Label = () => (
    <View style={[styles.container, styles[status]]} >
      <MaterialCommunityIcons name={icon || STATUS_ICONS[status]} color={styles[status + 'Text']?.color} />
      { !hideText ? (
        <Text style={[styles.text, styles[status + 'Text']]}>{ text || translate(status) }</Text>
      ) : null }
    </View>
  );
  return editable ? (
    <ContextMenu
      dropdownMenuMode={true}
      actions={editable.map(a => ({title: translate(a), id: a}))}
      onPress={(e) => onChange?.(editable[e.nativeEvent.index])}
    >
      <Label />
    </ContextMenu>
  ) : Label();
};
