import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useFocusEffect } from '@react-navigation/native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ActionButton from 'react-native-action-button';

import * as API from '../common/api.service';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate } from '../../lang';
import { ListItemComponent } from '../common/list-item.component';
import { StatusLabelComponent } from '../common/status-label.component';
import { BookingsListComponent } from '../bookings/list';
import { AccountInfoComponent } from '../more/account/info';
import { getBookingContext } from '../common/utils.service';

const Tab = createMaterialTopTabNavigator();

const styles = StyleSheet.create({
  actionButtonIcon: {
    fontSize: 20,
    height: 22,
    color: 'white',
  },
  tabNavigation: {
    marginTop: -20
  },
  tabNavigationComponents: {
    tabBarIndicatorStyle: { backgroundColor: Theme.palette.accent },
    tabBarLabelStyle: { fontSize: 12, color: Theme.palette.primaryContrast },
    tabBarStyle: { backgroundColor: Theme.palette.primary }
  },
  primaryBackground: {
    backgroundColor: Theme.palette.primary
  }
});

export const DetailComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const [bookings, setBookings] = useState([]);
  const { guest } = route.params;
  const [ today ] = new Date().toISOString().split('T');

  const getBookings = async () => {
    var bookingsQuery = {
      operation: '_read',
      table: 'bookings',
      index: 'byAccountId',
      query: {
        accountId: {
          ComparisonOperator: 'EQ',
          AttributeValueList: [guest.id]
        }
      },
      sort: 'ASC',
      sortKey: 'startDate'
    };
    let reservations = (await API.crud(bookingsQuery).catch(() => {})) || [];

    let todaySectionIndex = 0;
    setBookings(reservations?.reduce((agg, current) => {
      const { group, service } = getBookingContext(current, context);
      if (!group || !service) return agg;

      if (agg?.[agg.length - 1]?.date === current.startDate) {
        agg[agg.length - 1].data.push(current);
      } else {
        agg.push({
          date: current.startDate,
          data: [current]
        });
      }
      if (current.startDate < today) todaySectionIndex++;
      return agg;
    }, []));
    return todaySectionIndex;
  };

  const onBookingUpdated = (item) => {
    setBookings(bookings.map(booking => ({
      date: booking.date,
      data: booking.data.map(reservation =>
        reservation.id === item.id
          ? item
          : reservation
      )
    })));
  };

  const newService = (type) => {
    navigation.navigate(type, {
      guest,
      navigateTo: 'GuestDetail',
      navigateToParams: route.params
    });
  };

  useFocusEffect(useCallback(() => { getBookings(); }, [route.params]));

  const actionButtons = [];

  context.hotelConfig.data.features[guest.admin ? 'admin' : 'guest'].forEach((feature) => {
    if (guest.features.includes(feature.id) && (feature.id === 'services' || feature.componentId === 'services')) {
      actionButtons.push(
        <ActionButton.Item key={feature.id} buttonColor={Theme.palette[feature.type]} title={translate('NEW_' + feature.type.toUpperCase())} onPress={() => newService('GuestDetail' + feature.name)}>
          <MaterialCommunityIcons name={feature.icon} style={styles.actionButtonIcon} />
        </ActionButton.Item>
      );
    }
  });

  actionButtons.push(
    <ActionButton.Item buttonColor={Theme.palette.notification} title={translate('SEND_NOTIFICATION')} onPress={() => navigation.navigate('SendNotification', { receivers: [guest.id] })}>
      <MaterialCommunityIcons name="bullhorn" style={styles.actionButtonIcon} />
    </ActionButton.Item>
  );

  return (
    <View style={Theme.styles.flex}>
      <View style={styles.primaryBackground}>
        <View style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded, Theme.styles.paddingVertical0] : null}>
          <ListItemComponent
            title={guest.fullName}
            subtitle={guest.roomNumber || translate('NO_ROOM_NUMBER')}
            image={guest.image ? {
              uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID + (context.ref ? `.${context.ref}` : ''), guest.image].join('/')
            } : require('../../theme/assets/ProfilePlaceholder.png')}
            header={true}
            ActionComponent={() => (
              <StatusLabelComponent status={guest.status} />
            )}
          />
        </View>
      </View>
      <Tab.Navigator
        style={styles.tabNavigation}
        screenOptions={styles.tabNavigationComponents}
      >
        <Tab.Screen name='GuestDetailBookings' options={{ title: translate('BOOKINGS') }}>
          { () => <BookingsListComponent navigation={navigation} route={route} getBookings={getBookings} bookings={bookings} onBookingUpdated={onBookingUpdated} /> }
        </Tab.Screen>
        <Tab.Screen name='GuestDetailAccountInfo' options={{ title: translate('ACCOUNT_INFO') }}>
          {() => (
            <View style={[Theme.styles.flex, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper, Theme.styles.padding32, Theme.styles.paddingBottom16] : null]}>
              <AccountInfoComponent navigation={navigation} route={route} onSave={navigation.goBack} onDelete={navigation.goBack} keyboardPadding={115} />
            </View>
          )}
        </Tab.Screen>
      </Tab.Navigator>
      <ActionButton buttonColor={Theme.palette.accent} verticalOrientation="up" hideShadow={Platform.OS === 'web'}>
        { actionButtons }
      </ActionButton>
    </View>
  );
};
