import React from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Theme } from '../../theme';
import { ButtonComponent } from './button.component';

const styles = StyleSheet.create({
  container: Theme.XL ? {
    height: 70,
    padding: 16
  } : {
    height: 40,
    paddingRight: 8,
    paddingLeft: 8
  },
  completed: {
    borderBottomWidth: 1,
    borderColor: Theme.palette.backgroundDark,
    backgroundColor: Theme.palette.background
  },
  subtitleMultiline: {
    fontSize: 15,
    textAlign: 'right'
  }
});

export const HeaderComponent = ({
  icon,
  title,
  subtitle,
  completed,
  subtitleMultiline,
  subtitleComponent,
  actionText,
  onPress,
  style,
  ...buttonProps
}) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween, styles.container, completed ? styles.completed : null, style?.container]}>
      { icon ? (
        <MaterialCommunityIcons name={icon} size={25} color={Theme.styles.textDark} style={Theme.styles.marginRight10} />
      ) : null }
      <Text style={[Theme.styles.flex, Theme.styles.paddingRight15, Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing2, Theme.styles.fontSize18, completed ? Theme.styles.letterSpacing0 : null, Theme.XL ? [Theme.styles.textPrimary, Theme.styles.fontSize30] : null, style?.title]} numberOfLines={1}>
        { title }
      </Text>
      { subtitleComponent ? subtitleComponent : subtitle ? (
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize18, Theme.styles.letterSpacing1, Theme.styles.textBold, Theme.XL ? [Theme.styles.textPrimary, Theme.styles.fontSize25] : null, subtitleMultiline ? styles.subtitleMultiline : null, style?.subtitle]}>
          { subtitle }
        </Text>
      ) : actionText ? (
        <ButtonComponent onPress={onPress} text={actionText} inline {...buttonProps} />
      ) : null
      }
    </View>
  );
};
