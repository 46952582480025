import React, { useContext } from 'react';
import {
  FlatList,
  Platform,
  View,
} from 'react-native';

import { Context } from '../../Context';
import { Theme } from '../../../theme';
import { translate } from '../../../lang';
import { Tile } from '../../common/tile';
import { AnimateService } from '../../common/animate.service';
import { HeaderComponent } from '../../common/header.component';
import { getColumnStyle } from '../../common/utils.service';

const sectionMedia = {
  activities: require('../../../theme/assets/GenericActivityTile.png'),
  services: require('../../../theme/assets/GenericServiceTile.png'),
  spa: require('../../../theme/assets/GenericSpaTile.png')
};

export const ContentListComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const sections = [/*{
    id: 'moreGroups',
    title: translate('GENERAL_INFO'),
    media: [{
      type: 'image',
      source: require('../../../theme/assets/GenericInfoTile.png')
    }]
  }*/];
  context.hotelConfig.data.features.guest.forEach((feature) => {
    if (feature.id === 'services' || feature.componentId === 'services') {
      sections.push({
        id: `${feature.type}Groups`,
        title: translate(feature.id.toUpperCase()),
        media: [{
          type: 'image',
          source: sectionMedia[feature.id] || sectionMedia.services
        }]
      });
    }
  });
  const openSection = (section) => {
    context.navigation.title = section.title;
    navigation.navigate('ContentGroups', {
      ...route.params,
      key: section.id,
      groups: context[section.id]?.data
    });
  };
  return (
    <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded] : null]}>
      { Theme.XL ? (
        <View style={Theme.styles.paddingLeft16}>
          <HeaderComponent title={translate('CONTENT')} />
        </View>
      ) : null}
      <FlatList
        data={sections}
        style={[Theme.styles.height, Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop16] : null]}
        numColumns={Theme.numberOfColumns}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => (
          <AnimateService
            properties={['opacity', 'left', 'top']}
            style={getColumnStyle(index, sections.length, Theme.numberOfColumns, Theme.XL ? 32 : null)}
            delay={index * 50}
            duration={500}
            Component={() => (
              <Tile
                style={{ media: { height: (Theme.height / 3) - (Platform.OS !== 'web' ? 55 : 166 / 3)} }}
                media={item.media}
                title={item.title}
                onPress={() => openSection(item)}
              />
            )}
          />
        )}
      />
    </View>
  );
};

