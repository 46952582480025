import React, { useContext } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity
} from 'react-native';

import { Context } from '../Context';
import Image from './libs/image/image';
import { translate, getCurrentLocale } from '../../lang';
import { Theme } from '../../theme';
import { NumberInputComponent } from '../common/number-input.component';
import { getPriceString } from '../common/utils.service';
import * as Allergens from '../common/allergens';

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.backgroundDark
  },
  image: {
    width: 20,
    height: 20,
    marginRight: 8
  },
  sectionLabel: {
    borderRightWidth: 1,
    borderRightColor: Theme.styles.text,
    paddingRight: 8,
    marginRight: 8
  },
  numberInputComponent: {
    container: {
      paddingRight: 0
    },
    button: {
      height: 20,
      width: 20
    },
    text: {
      minWidth: 40,
      fontSize: 20
    },
    icon: {
      size: 15
    }
  }
});

export const MenuItemComponent = ({ item, onPress, onCountChange, disabledCount, disabled, defaultValue, sections }) => {
  const locale = getCurrentLocale();
  const context = useContext(Context);

  const allergens = Object.entries(item.allergens || {});

  return (
    <TouchableOpacity style={[Theme.styles.column, Theme.styles.width, Theme.styles.padding8, styles.container]} onPress={onPress} disabled={disabled}>
      <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.width]}>
        { item.media?.length && item.media[0].src !== 'restaurant_0.jpg' ? (
          <Image
            style={styles.image}
            source={item.media[0].source || {
              uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID.replace('.admin', '') + (context.ref ? `.${context.ref}` : ''), item.media[0].src].join('/')
            }}
          />
        ) : null}
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.flex, Theme.styles.fontSize17]}>{item.title?.[locale] || item.title.en || item.title}</Text>
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold, Theme.styles.fontSize17]}>{getPriceString(item.price)}</Text>
      </View>
      <View style={[Theme.styles.row, Theme.styles.width]}>
        <View style={[Theme.styles.row, Theme.styles.flex, Theme.styles.alignCenterStart]}>
          { sections?.length ? (
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textRight, item.type === 'food' && styles.sectionLabel]}>
              { sections.find(({ id }) => item.section === id)?.[locale] || translate('NO_SECTION') }
            </Text>
          ) : null }
          { allergens.length ? allergens.map(([allergen, value]) => value ? (
            <View key={allergen}>
              { Allergens[allergen]({hideText: true}) }
            </View>
          ) : null) : item.type === 'food' ? (
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textLeft]}>
              { translate('NO_ALLERGEN_INFO') }
            </Text>
          ) : null}
        </View>
        <TouchableOpacity onPress={() => {}} activeOpacity={1}>
          { !disabledCount ? (
            <NumberInputComponent onChange={onCountChange} defaultValue={defaultValue || 0} style={styles.numberInputComponent} />
          ) : null}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};
