import React, { useLayoutEffect } from 'react';
import { View } from 'react-native';
import WebView from '../libs/webview/webview';

import { getCurrentLocale } from '../../../lang';
import { Theme } from '../../../theme';

export const WebViewComponent = ({ navigation, route }) => {
  const { title, url, html } = route.params;

  const locale = getCurrentLocale();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: title[locale] || title
    });
  }, [navigation, route]);

  return (
    <View style={[Theme.styles.height, Theme.styles.column]}>
      <WebView
        source={url ? { uri: url } : { html: html }}
        style={[Theme.styles.flex, { width: Theme.width }]}
      />
    </View>
  );
};
