import moment from 'moment/min/moment-with-locales';
import { I18n } from 'i18n-js';
import * as Localization from 'expo-localization';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LocaleConfig } from 'react-native-calendars';

import { default as dict } from './.dictionary.json';
import { default as calendarDict } from './.calendar.dictionary.json';

const i18n = new I18n(dict);
i18n.missingBehaviour = 'guess';
i18n.defaultLocale = process.env.DEFAULT_LOCALE || 'en';
i18n.enableFallback = true;

(async function loadLocale() {
  const lang = await AsyncStorage.getItem('lang');
  i18n.locale = (lang || (process.env.LANGS.includes(Localization.locale.split('-')[0]) && Localization.locale) || i18n.defaultLocale).split('-')[0];
  setLocale(i18n.locale);
})();

export const setLocale = async (locale) => {
  LocaleConfig.locales = calendarDict;
  LocaleConfig.defaultLocale = locale;
  moment.locale(locale);
  i18n.locale = locale;
  AsyncStorage.setItem('lang', locale);
};

export const getCurrentLocale = () => i18n.locale;

export const translate = i18n.translate.bind(i18n);

export const LANGUAGE_LABELS = {
  'en': 'English',
  'es': 'Español',
  'ru': 'Русский',
  'de': 'Deutsch',
  'it': 'Italiano',
  'fr': 'Français',
  'hi': 'हिन्दी',
  'zh': '中国人'
};
