import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  ScrollView,
  Text,
  View,
  StyleSheet,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { Calendar } from 'react-native-calendars';
import uuid from 'react-native-uuid';

import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import {
  conflictingDateRange,
  getFinalPrice,
  getNextRoute,
  getOrderPrice,
  getPlanPrice,
  getPriceString,
  getTimeValue,
} from '../common/utils.service';
import * as API from '../common/api.service';
import { HeaderComponent } from '../common/header.component';
import { SelectorComponent } from '../common/selector.component';
import { ButtonComponent } from '../common/button.component';
import { upsertBooking } from '../bookings/service';
import { ResourceTypes } from '../config/resources/detail';

const styles = StyleSheet.create({
  dateHeaderXL: {
    container: {
      marginLeft: -32
    }
  },
  availabilityContainerXL: {
    flex: 1,
    marginTop: -8
  }
});

export const CalendarComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const [selected, setSelected] = useState(null);
  const { guest, group, service, startDate, endDate, type, order } = route.params;
  const [date, setDate] = useState({});
  const [range, setRange] = useState(0);
  const [markedDates, setMarkedDates] = useState({});
  const [availability, setAvailability] = useState();
  const [price, setPrice] = useState(route.params.price);
  const [plan, setPlan] = useState(route.params.plan);
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const locale = getCurrentLocale();

  useEffect(() => {
    const getPrice = async () => {
      if (order) {
        return setPrice(getOrderPrice(order));
      } else {
        setLoadingPrice(true);
        const finalPrice = await getFinalPrice(group, date, getPlanPrice(plan, undefined, date));
        setLoadingPrice(false);
        return setPrice(getPriceString(parseFloat(finalPrice)));
      }
    };
    if (date.end || !plan.dateRange) {
      getPrice();
    }
  }, [date, plan]);

  useEffect(() => {
    if (!date.start && startDate && !endDate) pressedDate({ dateString: startDate });
    if (!date.end && startDate && endDate) pressedDate({ dateString: endDate }, startDate);
  }, []);

  const pressedDate = ({ dateString }, forcedStart = false) => {
    if (plan.dateRange) {
      if (!forcedStart && (!date.start || date.end || dateString < date.start)) {
        setMarkedDates({
          [dateString]: {
            selected: true,
            startingDay: true,
            color: Theme.palette.accent,
            textColor: Theme.palette.accentContrast
          }
        });
        setDate({
          start: dateString
        });
        setRange(0);
      } else {
        const markedDates = {};
        for (const d = moment(forcedStart || date.start); d.isSameOrBefore(dateString); d.add(1, 'days')) {
          markedDates[d.format('YYYY-MM-DD')] = {
            selected: true,
            color: Theme.palette.accent,
            textColor: Theme.palette.accentContrast
          };

          if (d.format('YYYY-MM-DD') === (forcedStart || date.start)) markedDates[d.format('YYYY-MM-DD')].startingDay = true;
          if (d.format('YYYY-MM-DD') === dateString) markedDates[d.format('YYYY-MM-DD')].endingDay = true;
        }

        setMarkedDates(markedDates);
        setDate({
          start: forcedStart || date.start,
          end: dateString
        });
        setRange(moment(dateString).diff(forcedStart || date.start, 'days') + (plan.dateRangeUnit === 'day' || !plan.dateRange ? 1 : 0));
      }
    } else {
      setMarkedDates({
        [dateString]: {
          selected: true,
          startingDay: true,
          endingDay: true,
          color: Theme.palette.accent,
          textColor: Theme.palette.accentContrast
        }
      });
      setDate({
        start: dateString,
        end: dateString
      });
    }
  };

  const next = async () => {
    const nextRoute = getNextRoute('ServiceCalendar', { ...service, ...plan });
    if (!nextRoute) {
      const booking = {
        oldId: route.params.id,
        id: `${type}#${group.id}#${service.id}#${plan.id}#${date.start}#${route.params.id ? route.params.id.split('#').pop() : '%' + uuid.v4() + '%'}`,
        accountId: guest?.id,
        plan: plan.id,
        startDate: date.start,
        endDate: date.end || date.start,
        status: 'PENDING',
        price: await getFinalPrice(group, date, getPlanPrice(plan, undefined, date)),
        createdAt: Date.now()
      };
      const upserted = await upsertBooking(context, group, plan, booking, setLoading);
      if (upserted) {
        navigation.navigate(route.params.navigateTo || `${type}ServiceGroups`, { ...route.params.navigateToParams });
      }
    } else {
      navigation.navigate(`${type}${nextRoute}`, {
        ...route.params,
        date,
        price,
        plan,
        slot: availability[selected] || plan.default,
      });
    }
  };

  //let disabledDays = [];
  useEffect(() => {
    const buildAvailability = async () => {
      const now = new Date();
      const currentTime = now.getHours() + Math.ceil(now.getMinutes() / 60);
      setSelected(null);
      if (plan.dateRange) {
        // If needs daterange and not end date is selected, return not available
        if (!date.end) return setAvailability(null);
        if (plan.minDateRange || plan.maxDateRange) {
          const min = plan.minDateRange || 1;
          const max = plan.maxDateRange || Infinity;
          // If needs daterange and not meeting min and max, return not available
          if (range < min || range > max) return setAvailability(false);
        }
      }

      // If it has a custom availability method, check that
      if (group?.externalCrud?._availability) {
        setAvailability('loading');
        const result = await API.crud({
          operation: '_custom',
          custom: group.externalCrud._availability,
          query: [
            service.id,
            plan.id,
            plan.max,
            date.start,
            date.end
          ]
        });
        if (result.plan) {
          setPlan({
            ...plan,
            ...result.plan
          });
        }
        return setAvailability(result.availability);
      }

      // If plan has no schedules and no resources, return available (eg: 3rd party diving activities with daterange)
      if (!plan.schedules?.length && !plan.resources?.length) return setAvailability(true);

      if (plan.schedules?.length) {
        // If plan uses schedules and none are available for that weeday, return not available
        if (!plan.schedules.some(schedule => schedule.weekdays.includes(new Date(date.start).getDay()))) return setAvailability(false);
        // If plan uses schedules and no resources, set availability = schedules (eg: restaurants or 3rd party transfers)
        if (!plan.resources?.length) return setAvailability(plan.schedules);
      }

      setAvailability('loading');
      let schedules = JSON.parse(JSON.stringify(plan.schedules || []));
      const resources = {};
      const resourceTypeCount = {};
      for (let resourceId of plan.resources) {
        let resource = resources[resourceId];
        if (!resource) {
          resource = context.hotelConfig?.data?.resources?.find(r => r.id === resourceId);
          resources[resourceId] = resource;
        }
        resourceTypeCount[resource.type] = resourceTypeCount[resource.type] || { ...resource, total: 0, available: 0 };
        resourceTypeCount[resource.type].total++;
        if (!resource) continue;
        if (resource.weekdays?.length < 7) {
          const unavailableWeekdays = [0, 1, 2, 3, 4, 5, 6].filter(d => !resource.weekdays.includes(d));
          // No available weekdays, skipping this resource
          if (unavailableWeekdays.length === 0) continue;

          const dateRangeLength = moment(date.end).diff(date.start, 'days') + 1;
          // Daterange longer than a week, skipping this resource since it's not available for the whole period
          if (dateRangeLength >= 7) continue;

          // Check if unavailable weekdays are inside the daterange
          let found = false;
          let day = new Date(date.start).getDay();
          const end = (day + dateRangeLength) % 7;
          while (day < end && !found) {
            found = unavailableWeekdays.includes(day);
            day = (day + 1) % 7;
          }
          // Unavailable weekday found inside range, skipping this resource
          if (found) continue;
        }

        // Checking if resource has some unavailability within the period
        const available = resource.unavailabilities?.every(unavailability => !conflictingDateRange(date, unavailability));
        if (!available) continue;

        // Getting all possible conflicting bookings
        // TODO Use an index byResource
        const bookings = await API.crud({
          operation: '_read',
          table: 'bookings',
          query: {},
          filter: {
            dynamoDB: {
              FilterExpression: 'NOT(#e < :s OR #s > :e)',
              ExpressionAttributeNames: {
                '#s': 'startDate',
                '#e': 'endDate',
              },
              ExpressionAttributeValues: {
                ':s': date.start,
                ':e': date.end || date.start
              },
            }
          }
        });

        if (!schedules.length && plan.dateRange) {
          // If plan uses resources and no schedules or requires dateRange
          // set a "fake" schedule for the whole day to force conflict
          // (eg: 1st party diving center with daterangePADI courses or rooms without check-in time)
          schedules = [{
            startTime: '1970-01-01T00:00:00.000Z',
            endTime: '1970-01-01T23:59:59.999Z',
          }];
        }
        // If plan uses schedules and resources (eg: JetSki rental or group boat excursions)
        schedules.forEach(schedule => {
          schedule.resources = schedule.resources || [];
          // Only allow today schedules if they are in the future
          if (plan.dateRange || date.start !== today || schedule.startTime > currentTime) {
            let resourceMinPax = resource.allowance.min;
            let resourceMaxPax = resource.allowance.max;
            for (let booking of bookings) {
              if (booking.id !== route.params.id) {
                // Check if booking (or booking schedules) conflict in day, time and resources
                const bookingSchedules = booking.schedules?.length ? booking.schedules : [booking];
                bookingSchedules.forEach(bookingSchedule => {
                  if (!bookingSchedule.resources?.length) {
                    bookingSchedule.resources = booking.resources || [];
                  }
                  if (
                    bookingSchedule.resources?.includes(resource.id) &&
                    conflictingDateRange(date, {
                      start: bookingSchedule.startDate,
                      end: bookingSchedule.endDate || bookingSchedule.startDate
                    }) &&
                    (!schedule.start || conflictingDateRange(schedule, {
                      start: bookingSchedule.startDate,
                      end: bookingSchedule.endDate || bookingSchedule.startDate
                    })) &&
                    (!schedule.startTime || conflictingDateRange({
                      start: getTimeValue(schedule.startTime),
                      end: schedule.endTime ? getTimeValue(schedule.endTime) : getTimeValue(schedule.startTime) + ((schedule.duration || plan.duration) / 60)
                    }, {
                      start: getTimeValue(bookingSchedule.startTime),
                      end: bookingSchedule.endTime ? getTimeValue(bookingSchedule.endTime) : getTimeValue(bookingSchedule.startTime) + (booking.duration / 60)
                    }))
                  ) {
                    // Resource already used and not shareable
                    const totalAttendees = booking.attendees.adults + booking.attendees.kids + booking.attendees.infants;
                    resourceMaxPax -= totalAttendees;
                    if (resource.shareable) {
                      resourceMinPax -= totalAttendees;
                    } else {
                      resourceMaxPax = 0;
                    }
                    // Resource unavailable for this schedule
                  }
                });
              }
              if (resourceMaxPax <= 0) break;
            }
            // Resource available for this schedule
            if (resourceMaxPax > 0) {
              schedule.resources.push({
                ...resource,
                allowance: {
                  ...resource.allowance,
                  min: Math.max(resourceMinPax, 1),
                  max: resourceMaxPax
                }
              });
            }
          }
        });
      }
      setAvailability(plan.resources ? schedules.filter((s, idx) => {
        // There must be at least one resource per type
        if (!s.resources?.length) return false;
        let minResource = {
          total: Infinity
        };
        // Reseting the available count for each schedule;
        for (let type in resourceTypeCount) {
          resourceTypeCount[type].available = 0;
        }
        s.resources.forEach(resource => {
          resourceTypeCount[resource.type].available++;
          if (minResource.total >= resourceTypeCount[resource.type].total) {
            minResource = { ...resourceTypeCount[resource.type] };
          }
        });
        s.minResource = { ...minResource };
        if (route.params.startTime && getTimeValue(route.params.startTime) === getTimeValue(s.startTime)) {
          setSelected(idx);
        }
        return s.minResource.available;
      }) : schedules);
    };

    if (date.start) buildAvailability();
  }, [date]);

  const disableNext = () => {
    return loadingPrice || loading || !(availability === true || availability?.length && selected !== null);
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <HeaderComponent title={service.title[locale]} subtitle={availability ? price : ''} subtitleComponent={loadingPrice || availability === 'loading' ? (<ActivityIndicator color={Theme.palette.textDark} />) : null} completed />
      <ScrollView style={Theme.styles.flex} contentContainerStyle={Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop0] : null}>
        <View style={Theme.XL ? [Theme.styles.row, Theme.styles.alignStartStart] : null}>
          {/* Calendar */}
          <View style={Theme.XL ? Theme.styles.flex : null}>
            <HeaderComponent title={plan.dateRange ? translate('DATES') : translate('DATE')} style={Theme.XL ? styles.dateHeaderXL : null} />
            <Calendar
              key={locale}
              current={today}
              minDate={today}
              markingType={'period'}
              markedDates={markedDates}
              onDayPress={pressedDate}
              enableSwipeMonths={true}
              disabledDaysIndexes={[]}
              theme={{
                arrowColor: Theme.palette.primary,
                todayTextColor: Theme.palette.primary
              }}
            />
          </View>

          {/* Availability/CTA Section */}
          <View style={[Theme.styles.container, Theme.XL ? styles.availabilityContainerXL : null]}>
            <HeaderComponent title={translate('AVAILABILITY')} />
            { !date || !date.start ? (
              <View style={[Theme.styles.column, Theme.styles.paddingTop40]}>
                <MaterialCommunityIcons name='calendar' color={Theme.palette.text} size={40} />
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.padding15]}>
                  { translate('SELECT_DATE') }
                </Text>
              </View>
            ) : plan.dateRange && !date.end ? (
              <View style={[Theme.styles.column, Theme.styles.alignCenterCenter]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingBottom8, Theme.styles.width]}>
                  { translate('DATE_RANGE') }
                </Text>
                <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.paddingBottom16, Theme.styles.width]}>
                  { moment(date.start).format('ll') } -
                </Text>
                <MaterialCommunityIcons name='ray-start-arrow' color={Theme.palette.text} size={30} />
                <View style={[Theme.styles.row, Theme.styles.paddingTop16]}>
                  <MaterialCommunityIcons name='calendar-end' color={Theme.palette.text} size={30} />
                  <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft16]}>
                    { translate('SELECT_END_DATE') }
                  </Text>
                </View>
              </View>
            ) : availability === 'loading' ? (
              <View style={[Theme.styles.column, Theme.styles.paddingTop30]}>
                <ActivityIndicator color={Theme.palette.text} />
                <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.padding15]}>
                  { translate('LOADING') }
                </Text>
              </View>
            ) : plan.dateRange && (plan.minDateRange || 0) > range ? (
              <View style={[Theme.styles.column, Theme.styles.paddingTop10]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingVertical15]}>
                  { translate('DATE_RANGE_MISMATCH_TITLE') }
                </Text>
                <MaterialCommunityIcons name='emoticon-sad-outline' color={Theme.palette.text} size={40} />
                <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.padding15]}>
                  { translate('SELECT_MIN_RANGE', {
                    threshold: translate('MIN'),
                    min: plan.minDateRange || 1,
                    unit: translate((plan.dateRangeUnit?.toUpperCase() || 'NIGHT') + '(S)').toLowerCase()
                  }) }
                </Text>
              </View>
            ) : availability === false || availability?.length === 0 ? (
              <View style={[Theme.styles.column, Theme.styles.paddingTop10]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingVertical15]}>
                  { translate('NOT_AVAILABLE_TITLE') }
                </Text>
                <MaterialCommunityIcons name='emoticon-sad-outline' color={Theme.palette.text} size={40} />
                <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.padding15]}>
                  { plan.dateRange ? translate('NOT_AVAILABLE_IN_RANGE') : translate('NOT_AVAILABLE') }
                </Text>
              </View>
            ) : availability === true ? (
              <View style={[Theme.styles.column, Theme.styles.paddingTop10]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingVertical15]}>
                  { translate('AVAILABLE_TITLE') }
                </Text>
                <MaterialCommunityIcons name='emoticon-happy-outline' color={Theme.palette.text} size={40} />
                <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.padding15]}>
                  { translate('AVAILABLE_MESSAGE') }
                </Text>
              </View>
            ) : availability?.map((schedule, index) =>
              <SelectorComponent
                key={index}
                onPress={() => setSelected(index)}
                selected={selected === index}
                titleIcon={plan.schedules?.length ? 'timer' : 'check'}
                title={
                  plan.schedules?.length
                    ? moment.utc(schedule.startTime).format('hh:mm A')
                    : schedule.minResource?.name?.[locale] || translate('OPTION_NUMBER', {number: index + 1})
                }
                subtitle={schedule.subtitle?.[locale] || ''}
                value={
                  plan.resources?.length
                    ? `${schedule.minResource.available}/${schedule.minResource.total}`
                    : translate('SELECT')
                }
                valueIcon={
                  plan.resources?.length
                    ? ResourceTypes[schedule.minResource.type].icon || 'dots-vertical'
                    : ''
                }/>
            )}
          </View>
        </View>
      </ScrollView>
      <View style={Theme.styles.actionButtonBorder}>
        <View style={[Theme.styles.width, Theme.styles.actionButtonContainer, Theme.XL ? [Theme.styles.padding16, Theme.styles.paddingRight32] : null]}>
          <ButtonComponent onPress={next} text={plan.type === 'noAttendees' ? translate('CONFIRM') : translate('NEXT')} disabled={disableNext()} loading={loading} />
        </View>
      </View>
    </View>
  );
};

