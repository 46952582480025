import React, { useContext } from 'react';
import {
  View,
  Platform,
} from 'react-native';
import * as Device from 'expo-device';
import Constants from 'expo-constants';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { deviceId } from '../common/api.service';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale, LANGUAGE_LABELS } from '../../lang';
import { ListComponent } from '../common/list';
import { HeaderComponent } from '../common/header.component';

export const MoreListComponent = ({ navigation }) => {
  const context = useContext(Context);
  const locale = getCurrentLocale();

  const open = (item) => navigation.navigate(item.navigateTo, { title: item.title, ...item.navigateToParams });

  const list = [{
    id: 'settings',
    title: translate('SETTINGS'),
    data: [{
      icon: (
        <MaterialIcons name='account-box' size={25} color={Theme.styles.text.color} />
      ),
      title: translate('ACCOUNT'),
      navigateTo: 'Account',
      onPress: open
    }]
  }, {
    id: 'info',
    title: translate('INFORMATION'),
    data: []
  }, {
    id: 'about',
    title: translate('ABOUT'),
    data: [{
      icon: (
        <MaterialIcons name='gavel' size={25} color={Theme.styles.text.color} />
      ),
      title: translate('TERMS_AND_CONDITIONS'),
      navigateTo: 'Terms',
      onPress: open
    }, {
      icon: (
        <MaterialIcons name='phone-iphone' size={25} color={Theme.styles.text.color} />
      ),
      title: translate('APP_INFO'),
      navigateTo: 'MoreKeyValues',
      onPress: open,
      navigateToParams: {
        keyValues: [{
          value: translate('APP_INFO_HEADER')
        }, {
          key: translate('DEVELOPER'),
          value: 'GuestTours Ltd.',
          icon: 'code-json'
        }, {
          key: translate('APP_NAME'),
          value: process.env.APP_DISPLAY_NAME,
          icon: 'apps'
        }, {
          key: translate('HOTEL_NAME'),
          value: context.hotelConfig?.data?.info?.hotel?.name || process.env.APP_DISPLAY_NAME,
          icon: 'home'
        }, {
          key: translate('LANGUAGE'),
          value: LANGUAGE_LABELS[locale],
          icon: 'translate'
        }, {
          key: translate('PLATFORM'),
          value: `${Device.osName} (${Device.osVersion})`,
          icon: Platform.OS === 'ios' ? 'apple-ios': Platform.OS === 'android' ? 'android' : 'web',
        }, {
          key: translate('DEVICE_ID'),
          value: deviceId,
          icon: 'cellphone-text'
        }, {
          key: translate('APP_VERSION'),
          value: Constants.expoConfig?.version,
          icon: 'information-outline'
        }, {
          key: translate('BUILD_VERSION'),
          value: Constants.expoConfig?.ios?.buildNumber,
          icon: 'information-outline'
        }]
      }
    }]
  }];
  if (process.env.ENV.includes('dev')) {
    // Development mode
    list[2].data[1].navigateToParams.keyValues.push({
      key: 'Environment',
      value: 'Development',
      icon: 'information-outline'
    });
    list[2].data[1].navigateToParams.keyValues.push({
      key: 'API URL',
      value: process.env.API_URL,
      icon: 'information-outline'
    });
    list[2].data[1].navigateToParams.keyValues.push({
      key: 'CDN URL',
      value: process.env.CLOUDFRONT_URL,
      icon: 'information-outline'
    });
    list[2].data[1].navigateToParams.keyValues.push({
      key: 'BUNDLE ID',
      value: process.env.BUNDLE_ID,
      icon: 'information-outline'
    });
  }

  const mapService = (service) => ({
    title: service.title,
    icon: (
      <MaterialCommunityIcons name={service.icon} size={25} color={Theme.styles.text.color} />
    ),
    navigateTo: `More${service.template}`,
    onPress: open,
    navigateToParams: service.params
  });

  context.moreGroups?.data?.forEach(group => {
    const section = list.find(s => s.id === group.id);
    if (section) {
      section.data = group.services.map(mapService).concat(section.data);
    } else {
      list.push({
        id: group.id,
        title: translate(group.id?.toUpperCase() || ''),
        icon: group.icon,
        data: group.services.map(mapService)
      });
    }
  });

  return (
    <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded] : null]}>
      { Theme.XL ? (
        <View style={Theme.styles.paddingLeft16}>
          <HeaderComponent title={translate('MORE')} />
        </View>
      ) : null}
      <ListComponent
        style={Theme.XL ? {container: Theme.styles.paddingHorizontal22}: null}
        navigation={navigation}
        list={list}
      />
    </View>
  );
};
