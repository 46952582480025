import React, { useRef } from 'react';
import {
  Animated,
  Easing
} from 'react-native';

const VALUES = {
  opacity: {
    START: 0,
    END: 1
  },
  left: {
    START: 10,
    END: 0
  },
  top: {
    START: 10,
    END: 0
  }
};

export const AnimateService = ({ properties, Component, delay, duration, startValue, endValue, style, startAnimation }) => {

  style = {
    position: 'relative',
    ...style
  };

  properties.forEach(prop => {
    const name = prop.name || prop;
    const anim = useRef(new Animated.Value(prop.startValue || startValue || VALUES[name].START)).current;
    const animation = Animated.timing(anim, {
      toValue: prop.endValue || endValue || VALUES[name].END,
      delay: prop.delay || delay || 0,
      duration: prop.duration || duration || 1000,
      useNativeDriver: false
    });
    if (startAnimation !== false) {
      animation.start();
    }
    style[name] = anim;
  });

  return (
    <Animated.View style={style}>
      { <Component /> }
    </Animated.View>
  );
};
