// TODO implement compression for web
// TODO implement compression for web
// TODO implement compression for web
export const Image = {
  compress: (uri, options) => {
    return uri;
  }
};

export const Video = {
  compress: (uri, options) => {
    return uri;
  }
};
