import React, { useLayoutEffect, useContext, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  Switch,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import uuid from 'react-native-uuid';
import Toast from 'react-native-toast-message';

import * as API from '../../common/api.service';
import { Theme } from '../../../theme';
import { translate, getCurrentLocale } from '../../../lang';
import { Tile } from '../../common/tile';
import { Context } from '../../Context';
import { AnimateService } from '../../common/animate.service';
import { LocalizedInput } from '../../common/localized-input.component';
import { HeaderComponent } from '../../common/header.component';
import { ButtonComponent } from '../../common/button.component';
import { getColumnStyle, getDurationString, getLowestPrice } from '../../common/utils.service';

const styles = StyleSheet.create({
  inputsContainer: {
    height: 150
  }
});

export const ContentGroupDetailComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const locale = getCurrentLocale();
  let { groups, group, key } = (route.params || {});
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [title, setTitle] = useState(group?.title || {});
  const [subtitle, setSubtitle] = useState(group?.subtitle || {});
  const [active, setActive] = useState(group?.active !== false);
  group = group || {};
  group.services = group.services || [];

  const openDetail = (service) => {
    context.navigation.title = service && service.title && service.title[locale] || null;
    navigation.navigate('ContentDetail', {
      ...route.params,
      groups,
      group,
      service
    });
  };

  const save = async () => {
    setLoading(true);
    group = group || {};
    group.title = title;
    group.subtitle = subtitle;
    group.active = active;
    if (!group.id) {
      context[key].data = context[key].data || [];
      context[key].data.push({
        id: uuid.v4(),
        ...group
      });
    }
    await (API.crud({
      operation: '_update',
      table: 'hotels',
      query: context[key]
    })).finally(() => setLoading(false));
    navigation.navigate('ContentGroups', {
      ...route.params,
      groups
    });
  };

  const deleteGroup = () => {
    return Toast.show({
      type: 'action',
      autoHide: false,
      text1: translate('CONFIRM_ACTION'),
      text2: translate('CONFIRM_ACTION_DESCRIPTION'),
      props: {
        actions: [{
          text: translate('NO'),
          onPress: Toast.hide
        }, {
          text: translate('YES'),
          class: 'error',
          onPress: async () => {
            setLoadingDelete(true);
            const index = groups.indexOf(group);
            if (index !== -1) {
              groups.splice(index, 1);
            }
            await (API.crud({
              operation: '_update',
              table: 'hotels',
              query: context[key]
            })).finally(() => setLoadingDelete(false));
            navigation.navigate('ContentGroups', {
              ...route.params,
              groups,
            });
          }
        }]
      }
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity onPress={save} style={[Theme.styles.row, Theme.styles.alignCenterCenter, Platform.OS === 'web' ? Theme.styles.marginRight16: null]} disabled={loading}>
          { loading ? (
            <ActivityIndicator color={Theme.palette.primaryContrast} />
          ) : (
            <MaterialCommunityIcons name='check' color={Theme.palette.primaryContrast} size={25} />
          )}
        </TouchableOpacity>
      ),
    });
  }, [navigation, loading, title, subtitle, active]);

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <HeaderComponent title={translate('DETAILS')} subtitleComponent={(
        <Switch
          style={Theme.styles.activeButton}
          onValueChange={setActive}
          value={active}
        />
      )}/>
      <LocalizedInput value={title} onChange={setTitle} label={translate('TITLE')} required />
      <LocalizedInput value={subtitle} onChange={setSubtitle} label={translate('SUBTITLE')} />
      <View style={Theme.styles.padding8} />
      <HeaderComponent title={translate('SERVICES')} actionText={translate('ADD_SERVICE')} onPress={() => openDetail()} outlined />
      <View style={Theme.styles.paddingBottom8} />
      { group?.services?.length ? (
        <FlatList
          numColumns={Theme.numberOfColumns}
          data={group.services}
          keyExtractor={item => item.id}
          renderItem={({ item, index }) => (
            <AnimateService
              key={index}
              properties={['opacity', 'left', 'top']}
              style={getColumnStyle(index, groups.length, Theme.numberOfColumns)}
              delay={index * 50}
              duration={500}
              Component={() => (
                <Tile
                  media={item.media}
                  title={item.title?.[locale]}
                  label={getLowestPrice(item.plans) ? {
                    title: translate('FROM'),
                    value: getLowestPrice(item.plans)
                  } : null}
                  subtitle={item.subtitle && item.subtitle[locale] || ''}
                  caption={{ icon: 'timer', text: getDurationString(item.plans?.[0]?.duration) }}
                  onPress={() => openDetail(item) }
                />
              )}
            />
          )}
        />
      ) : (
        <View style={[Theme.styles.flex, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
          <Text style={[Theme.styles.text, Theme.styles.textDark]}>
            { translate('NO_SERVICES_YET') }
          </Text>
        </View>
      )}
      { group.id ? (
        <ButtonComponent text={translate('DELETE_GROUP')} type='error' onPress={deleteGroup} loading={loadingDelete} disabled={loading || loadingDelete} />
      ) : null}
    </View>
  );
};
