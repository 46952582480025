import {
  StyleSheet,
} from 'react-native';

import { Theme } from '../../../../theme';

export const styles = StyleSheet.create({
  selector: {
    inputWeb: {
      ...Theme.styles.inputWeb,
      ...Theme.styles.flex,
      ...Theme.styles.text,
      ...Theme.styles.textDark,
      ...Theme.styles.letterSpacing1,
    },
    iconContainer: {
      position: 'relative'
    },
    viewContainer: {
      marginLeft: -5,
      display: 'flex',
      flexDirection: 'row-reverse'
    }
  }
});
