import React, { useEffect, useContext, useState } from 'react';
import {
  ActivityIndicator,
  ScrollView,
  Text,
  View,
} from 'react-native';
import uuid from 'react-native-uuid';

import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { getDateString, getOrderPrice, getFinalPrice, getPriceString, getDuration, getNextRoute } from '../common/utils.service';
import { HeaderComponent } from '../common/header.component';
import { ButtonComponent } from '../common/button.component';
import { OrderSummaryComponent } from '../bookings/order-summary';
import { upsertBooking } from '../bookings/service';
import { PolicyComponent } from '../common/policy.component';

export const OrderComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const { guest, group, service, type, order, room } = route.params;
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [price, setPrice] = useState(route.params?.price || 0);
  const locale = getCurrentLocale();
  const roomNumber = context.user?.bookings?.find(b => b.id === room)?.roomNumber;
  const today = new Date().toISOString().split('T')[0];
  const plan = route.params.plan || { id: 'no-plan' };
  const date = route.params.date || { start: today, end: today };
  const slot = route.params.slot || { startTime: 9 };
  const nextRoute = getNextRoute('ServiceOrder', { ...service, ...plan });

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const getPrice = async () => {
      setLoadingPrice(true);
      const finalPrice = await getFinalPrice(group, date, getOrderPrice(order), undefined, route.params?.discount);
      setLoadingPrice(false);
      return setPrice(getPriceString(parseFloat(finalPrice)));
    };
    if (mounted) {
      getPrice();
    }
  }, [order , mounted]);

  const bookService = async () => {
    const booking = {
      oldId: route.params.id,
      id: `${type}#${group.id}#${service.id}#${plan.id}#${date.start}#${route.params.id ? route.params.id.split('#').pop() : '%' + uuid.v4() + '%'}`,
      accountId: guest?.id,
      plan: plan.id,
      startDate: date.start,
      endDate: date.end,
      startTime: slot.startTime,
      status: 'PENDING',
      price: price,
      order,
      roomNumber,
      duration: getDuration(date, plan),
      createdAt: Date.now(),
      integrationInfo: slot.integrationInfo
    };
    const upserted = await upsertBooking(context, group, plan, booking, setLoading);
    if (upserted) {
      navigation.navigate(route.params.navigateTo || `${type}ServiceGroups`, { ...route.params.navigateToParams });
    }
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <HeaderComponent title={service.title[locale]} subtitle={price} subtitleComponent={loadingPrice ? (<ActivityIndicator color={Theme.palette.textDark} />) : null} completed />
      <HeaderComponent title={plan.dateRange ? translate('DATES') : translate('DATE')} subtitle={getDateString(date, slot, plan)} subtitleMultiline={true} completed />
      <HeaderComponent title={translate('ORDER')} subtitleComponent={(
        <View>
          { roomNumber ? (
            <Text style={[Theme.styles.fontSize12, Theme.styles.textNormal, Theme.styles.textRight]}>{ translate('ROOM') + ': ' }<Text style={Theme.styles.textBold}>{roomNumber}</Text></Text>
          ) : null }
          <Text style={[Theme.styles.fontSize12, Theme.styles.textNormal, Theme.styles.textRight]}>{ translate('PLAN') + ': ' }<Text style={Theme.styles.textBold}>{plan?.title?.[locale] || translate('NO_PLAN')}</Text></Text>
        </View>
      )} />
      <ScrollView style={Theme.styles.flex} contentContainerStyle={[Theme.styles.column, Theme.styles.height, Theme.styles.paddingTop10]}>
        <View style={[Theme.styles.column, Theme.styles.flex, Theme.styles.paddingLeft8, Theme.styles.paddingRight8, Theme.styles.alignStretchStart]}>
          <OrderSummaryComponent order={order} />
          <View style={Theme.styles.flex} />
          { !nextRoute ? (
            <PolicyComponent service={service} plan={plan} locale={locale} />
          ) : null}
        </View>
      </ScrollView>
      <View style={Theme.styles.actionButtonBorder}>
        <View style={[Theme.styles.width, Theme.styles.actionButtonContainer, Theme.XL ? [Theme.styles.padding16, Theme.styles.paddingRight32] : null]}>
          <ButtonComponent onPress={bookService} text={translate('CONFIRM')} disabled={loading} loading={loading} />
        </View>
      </View>
    </View>
  );
};

