import React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { BottomSheetTextInput } from '@gorhom/bottom-sheet';

import { Theme } from '../../theme';

const styles = StyleSheet.create({
  inlineLabel: {
    maxWidth: '70%'
  },
  textInput: {
    ...Theme.styles.flex,
    ...Theme.styles.text,
    ...Theme.styles.textRight,
    ...Theme.styles.textDark,
    fontSize: 16,
    lineHeight: 20,
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.background,
    marginHorizontal: 6
  }
});

export const InlineInputComponent = ({
  label,
  value,
  onChange,
  defaultValue,
  keyboardType,
  inputComponent,
  style
}) => {
  const InputComponent = inputComponent || BottomSheetTextInput;
  if (keyboardType === 'numeric') {
    value = (value ?? defaultValue ?? 0).toString();
    const _onChange = onChange;
    onChange = (value, forceWebChange) => {
      if (!forceWebChange || Platform.OS === 'web') {
        _onChange(parseFloat(value.nativeEvent.text));
      }
    };
  }
  return (
    <View style={[Theme.styles.row, Theme.styles.alignCenterSpaceBetween, Theme.styles.marginBottom8, Theme.styles.flex, style?.container]}>
      { label ? (
        <Text style={[Theme.styles.inputLabel, Theme.styles.paddingTop16, Theme.styles.paddingRight16, styles.inlineLabel, style?.label]}>
          { label }
        </Text>
      ) : null }
      <InputComponent style={[styles.textInput, style?.input]} keyboardType={keyboardType || 'default'} onChange={value => onChange(value, true) } onEndEditing={onChange} defaultValue={value || defaultValue} />
    </View>
  );
};
