import { Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import uuid from 'react-native-uuid';
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';

import { Theme } from '../../theme';
import { Auth } from './auth.service';
import { translate, getCurrentLocale } from '../../lang';
import { getBookingContext } from './utils.service.js';
import { version } from '../../../package.json';

const NEEDS_UPDATE_RESPONSE = 'received bad response code from server 421';
const NETWORK_DOWN_RESPONSE = 'The operation couldn’t be completed. Network is down';
const UNAUTHORIZED = 'Expected HTTP 101 response but was \'401 Unauthorized\'';
const FIVE_SECONDS = 5 * 1000;
const FIVE_MINUTES = FIVE_SECONDS * 60;
const MAX_FRAME_SIZE = 32000; // 32kB API Gateway Websocket frame limit
const FRAME_OFFSET = 5000;
const ACTIONS_TO_QUEUE = ['crud_create', 'crud_update', 'crud_delete', 'message'];

const frames = {};

let socket;
let navigation;
let resolveConnect;
let rejectConnect;
let ping;
export let deviceId;
export let context;

let _setContext;
export const setContext = (ctx) => {
  context = ctx;
  return _setContext(ctx);
};
export const initializeCtx = (ctx) => {
  [context, _setContext] = ctx;
};

export const initializeNav = (nav) => {
  navigation = nav;
};

export let connected;

const createListener = (event) => {
  // TODO Create timeout
  return new Promise((resolve, reject) => {
    listeners[event] = [resolve, reject];
  });
};

const listeners = {};

const requestNotificationsPermission = async () => {
  if (Platform.OS !== 'web') {
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: Theme.palette.primary
      });
    }
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      Toast.show({
        type: 'error',
        text1: translate('NOTIFICATIONS_OFF'),
        text2: translate('NOTIFICATIONS_OFF_DESCRIPTION'),
      });
      return;
    }
    try {
      if (Device.isDevice) {
        const result = await Notifications.getExpoPushTokenAsync({
          projectId: Constants.expoConfig.extra.eas.projectId,
        });
        return result?.data;
      }
    } catch (e) {
      console.error('Error trying to get pushToken', e);
    }
  } else {
    console.warn('This device does not allow Push notifications!');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const subscribeToNotifications = () => {
  Notifications.addNotificationReceivedListener(onMessage);
  Notifications.addNotificationResponseReceivedListener(onMessage);
};

const showBookingDetail = (booking, context, account) => {
  const params = {
    booking: {
      ...booking,
      bookingContext: getBookingContext(booking, context),
    }
  };
  if (context.user?.features?.includes('guests')) {
    params.guest = account || context.user;
    params.booking.bookingContext.guest = account || context.user;
    navigation.navigate('Guests', {
      screen: 'GuestDetail',
      params
    });
  } else {
    navigation.navigate('Bookings', {
      screen: 'MyBookings',
      params
    });
  }
};

const pressedPushNotification = (Toast, message, context) => {
  if (message.booking) {
    Toast.hide();
    return showBookingDetail(message.booking, context);
  } else {
    // Show notification dalog??
    console.log('pressed non-booking notification', message);
  }
};

const connectWebSocket = async (authData) => {
  const { hotelRef, username, password, locale } = authData;
  const pushToken = await requestNotificationsPermission();
  subscribeToNotifications();
  deviceId = pushToken ? pushToken.match(/\[(.+)\]/)[1] : uuid.v4();
  const authToken = new Auth().getConnectionAuth(process.env.BUNDLE_ID);

  context.hotelId =  process.env.BUNDLE_ID + (hotelRef ? `.${hotelRef}` : '');
  const data = JSON.stringify({
    deviceId,
    bundleId: process.env.BUNDLE_ID,
    hotelId: process.env.BUNDLE_ID + (hotelRef ? `.${hotelRef}` : ''),
    Authorization: authToken,
    username,
    password,
    locale,
    pushToken,
    version
  });
  socket = new WebSocket(process.env.API_URL + `?token=${Auth.btoa(data)}`);
  socket.addEventListener('open', async () => {
    console.log('Connected!');
    context.offline = false;
    if (ping) {
      clearInterval(ping);
      ping = null;
    }
    ping = setInterval(() => send({ action: 'ping' }), FIVE_MINUTES);
    context.synching = true;

    // Getting user session
    const session  = await crud({
      operation: '_read',
      table: 'accounts',
      query: {},
    });
    context.user = session[0];
    resolveConnect(context.user);

    // Synching pending operations
    const pendingQueue = JSON.parse(await AsyncStorage.getItem('pendingQueue') || '[]');
    while (pendingQueue.length) {
      const message = pendingQueue.pop();
      const id = message.data?.reqId || message.action || (message.operation + message.table);
      const response = createListener(id);
      await send(message, true);
      await response;
      if (message.data?.table === 'hotels') {
        context[message.data.query.key] = message.data.query;
      }
    }
    AsyncStorage.setItem('pendingQueue', '[]').then(() => {});
    if (hotelRef) {
      AsyncStorage.setItem('context.ref', hotelRef).then(() => {});
    }
    context.synching = false;
    //setContext({...context});
    return;

  });
  socket.addEventListener('error', (e) => {
    console.log('socket error', e);
  });
  socket.addEventListener('close', (e) => {
    console.log('socket closed', e);
    context.offline = true;
    context.noNetwork = e.message === NETWORK_DOWN_RESPONSE;
    context.needsUpdate = e.message === NEEDS_UPDATE_RESPONSE;
    context.unauthorized = e.message === UNAUTHORIZED;
    context.offlineReason = e.message;
    setContext({
      ...context
    });
    if (ping) {
      clearInterval(ping);
      ping = null;
    }
    setTimeout(() => {
      console.log('Trying to reconnect...');
      connectWebSocket(authData);
    }, FIVE_SECONDS);
    return rejectConnect();
  });
  socket.addEventListener('message', onMessage);
};

export const onMessage = async (data) => {
  //console.log('Received message from server', data);
  if (!data) return;
  const locale = getCurrentLocale();
  let message = data.data || data.message || data;
  if (typeof message === 'string') message = JSON.parse(message);
  if ('frame' in message) {
    frames[message.id] = frames[message.id] || [];
    frames[message.id][message.frame] = message.message;
    if (frames[message.id].filter(frame => frame).length === message.total) {
      message = JSON.parse(frames[message.id].join(''));
      //console.log('Fragmented message fully received', message);
      delete frames[message.id];
    } else {
      //console.log('Fragmented message received', message.id, message.frame, message.total);
      return;
    }
  }
  let notifications = (await AsyncStorage.getItem('notifications')) || '';
  if (notifications.includes(`:${message.id || message.request?.identifier}:`)) return; // This message has already been parsed
  const id = message.reqId || message.action || ((message.data?.operation || '') + (message.data?.table || ''));
  if (id && listeners[id]) {
    // Response to a message that has been sent by this client
    if (message.success) {
      // Resolve is always index 0
      listeners[id][0](message.data);
    } else {
      // Reject is always index 1
      listeners[id][1](message.data);
    }
    delete listeners[id];
  } else if (message.request?.trigger?.type === 'push') {
    const { title, body, subtitle, sticky, data } = message.request.content;
    Toast.show({
      type: 'push',
      autoHide: !sticky,
      text1: title,
      text2: body || subtitle,
      props: data,
      onPress: (Toast, context) => pressedPushNotification(Toast, message, context),
    });
  } else if (message.request) {
    // Sync notification
    switch (message.request.operation + '+' + message.request.table) {
    case '_create+bookings':
    case '_update+bookings':
      // TODO CHECK IF THE ONLY UPDATE IS THE CONFIRM TO SHOW A DIFFERENT MESSAGE!!
      // TODO CHECK IF THE ONLY UPDATE IS THE CONFIRM TO SHOW A DIFFERENT MESSAGE!!
      // TODO CHECK IF THE ONLY UPDATE IS THE CONFIRM TO SHOW A DIFFERENT MESSAGE!!
      Toast.show({
        type: 'accent',
        //autoHide: false,
        text1: message.notification.title,
        text2: message.notification.body,
        onPress: () => showBookingDetail(message.response, context, message.account),
      });
      // TODO UPDATE BOOKINGSS IN CONTEXT
      // TODO UPDATE BOOKINGSS IN CONTEXT
      // TODO UPDATE BOOKINGSS IN CONTEXT
      break;
    case '_update+hotels':
      await AsyncStorage.setItem(`context.${message.response.key}`, JSON.stringify(message.response));
      setContext({
        ...context,
        [message.response.key]: message.response
      });
      break;
    case '_broadcast+accounts':
      const { title, body, subtitle, sticky } = message;
      Toast.show({
        type: 'push',
        autoHide: !sticky,
        text1: title[locale] || title.en || title,
        text2: (body || subtitle)[locale] || (body || subtitle).en || (body || subtitle),
        props: message,
        onPress: (Toast, context) => pressedPushNotification(Toast, message, context),
      });
      break;
    }
  }
  notifications += `:${message.id || message.request?.identifier}:`;
  AsyncStorage.setItem('notifications', notifications).then(() => {});
};

export const connect = async (authData) => {
  connected = new Promise((resolve, reject) => {
    resolveConnect = resolve;
    rejectConnect = reject;
  });
  try {
    await connectWebSocket(authData);
    return connected;
  } catch(e) {
    console.log('Error connecting', e);
    context.offline = true;
    setContext({
      ...context
    });
    rejectConnect();
  }
  return connected;
};

export const send = async (message, synching/*, receivers*/) => {
  // ADD SOME LOGIC TO SEND TO SPECIFIC RECEIVERS
  if (!synching) {
    await connected?.catch(() => {});
  }
  if (socket && !context.offline) {
    const msg = JSON.stringify(message);
    if (msg.length > MAX_FRAME_SIZE) {
      let OFFSET = Math.ceil(FRAME_OFFSET + ((msg.match(/"/g).length || []) / 3));
      const regExp = new RegExp(`.{1,${MAX_FRAME_SIZE - OFFSET}}`, 'g');
      const frames = msg.match(regExp);
      //console.log('Sending fragmented message with fragment count =', frames.length);
      const id = uuid.v4();
      let idx = 0;
      for (let m of frames) {
        socket.send(JSON.stringify({
          action: message.action,
          data: {
            id,
            frame: idx,
            total: frames.length,
            message: m
          }
        }));
        if (idx && idx % 50 === 0) {
          //console.log('Message with more than 100 frames, throttling...');
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        idx++;
      }
    } else {
      socket.send(msg);
    }
    if (message.data?.table === 'hotels' && ACTIONS_TO_QUEUE.includes(message.action + message.data?.operation)) {
      // Sync local storage
      await AsyncStorage.setItem(`context.hotel.${message.data.query.key}`, JSON.stringify(message.data.query));
    }
    return true;
  } else if (ACTIONS_TO_QUEUE.includes(message.action + message.data?.operation)) {
    // Offline, creating pending tasks queue
    const pendingQueue = JSON.parse(await AsyncStorage.getItem('pendingQueue') || '[]');
    pendingQueue.unshift(message);
    await AsyncStorage.setItem('pendingQueue', JSON.stringify(pendingQueue));
    if (message.data?.table === 'hotels') {
      // Sync local storage
      await AsyncStorage.setItem(`context.hotel.${message.data.query.key}`, JSON.stringify(message.data.query));
    }
    return false;
  }
};

export const signUp = async (data) => {
  try {
    const response = createListener('signup');
    const message = {
      action: 'signup',
      data
    };
    await send(message);
    return response;
  } catch(e) {
    console.log('There has been an error trying to sign up', e);
    throw e;
  }
};

export const sendMessage = async (data) => {
  try {
    const reqId = uuid.v4();
    const response = createListener(reqId);
    data.reqId = reqId;
    const message = {
      action: 'message',
      data
    };
    await send(message);
    return response;
  } catch(e) {
    console.log('There has been an error trying to send a message', e);
    throw e;
  }
};

export const getTranslation = async (data) => {
  try {
    const response = createListener('translate');
    const message = {
      action: 'translate',
      data
    };
    await send(message);
    return response;
  } catch(e) {
    console.log('There has been an error trying to translate text', e);
    throw e;
  }
};

export const crud = async ({ operation, custom, table, index, query, sort, sortKey, filter }) => {
  try {
    const reqId = uuid.v4();
    const response = createListener(reqId);
    const message = {
      action: 'crud',
      data: {
        reqId,
        operation,
        custom,
        table,
        index,
        query,
        sort,
        sortKey,
        filter
      }
    };
    const sent = await send(message, context?.synching);
    if (sent) {
      return response;
    } else {
      // Message has been queued
      return { ...message, queued: true };
    }
  } catch(e) {
    console.log('There has been an error trying to crud data', e);
    throw e;
  }
};

export const uploadFile = async (file) => {
  try {
    const key = uuid.v4() + '.' + file.extension;
    const getSignedUrl = createListener(key);
    const contentType = file.type + '/' + file.extension;
    await send({
      action: 'getSignedUrl',
      data: {
        reqId: key,
        key,
        contentType
      }
    });
    const { url } = await getSignedUrl;
    const blob = await (await fetch(file.source.uri)).blob();
    await fetch(url, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': contentType
      }
    });
    return key;
  } catch (e) {
    console.log('There has been an error trying to upload file', e);
    throw e;
  }
};
