const themes = {
  base: require('./base.js').default,
  current: require('./current/theme.js')
};

let setTheme = (theme = new themes.current.default().getTheme()) => {
  Theme = theme;
  return Theme;
};

let Theme = setTheme();

export { Theme, setTheme, themes };
