import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Theme } from '../../theme';

const styles = StyleSheet.create({
  button: {
    borderRadius: 2,
    height: 30,
    width: 30
  },
  text: {
    minWidth: 70
  }
});

export const NumberInputComponent = ({ onChange, min = 0, max = Infinity, defaultValue = 0, style = {} }) => {
  const [value, setValue] = useState(parseFloat(defaultValue));

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(parseFloat(defaultValue));
    }
  }, [defaultValue]);

  const changeValue = (delta) => {
    const newValue = Math.min(Math.max(value + delta, min), max);
    if (newValue !== value) {
      const result = onChange(newValue);
      if (result === false) return;
      setValue(newValue);
    }
  };

  return (
    <View style={[Theme.styles.row, Theme.styles.paddingRight16, style.container]}>
      <TouchableOpacity onPress={() => changeValue(-1)} style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.button, styles.button, style.button, value <= min ? Theme.styles.buttonDisabled : null ]} disabled={value <= min}>
        <MaterialCommunityIcons name='minus' color={Theme.palette.backgroundDarkContrast} size={style.icon?.size || 25} />
      </TouchableOpacity>
      <Text style={[Theme.styles.text, Theme.styles.textCenter, Theme.styles.textDark, Theme.styles.fontSize25, styles.text, style.text]}>
        { value }
      </Text>
      <TouchableOpacity onPress={() => changeValue(+1)} style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.button, styles.button, style.button, value >= max ? Theme.styles.buttonDisabled : null ]} disabled={value >= max}>
        <MaterialCommunityIcons name='plus' color={Theme.palette.backgroundDarkContrast} size={style.icon?.size || 25} />
      </TouchableOpacity>
    </View>
  );
};
