import React, { useContext, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import uuid from 'react-native-uuid';

import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate, getCurrentLocale } from '../../lang';
import { getDuration, getDateString, getPriceString, getFinalPrice, getPlanPrice, getNextRoute } from '../common/utils.service';
import { HeaderComponent } from '../common/header.component';
import { ButtonComponent } from '../common/button.component';
import { NumberInputComponent } from '../common/number-input.component';
import { PolicyComponent } from '../common/policy.component';
import { upsertBooking } from '../bookings/service';

const styles = StyleSheet.create({
  attendeeRow: {
    width: '100%',
    height: 60
  },
  attendeeRowDisabled: {
    opacity: .5
  },
  attendeesError: {
    subtitle: {
      fontSize: 10,
      color: 'red',
      paddingRight: 8
    }
  }
});

export const AttendeesComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const { guest, group, service, plan, date, slot, attendees, type, additionalInformation, bookings } = route.params;
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [adults, setAdults] = useState(attendees ? attendees.adults : 1);
  const [kids, setKids] = useState(attendees ? attendees.kids : 0);
  const [infants, setInfants] = useState(attendees ? attendees.infants : 0);
  const [price, setPrice] = useState(route.params.price || 0);
  const [mounted, setMounted] = useState(false);
  const locale = getCurrentLocale();
  const nextRoute = getNextRoute('ServiceAttendees', { ...service, ...plan });

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const getPrice = async () => {
      setLoadingPrice(true);
      const attendees = { adults, kids, infants };
      const finalPrice = await getFinalPrice(group, date, getPlanPrice(plan, attendees, date), attendees, route.params?.discount);
      setLoadingPrice(false);
      return setPrice(getPriceString(parseFloat(finalPrice)));
    };
    if (mounted) {
      getPrice();
    }
  }, [adults, kids, infants, mounted]);

  const allowance = {
    min: plan.attendees?.min || 1,
    max: route.params.maxAttendees ?? plan.attendees?.max ?? Infinity,
    adults: true,
    kids: true,
    infants: true
  };
  if (slot?.resources?.length) {
    slot?.resources.forEach(resource => {
      if (resource.allowance.min > allowance.min) allowance.min = resource.allowance.min;
      if (resource.allowance.max < allowance.max) allowance.max = resource.allowance.max;
      allowance.adults = allowance.adults && resource.allowance.adults;
      allowance.kids = allowance.kids && resource.allowance.kids;
      allowance.infants = allowance.infants && resource.allowance.infants;
    });
  }
  Object.keys(bookings || {}).forEach(accountId => {
    if (accountId !== context.user?.id) {
      allowance.min = Math.max(0, allowance.min - bookings[accountId].attendees?.total);
      allowance.max -= bookings[accountId].attendees?.total || 0;
    }
  });

  const next = () => {
    const booking = {
      oldId: route.params.id,
      id: `${type}#${group.id}#${service.id}#${plan.id}#${date.start}#${route.params.id ? route.params.id.split('#').pop() : '%' + uuid.v4() + '%'}`,
      accountId: (guest || context.user)?.id,
      plan: plan.id,
      startDate: date.start,
      endDate: date.end,
      startTime: slot?.startTime,
      duration: getDuration(date, plan),
      attendees: {
        ...(route.params?.parentBooking?.attendees || {}),
        adults,
        kids,
        infants,
        total: (adults || 0) + (kids || 0) + (infants || 0),
      },
      maxAttendees: route.params?.maxAttendees,
      status: 'PENDING',
      price: price,
      createdAt: Date.now(),
      integrationInfo: slot?.integrationInfo,
      additionalInformation: additionalInformation || null,
      public: route.params?.public
    };
    if (!nextRoute) {
      return bookService(booking);
    } else {
      navigation.navigate(`${type}${nextRoute}`, {
        ...route.params,
        booking
      });
    }
  };

  const bookService = async (booking) => {
    const upserted = await upsertBooking(context, group, slot || plan, booking, setLoading);
    if (upserted) {
      navigation.navigate(route.params.navigateTo || `${type}ServiceGroups`, { ...route.params.navigateToParams });
    }
  };

  const availabilitySubtitle = () => {
    if ((adults + kids + infants) < (allowance.min || 1)) {
      return translate('MIN_AVAILABILITY', { min: allowance.min || 1 });
    } else if ((adults + kids + infants) > (slot?.availability || allowance.max)) {
      return translate('MAX_AVAILABILITY', { max: slot?.availability || allowance.max });
    }
    return;
  };

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <HeaderComponent title={service.title[locale]} subtitle={price} subtitleComponent={loadingPrice ? (<ActivityIndicator color={Theme.palette.textDark} />) : null} completed />
      <HeaderComponent title={plan.dateRange ? translate('DATES') : translate('DATE')} subtitle={getDateString(date, slot, plan)} subtitleMultiline={true} completed />
      <HeaderComponent title={translate('ATTENDEES')} subtitle={availabilitySubtitle()} style={styles.attendeesError} />
      <ScrollView contentContainerStyle={[Theme.styles.column, Theme.styles.height, Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop0] : null]}>
        <View style={[Theme.styles.width, Theme.styles.column, Theme.styles.flex]}>
          <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.paddingLeft8, styles.attendeeRow]}>
            <MaterialCommunityIcons name='human-greeting' color={Theme.palette.textDark} size={25} />
            <View style={Theme.styles.flex}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>
                { translate('ADULTS') }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize10, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>
                { translate('PLUS_TWELVE_YEARS_OLD') }
              </Text>
            </View>
            { allowance.adults ? (
              <NumberInputComponent onChange={setAdults} max={(slot?.availability || allowance.max) - kids - infants} defaultValue={adults} />
            ) : (
              <View style={[Theme.styles.row, Theme.styles.paddingRight16]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textDark, Theme.styles.textRight]}>
                  { translate('NOT_ALLOWED') }
                </Text>
              </View>
            )}
          </View>
          <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.paddingLeft8, styles.attendeeRow, !allowance.kids ? styles.attendeeRowDisabled : null]}>
            <MaterialCommunityIcons name='human-child' color={Theme.palette.textDark} size={25} />
            <View style={Theme.styles.flex}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>
                { translate('KIDS') }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize10, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>
                { translate('THREE_TO_TWELVE_YEARS_OLD') }
              </Text>
            </View>
            { allowance.kids ? (
              <NumberInputComponent onChange={setKids} max={(slot?.availability || allowance.max) - adults - infants} defaultValue={kids} />
            ) : (
              <View style={[Theme.styles.row, Theme.styles.paddingRight16]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textDark, Theme.styles.textRight]}>
                  { translate('NOT_ALLOWED') }
                </Text>
              </View>
            )
            }
          </View>
          <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.paddingLeft8, styles.attendeeRow, !allowance.infants ? styles.attendeeRowDisabled : null]}>
            <MaterialCommunityIcons name='baby' color={Theme.palette.textDark} size={25} />
            <View style={Theme.styles.flex}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>
                { translate('INFANTS') }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize10, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>
                { translate('ZERO_TO_THREE_YEARS_OLD') }
              </Text>
            </View>
            { allowance.infants ? (
              <NumberInputComponent onChange={setInfants} max={(slot?.availability || allowance.max) - adults - kids} defaultValue={infants} />
            ) : (
              <View style={[Theme.styles.row, Theme.styles.paddingRight16]}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textDark, Theme.styles.textRight]}>
                  { translate('NOT_ALLOWED') }
                </Text>
              </View>
            )
            }
          </View>
          <View style={Theme.styles.flex} />
          { !nextRoute ? (
            <PolicyComponent service={service} plan={plan} locale={locale} />
          ) : null}
        </View>
      </ScrollView>
      <View style={Theme.styles.actionButtonBorder}>
        <View style={[Theme.styles.width, Theme.styles.actionButtonContainer, Theme.XL ? [Theme.styles.padding16, Theme.styles.paddingRight32] : null]}>
          <ButtonComponent onPress={next} text={plan.additionalInformation?.length ? translate('NEXT') : translate('CONFIRM')} disabled={loadingPrice || loading || !!availabilitySubtitle()} loading={loading} />
        </View>
      </View>
    </View>
  );
};
