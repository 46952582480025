import React, { useContext, useState } from 'react';
import {
  FlatList,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Carousel from 'react-native-snap-carousel';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';
import uuid from 'react-native-uuid';
import { useActionSheet } from '@expo/react-native-action-sheet';
import CheckBox from 'expo-checkbox';

import * as API from '../../../common/api.service';
import { Theme } from '../../../../theme';
import { translate, getCurrentLocale } from '../../../../lang';
import { CarouselItem } from '../../../common/tile';
import { Context } from '../../../Context';
import { LocalizedInput } from '../../../common/localized-input.component';
import { HeaderComponent } from '../../../common/header.component';
import { SelectorComponent } from '../../../common/selector.component';
import { ButtonComponent } from '../../../common/button.component';
import { fString, getPriceString, getPlanPrice, getDurationString } from '../../../common/utils.service';
import { openMediaPicker } from '../../../common/media.service';
import Image from '../../../common/libs/image/image';
import { Picker } from '../../../common/libs/picker/picker';
import { ICONS } from '../../../bookings/detail';
import { MenuItemComponent } from '../../../common/menu-item.component';

const styles = StyleSheet.create({
  media: {
    minHeight: 200,
    height: 200
  },
  icon: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginHorizontal: 5
  },
  iconCheck: {
    padding: 5,
    backgroundColor: Theme.palette.accent,
    color: Theme.palette.accentContrast,
    borderRadius: 100,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  actionColumn: {
    paddingRight: 8,
    width: 40,
    ...Theme.styles.alignEndCenter
  },
  sectionContainer: {
    width: '100%',
    marginTop: -5
  },
  menuViewPicker: {
    marginTop: -8
  }
});

const icons = Object.entries(ICONS);

export const ContentDetailDetailsComponent = ({ navigation, route, state }) => {
  let { groups, group, service, key } = (route.params || {});
  const context = useContext(Context);
  const locale = getCurrentLocale();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(service?.title || {});
  const [subtitle, setSubtitle] = useState(service?.subtitle || {});
  const [active, setActive] = useState(service?.active !== false);
  const [description, setDescription] = useState(service?.description || {});
  const [policy, setPolicy] = useState(service?.policy || {});
  const [media, setMedia] = useState([...(service?.media || [])]);
  const [icon, setIcon] = useState(service?.icon || (route.params.key === 'activityGroups' ? 'GENERIC_ACTIVITY' : 'GENERIC_SERVICE'));
  const [menuSections, setMenuSections] = useState(service?.menuSections || []);
  const [needsDates, setNeedsDates] = useState(service?.needsDates ?? true);
  const [needsAttendees, setNeedsAttendees] = useState(service?.needsAttendees ?? true);
  const [type, setType] = useState(service?.type || 'default');
  const [menuView, setMenuView] = useState('list');
  const [width, setWidth] = useState(Theme.width);
  const { showActionSheetWithOptions } = useActionSheet();

  service = service || {};

  const openPlan = (plan) => {
    context.navigation.title = plan?.title?.[locale] || null;
    navigation.navigate('ContentPlan', {
      ...route.params,
      service: {
        ...service,
        ...parseState()
      },
      plan
    });
  };

  const openMenuItem = (menuItem) => {
    context.navigation.title = menuItem?.title?.[locale] || null;
    navigation.navigate('ContentMenuItem', {
      ...route.params,
      service: {
        ...service,
        ...parseState()
      },
      menuSections,
      menuItem
    });
  };

  const setMenuSection = (idx) => value => {
    menuSections[idx] = value;
    setMenuSections([...menuSections]);
  };

  const removeMenuSection = (idx) => {
    menuSections.splice(idx, 1);
    setMenuSections([...menuSections]);
  };

  const parseState = () => {
    return {
      active: active,
      type: type,
      title: title,
      subtitle: subtitle,
      description: description,
      policy: policy,
      needsDates: needsDates,
      needsAttendees: needsAttendees,
      icon: icon,
      plans: service.plans || [],
      menuView: menuView,
      menuSections: menuSections,
      newMedia: media,
    };
  };

  const confirmRemove = (array, setArray) => {
    return ({ item }) => {
      return Toast.show({
        type: 'action',
        autoHide: false,
        text1: translate('CONFIRM_ACTION'),
        text2: translate('CONFIRM_ACTION_DESCRIPTION'),
        props: {
          actions: [{
            text: translate('NO'),
            onPress: Toast.hide
          }, {
            text: translate('YES'),
            class: 'error',
            onPress: async () => {
              array.splice(array.indexOf(item), 1);
              if (setArray) await setArray([...array]);
              Toast.hide();
            }
          }]
        }
      });
    };
  };

  const addMedia = async (callback) => {
    try {
      const { type, extension, uri } = await openMediaPicker(showActionSheetWithOptions);
      const toUpload = {
        source: { uri },
        toUpload: true,
        extension,
        type
      };
      if (callback) return callback(toUpload);
      else setMedia([...media, toUpload]);
    } catch(e) {
      if (e) {
        Toast.show({
          type: 'error',
          text1: translate('MEDIA_ERROR'),
          text2: translate('MEDIA_ERROR_DESCRIPTION')
        });
      }
    }
  };

  const deleteService = () => {
    return confirmRemove(group.services, async () => {
      setLoading(true);
      await (API.crud({
        operation: '_update',
        table: 'hotels',
        query: context[key]
      })).finally(() => setLoading(false));
      navigation.navigate('ContentGroupDetail', {
        ...route.params,
        groups,
        group
      });
      setLoading(false);
    })({ item: service });
  };
  const onLayout = (event) => setWidth(event.nativeEvent.layout.width);

  state.details = parseState();

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      <ScrollView style={Theme.styles.height} contentContainerStyle={[Theme.styles.column, Theme.styles.alignStretchStart]}>
        <View style={[Theme.styles.row, Theme.styles.padding8, Theme.styles.alignCenterSpaceBetween]}>
          <Text style={Theme.styles.inputLabel}>{translate(active ? 'ENABLED' : 'DISABLED')}</Text>
          <Switch
            style={Theme.styles.activeButton}
            onValueChange={setActive}
            value={active}
          />
        </View>
        <View style={Theme.styles.paddingHorizontal8}>
          <Picker
            placeholder={{}}
            value={type}
            onValueChange={setType}
            label={translate('TYPE')}
            items={[{
              label: translate('DEFAULT'),
              value: 'default'
            }, {
              label: translate('MENU'),
              value: 'menu'
            }]}
          >
            <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput, Theme.styles.paddingTop0]}>
              <View>
                <Text style={Theme.styles.inputLabel}>{translate('TYPE')}</Text>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(type.toUpperCase() || 'DEFAULT')}</Text>
              </View>
            </View>
          </Picker>
        </View>
        <LocalizedInput value={title} onChange={setTitle} label={translate('TITLE')} required />
        <LocalizedInput value={subtitle} onChange={setSubtitle} label={translate('SUBTITLE')} />
        <LocalizedInput value={description} onChange={setDescription} label={translate('DESCRIPTION')} textarea recommendedMax={315} hint={translate('OVERWRITTEN_BY_PLAN_DESCRIPTION')} />
        <LocalizedInput value={policy} onChange={setPolicy} label={translate('SERVICE_POLICY')} textarea hint={translate('OVERWRITTEN_BY_PLAN_POLICY')} />
        <View style={Theme.styles.padding8} />
        <View style={Theme.styles.paddingHorizontal16} >
          <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignStartStart]} onPress={() => setNeedsDates(!needsDates)}>
            <CheckBox
              onValueChange={() => setNeedsDates(!needsDates)}
              color={needsDates ? Theme.palette.accent : null }
              style={Theme.styles.checkbox}
              value={needsDates}
            />
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8]}>
              { translate('NEEDS_DATES') }
            </Text>
          </TouchableOpacity>
          <View style={Theme.styles.padding8} />
          <TouchableOpacity style={[Theme.styles.row, Theme.styles.alignStartStart]} onPress={() => setNeedsAttendees(!needsAttendees)}>
            <CheckBox
              onValueChange={() => setNeedsAttendees(!needsAttendees)}
              color={needsAttendees ? Theme.palette.accent : null }
              style={Theme.styles.checkbox}
              value={needsAttendees}
            />
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8]}>
              { translate('NEEDS_ATTENDEES') }
            </Text>
          </TouchableOpacity>
        </View>
        <View style={Theme.styles.padding8} />
        <HeaderComponent title={translate('MEDIA')} actionText={translate('ADD_MEDIA')} onPress={() => addMedia()} outlined />
        <View style={Theme.styles.paddingBottom8} />
        { media.length ? (
          <View style={styles.media} onLayout={onLayout}>
            <Carousel
              data={media}
              renderItem={(props) => (<CarouselItem {...props} onPress={confirmRemove(media, setMedia)} />)}
              sliderWidth={width}
              itemWidth={width}
              autoplay={true}
              autoplayInterval={5000}
              loop={true}
            />
          </View>
        ) : (
          <View style={[styles.media, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
              { translate('NO_MEDIA_YET') }
            </Text>
          </View>
        )}
        <View style={Theme.styles.padding8} />
        <HeaderComponent title={translate('ICON')} />
        <View style={Theme.styles.paddingBottom8} />
        <FlatList
          data={icons}
          keyExtractor={item => item[0]}
          horizontal={true}
          renderItem={({ item }) => (
            <TouchableOpacity key={item[0]} onPress={() => setIcon(item[0])}>
              <Image
                style={styles.icon}
                source={item[1]}
              />
              { icon === item[0] ? (
                <View style={styles.iconCheck}>
                  <MaterialCommunityIcons name='check' color={styles.iconCheck.color} size={15} />
                </View>
              ) : null}
            </TouchableOpacity>
          )}
        />
        <View style={Theme.styles.padding16} />
        <HeaderComponent title={translate('PLANS')} actionText={translate('ADD_PLAN')} onPress={() => openPlan()} outlined />
        <View style={Theme.styles.paddingBottom8} />
        { service?.plans?.length ? (
          <View style={Theme.styles.paddingHorizontal8}>
            { service.plans.map((plan, idx) => (
              <SelectorComponent
                key={idx}
                onPress={() => openPlan(plan)}
                selected={false}
                titleIcon="timer"
                title={plan.title[locale]}
                subtitle={((plan.subtitle?.[locale] && fString(plan.subtitle[locale], plan) + ' | ') || '') + getDurationString(plan.duration, plan.dateRange && plan.dateRangeUnit === 'night' ? 'NIGHT(S)' : null)}
                value={getPriceString(getPlanPrice(plan))}
              />
            ))}
          </View>
        ) : (
          <View style={[styles.media, Theme.styles.flex, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
              { translate('NO_PLANS_YET') }
            </Text>
          </View>
        )}
        { type === 'menu' || service?.plans?.some(plan => plan.type === 'menu') ? (
          <View>
            <View style={Theme.styles.padding8} />
            <HeaderComponent title={translate('MENU')} />
            <View style={[Theme.styles.paddingHorizontal8, styles.menuViewPicker]}>
              <Picker
                placeholder={{}}
                value={menuView}
                onValueChange={setMenuView}
                label={translate('MENU_VIEW')}
                items={[{
                  label: translate('LIST'),
                  value: 'list'
                }, {
                  label: translate('GRID'),
                  value: 'grid'
                }]}
              >
                <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
                  <View>
                    <Text style={Theme.styles.inputLabel}>{translate('TYPE')}</Text>
                    <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(menuView.toUpperCase() || 'LIST')}</Text>
                  </View>
                </View>
              </Picker>
            </View>
            <View style={Theme.styles.padding8} />
            <HeaderComponent title={translate('MENU_SECTIONS')} actionText={translate('ADD')} onPress={() => setMenuSections([...menuSections, {id: uuid.v4()}])} outlined />
            <View style={Theme.styles.paddingBottom8} />
            { menuSections?.length ? (
              <View style={[Theme.styles.paddingBottom16, Theme.styles.width]}>
                { menuSections.map((section, idx) =>
                  <View style={[Theme.styles.row, Theme.styles.flex, styles.sectionContainer,]} key={idx}>
                    <LocalizedInput style={{container: Theme.styles.flex}} value={section} onChange={setMenuSection(idx)} required />
                    <TouchableOpacity style={styles.actionColumn} onPress={() => removeMenuSection(idx)}>
                      <MaterialCommunityIcons name='delete' color={Theme.palette.text} size={20} />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            ) : (
              <View style={[styles.media, Theme.styles.flex, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
                  { translate('NO_MENU_SECTIONS_YET') }
                </Text>
              </View>
            )}
            <View style={Theme.styles.paddingBottom8} />
            <HeaderComponent title={translate('MENU_ITEMS')} actionText={translate('ADD')} onPress={() => openMenuItem()} outlined />
            { service?.menu ? (
              <View style={[Theme.styles.row, Theme.styles.wrap, Theme.styles.alignCenterSpaceBetween, Theme.styles.paddingBottom16]}>
                { service.menu.map((item, idx) => <MenuItemComponent key={idx} item={item} disabledCount={true} onPress={() => openMenuItem(item)} sections={menuSections} /> )}
              </View>
            ) : (
              <View style={[styles.media, Theme.styles.flex, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
                  { translate('NO_MENU_ITEMS_YET') }
                </Text>
              </View>
            )}
          </View>
        ) : null}
        <View style={Theme.styles.padding8}>
          <ButtonComponent text={translate('DELETE_SERVICE')} type='error' onPress={deleteService} loading={loading} outlined />
        </View>
      </ScrollView>
    </View>
  );
};
