import moment from 'moment';
import React, { memo, useCallback, useState, useEffect } from 'react';
import {
  ActivityIndicator,
  Image,
  Modal,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  Linking,
  View,
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Video } from 'expo-av';
import { useValidation } from 'react-native-form-validator';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Toast from 'react-native-toast-message';
import InView from 'react-native-component-inview';

import * as API from '../common/api.service';
import { Theme } from '../../theme';
import { translate, getCurrentLocale, setLocale as setCurrentLocale, LANGUAGE_LABELS } from '../../lang';
import { styles } from './styles';
import { AnimateService } from '../common/animate.service';
import { getColumnStyle } from '../common/utils.service';
import { Tile } from '../common/tile';
import { DatePickerComponent } from '../common/datepicker.component';

const GUEST_USER = {
  admin: false,
  guest: true,
  status: 'TEMPORARY'
};

const ServicesComponent = memo(({group, locale, setRef, onPress}) => {
  const [inView, setInView] = useState(false);
  return (
    <View style={[Theme.styles.webContainer, Theme.styles.webContainerExpanded, Theme.styles.column, Theme.styles.alignCenterCenter]} ref={setRef}>
      <InView onChange={visible => setInView(inView || visible)} />
      <View style={[Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.paddingTop52]}>
        <Text style={[Theme.styles.text, Theme.styles.fontSize40, Theme.styles.marginBottom10, Theme.styles.textTransformNone]}>{group.title?.[locale] || group.title}</Text>
        {group.subtitleWeb?.[locale] || group.subtitle?.[locale] || group.subtitle.length ? (
          <Text style={[Theme.styles.text, Theme.styles.textTransformNone, Theme.styles.textCenter]}>{group.subtitleWeb?.[locale] || group.subtitle?.[locale] || group.subtitle}</Text>
        ) : null}
      </View>
      <FlatList
        style={[Theme.styles.height, Theme.styles.width, Theme.styles.paddingTop32, Theme.styles.overflowHidden]}
        numColumns={Theme.XL ? 3 : 2}
        data={group.services}
        keyExtractor={(item, index) => index}
        renderItem={({ item, index }) => (
          <AnimateService
            key={index}
            properties={['opacity', 'left', 'top']}
            style={getColumnStyle(index, group.services.length, Theme.XL ? 3 : 2, Theme.XL ? 30 : 10)}
            delay={index * 150}
            startAnimation={inView}
            duration={500}
            Component={() => (
              <Tile
                media={item.media || item.services?.reduce((r, a) =>  r.concat(a.media), []) || []}
                minInterval={30}
                title={item.title[locale]}
                subtitle={Theme.XL && item.subtitle && item.subtitle[locale]}
                onPress={() => onPress(item)}
              />
            )}
          />
        )}
      />
    </View>
  );
});
ServicesComponent.displayName = 'ServicesComponent';

export const LoginComponent = ({ navigation, route, ctx }) => {
  const context = ctx[0];
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [credentialsError, setCredentialsError] = useState(false);
  const [locale, setLocale] = useState(getCurrentLocale());
  const [scrollView, setScrollView] = useState();
  const [islandView, setIslandView] = useState();
  const [stayView, setStayView] = useState();
  const [diningView, setDiningView] = useState();
  const [experiencesView, setExperiencesView] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const today = new Date().toISOString().split('T')[0];
  const [checkInDate, setCheckInDate] = useState(today);
  const [checkOutDate, setCheckOutDate] = useState(moment(today).add(2, 'days').format('YYYY-MM-DD'));
  const [guests, setGuests] = useState(2);

  useEffect(() => {
    const current = getCurrentLocale();
    if (current !== locale) setLocale(current);
  }, [getCurrentLocale()]);

  useEffect(() => {
    setLoading(false);
  }, [modalVisible]);

  const {
    validate,
    isFieldInError
  } = useValidation({ state: { userName, password }});

  const logIn = async () => {
    const validators = {
      userName: { required: true },
      password: { required: true }
    };
    if (process.env.PASSWORD_FIELD === 'EMAIL_ADDRESS') {
      validators.password.email = true;
    }
    if (validate(validators)) {
      setLoading(true);
      try {
        context.user = await API.connect({
          username: userName,
          password,
          locale,
        }).catch(() => setLoading(false), ctx, navigation);
        if (!context.user) throw new Error('Unknown error');
        await Promise.all([
          AsyncStorage.setItem('userName', userName),
          AsyncStorage.setItem('password', password),
          AsyncStorage.setItem('context.user', JSON.stringify(context.user)),
          proceed()
        ]);
      } catch(e) {
        setCredentialsError(true);
        return Toast.show({
          type: 'error',
          autoHide: false,
          text1: translate('ERROR_LOGGING_IN'),
          text2: translate('ERROR_LOGGING_IN_DESCRIPTION'),
        });
      }
    }
  };

  const updateLocale = async (value) => {
    if (value !== locale) {
      await setCurrentLocale(value);
      setLocale(value);
    }
  };

  const proceed = async () => {
    Toast.hide();
    setLoading(true);
    const cache = await AsyncStorage.getAllKeys();
    let cached = [];
    cache.forEach(key => {
      if (key.includes('hotel')) cached.push(AsyncStorage.getItem(key));
    });
    cached = await Promise.all(cached);
    if (cached) {
      cached.forEach(doc => {
        const document = JSON.parse(doc);
        context[document.key] = document;
      });
    }
    return API.connected.finally(async () => {
      if (context.needsUpdate) return navigation.navigate('Update');
      if (context.offline !== true) {
        const hotel = (await API.crud({
          operation: '_read',
          table: 'hotels',
          query: {}
        }));
        await Promise.all(hotel.map(document => {
          context[document.key] = document;
          return AsyncStorage.setItem(`context.hotel.${document.key}`, JSON.stringify(document));
        }));
        API.setContext({...context});
      }
      if (!context.hotelConfig) {
        setLoading(false);
        Toast.show({
          type: 'error',
          autoHide: false,
          text1: translate('ERROR_LOGGING_IN'),
          text2: translate('ERROR_LOGGING_IN_DATA_DESCRIPTION'),
        });
        throw new Error('Trying to proceed without cache nor a valid internet connection');
      }
      if (!context.user) {
        context.user = {
          ...GUEST_USER,
          locale,
          features: context.hotelConfig.data.features.guest.map(f => f.id)
        };
        API.setContext({ ...context });
      }
      setModalVisible(false);
      setLoading(false);
      return navigation.navigate('Main');
    });
  };

  useEffect(() => {
    if (route.params?.reload) setLoading(false);
    const load = async () => {
      const userName = await AsyncStorage.getItem('userName');
      const password = await AsyncStorage.getItem('password');
      const user = await AsyncStorage.getItem('context.user');
      if (user) {
        context.user = JSON.parse(user);
        const response = await Promise.all([
          API.connect({ username: userName, password, locale }),
          proceed(setLoading, Theme),
        ]).catch(() => {}); // proceed(setLoading, Theme, ref));
        if (response && response[0]) {
          context.user = response[0];
          AsyncStorage.setItem('context.user', JSON.stringify(response[0])).then(() => {});
        }
      } else if (userName && password) {
        const user = await API.connect({ username: userName, password, locale });
        user.password = password;
        AsyncStorage.setItem('context.user', JSON.stringify(user)).then(() => {});
        return await proceed(setLoading, Theme);
      } else {
        // Connecting as guest user to load data
        await API.connect({ locale });
        setReady(true);
      }
    };

    const loadHotel = async (ref) => {
      if (!API.context) await API.connect(ctx, navigation, ref).catch(() => {});
      const hotel = (await API.crud({
        operation: '_read',
        table: 'hotels',
        query: {}
      }));
      await Promise.all(hotel.map(document => {
        context[document.key] = document;
        return AsyncStorage.setItem(`context.hotel.${document.key}`, JSON.stringify(document));
      }));
      API.setContext({...context});
    };

    load().then(loadHotel);
  }, [route.params]);

  const checkAvailability = async () => {
    await proceed();
    if (checkInDate && checkOutDate && guests) {
      navigation.navigate('Main', {
        screen: 'Services',
        params: {
          screen: 'serviceServiceList',
          params: {
            type: 'service',
            group: rooms,
            //service: rooms.services.find(s => s.id === roomType),
            attendees: {
              adults: guests
            },
            startDate: checkInDate,
            endDate: checkOutDate
          }
        }
      });
    } else {
      // ERROR
    }
  };

  useFocusEffect(useCallback(() => {
    if (ready && context.offline === true) {
      const buttons = [{
        text: translate('OK'),
        onPress: Toast.hide
      }];
      if (process.env.ALLOW_GUESTS === 'true') buttons.push({
        text: translate('ENTER_AS_GUEST'),
        onPress: proceed
      });
      return Toast.show({
        type: 'action',
        autoHide: false,
        text1: translate('OFFLINE'),
        text2: translate('OFFLINE_DESCRIPTION'),
        props: {
          actions: buttons
        }
      });
    }
  }, [ready, context.offline]));

  const scrollTo = (view) => {
    if (view) {
      view.measure((x, y) => {
        scrollView.scrollTo({y, animated: true});
      });
    } else {
      scrollView.scrollTo({y: 0, animated: true});
    }
  };

  const rooms = context?.serviceGroups?.data?.find(s => s.id === 'room');
  const restaurant = context?.serviceGroups?.data?.find(s => s.id === 'restaurant');
  const activities = context?.activityGroups?.data;
  const info = context?.moreGroups?.data?.find(s => s.id === 'info');
  const contacts = info?.services?.find(s => s.id === 'contact')?.params?.keyValues || [];

  return (
    <View style={[Theme.styles.height, Theme.styles.alignStretchStart]}>
      <View style={[styles.header, Theme.styles.row, Theme.styles.alignCenterCenter]}>
        <View style={[Theme.styles.webContainer, Theme.styles.row, Theme.styles.alignCenterCenter]}>
          <TouchableOpacity onPress={() => scrollTo()}>
            <Image
              style={styles.logo}
              source={Theme.logoColor}
              width={100}
              height={100}
              resizeMode='contain'
            />
          </TouchableOpacity>
          { Theme.XL && context?.hotelConfig ? (
            <View style={[Theme.styles.flex, Theme.styles.row, Theme.styles.alignCenterEnd]}>
              <TouchableOpacity onPress={() => scrollTo(islandView)} style={Theme.styles.marginRight16}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>{ translate('THE_ISLAND') }</Text>
              </TouchableOpacity>
              { rooms ? (
                <TouchableOpacity onPress={() => scrollTo(stayView)} style={Theme.styles.marginRight16}>
                  <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>{ rooms.title[locale] }</Text>
                </TouchableOpacity>
              ) : null
              }
              { restaurant ? (
                <TouchableOpacity onPress={() => scrollTo(diningView)} style={Theme.styles.marginRight16}>
                  <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>{ restaurant.title[locale] }</Text>
                </TouchableOpacity>
              ) : null
              }
              { activities ? (
                <TouchableOpacity onPress={() => scrollTo(experiencesView)} style={Theme.styles.marginRight16}>
                  <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>{ translate('EXPERIENCES') }</Text>
                </TouchableOpacity>
              ) : null
              }
              {/*
              <TouchableOpacity onPress={() => scrollTo(moreView)} style={Theme.styles.marginRight16}>
                <Text style={[Theme.styles.text, Theme.styles.letterSpacing1]}>{ translate('MORE') }</Text>
              </TouchableOpacity>
              */}
              <View style={[Theme.styles.row, Theme.styles.marginLeft16]}>
                <MaterialIcons name='translate' size={20} color={Theme.styles.text.color} />
                <View style={Theme.styles.paddingLeft8}>
                  <RNPickerSelect
                    placeholder={{}}
                    value={locale}
                    onValueChange={updateLocale}
                    style={styles.selector}
                    items={process.env.LANGS.split(',').map(lang => ({
                      label: LANGUAGE_LABELS[lang],
                      value: lang
                    }))}
                  >
                  </RNPickerSelect>
                </View>
              </View>
            </View>
          ) : Theme.XL ? (
            <View style={[Theme.styles.flex, Theme.styles.row, Theme.styles.alignCenterEnd]}>
              <ActivityIndicator color={Theme.palette.primary} />
            </View>
          ) : (
            <View style={[Theme.styles.flex, Theme.styles.row, Theme.styles.marginLeft16, Theme.styles.alignCenterEnd]}>
              <MaterialIcons name='translate' size={20} color={Theme.styles.text.color} />
              <View style={Theme.styles.paddingLeft8}>
                <RNPickerSelect
                  placeholder={{}}
                  value={locale}
                  onValueChange={updateLocale}
                  style={styles.selector}
                  items={process.env.LANGS.split(',').map(lang => ({
                    label: LANGUAGE_LABELS[lang],
                    value: lang
                  }))}
                >
                </RNPickerSelect>
              </View>
            </View>
          )}
          <TouchableOpacity onPress={() => setModalVisible(true)} style={[Theme.styles.alignCenterCenter, Theme.styles.marginLeft16, styles.button, styles.reverseButton]} disabled={loading}>
            { loading ? (
              <ActivityIndicator color={Theme.palette.primary} />
            ) : (
              <Text style={[Theme.styles.text, Theme.styles.buttonText, styles.buttonText, styles.reverseButtonText]}>{translate('LOGIN')}</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
      <Modal
        animationType='fade'
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(!modalVisible)}
      >
        <View style={[Theme.styles.alignCenterCenter, styles.backdrop, Theme.styles.height]}>
          <View style={[styles.header, Theme.styles.padding30, Theme.styles.heightAuto, styles.modal]}>
            <Text style={[Theme.styles.text, Theme.styles.textDark, styles.title]}>{ process.env.APP_DISPLAY_NAME }</Text>
            <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.closeButton}>
              <MaterialCommunityIcons name='close' color={Theme.palette.dark} size={23} />
            </TouchableOpacity>
            <View style={[Theme.styles.column, Theme.styles.alignCenterCenter, styles.formWrapper]}>
              <View style={styles.loginFormContainer}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, styles.inputLabel, (credentialsError || isFieldInError('userName')) && Theme.styles.error]}>
                  { translate(process.env.USERNAME_FIELD || 'USER_NAME') }*
                </Text>
                <TextInput
                  style={[Theme.styles.input, styles.input, (credentialsError || isFieldInError('userName')) && Theme.styles.error]}
                  onChangeText={(userName) => { setUserName(userName); setCredentialsError(false); }}
                  value={userName}
                  placeholder='Smith'
                  placeholderTextColor={Theme.styles.text.color}
                />
                <Text style={[Theme.styles.text, Theme.styles.textDark, styles.inputLabel, (credentialsError || isFieldInError('password')) && Theme.styles.error]}>
                  { translate(process.env.PASSWORD_FIELD || 'PASSWORD') }*
                </Text>
                <TextInput
                  style={[Theme.styles.input, styles.input, (credentialsError || isFieldInError('password')) && Theme.styles.error]}
                  onChangeText={(password) => { setPassword(password); setCredentialsError(false); }}
                  value={password}
                  placeholder={process.env.PASSWORD_FIELD ? translate(process.env.PASSWORD_FIELD + '_PLACEHOLDER') : 'R250B'}
                  placeholderTextColor={Theme.styles.text.color}
                  secureTextEntry={process.env.PASSWORD_FIELD === 'PASSWORD'}
                />
                <TouchableOpacity onPress={logIn} style={[Theme.styles.alignCenterCenter, styles.button, credentialsError ? Theme.styles.buttonDisabled : null]} disabled={loading || credentialsError}>
                  { loading ? (
                    <ActivityIndicator color={Theme.palette.primaryContrast} />
                  ) : (
                    <Text style={[Theme.styles.text, Theme.styles.buttonText, styles.buttonText]}>{translate('LOGIN')}</Text>
                  )}
                </TouchableOpacity>
                { process.env.ALLOW_GUESTS === 'true' ? (
                  <View>
                    <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, styles.dividerContainer]}>
                      <View style={styles.divider} />
                      <Text style={styles.dividerText}>{translate('OR')}</Text>
                      <View style={styles.divider} />
                    </View>
                    <TouchableOpacity onPress={proceed} style={[Theme.styles.alignCenterCenter, styles.button, styles.reverseButton, loading ? Theme.styles.buttonDisabled : null]} disabled={loading}>
                      <Text style={[Theme.styles.text, Theme.styles.buttonText, styles.reverseButtonText]}>{translate('ENTER_AS_GUEST')}</Text>
                    </TouchableOpacity>
                  </View>
                ) : null
                }
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <ScrollView ref={setScrollView}>
        <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, styles.backgroundVideoContainer]}>
          <Video
            source={'' || {
              uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID, 'cover.mp4'].join('/')
            }}
            shouldPlay={true}
            style={styles.backgroundVideo}
            isLooping
            isMuted={true}
            resizeMode='cover'
            rate={1.0}
          />
          <View style={[Theme.styles.webContainer, Theme.styles.column, Theme.styles.alignCenterStart, styles.backgroundImageText]}>
            <Text style={[Theme.styles.text, Theme.styles.fontSize40, Theme.styles.marginBottom10, Theme.styles.textContrast, Theme.styles.textTransformNone]}>{ process.env.APP_DISPLAY_NAME }</Text>
            <Text style={[Theme.styles.text, Theme.styles.marginBottom10, Theme.styles.textContrast, Theme.styles.fontSize20, Theme.styles.textCenter]}>{translate('APP_SUBTITLE')}</Text>
          </View>
          <View>
          </View>
        </View>
        <View style={[Theme.styles.webContainer, Theme.styles.row, Theme.styles.alignCenterCenter, Theme.XL ? styles.checkAvailability : Theme.styles.borderBottom ]}>
          { Theme.XL ? (
            <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.flex]}>
              <View style={[Theme.styles.flex, Theme.styles.marginRight16]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, styles.inputLabel]}>
                  { translate('CHECK_IN') }*
                </Text>
                <DatePickerComponent minDate={today} onChange={setCheckInDate} value={checkInDate} />
              </View>
              <View style={[Theme.styles.flex, Theme.styles.marginRight16]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, styles.inputLabel]}>
                  { translate('CHECK_OUT') }*
                </Text>
                <DatePickerComponent minDate={checkInDate} onChange={setCheckOutDate} value={checkOutDate} />
              </View>
              {/*
              <View style={[Theme.styles.flex, Theme.styles.marginRight16]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, styles.inputLabel]}>
                  { translate('ROOM') }*
                </Text>
                <RNPickerSelect
                  placeholder={{}}
                  value={roomType}
                  onValueChange={setRoomType}
                  style={styles.formSelector}
                  items={rooms?.services?.map?.(s => ({
                    label: s.title?.[locale] || s.title?.en,
                    value: s.id
                  })) || []}
                >
                </RNPickerSelect>
              </View>
              */}
              <View style={[Theme.styles.flex, Theme.styles.marginRight16]}>
                <Text style={[Theme.styles.text, Theme.styles.textDark, styles.inputLabel]}>
                  { translate('NUMBER_OF_GUESTS') }*
                </Text>
                <TextInput
                  style={[Theme.styles.input, Theme.styles.marginBottom0]}
                  onChangeText={setGuests}
                  value={guests}
                  placeholder='2'
                  placeholderTextColor={Theme.styles.text.color}
                />
              </View>
            </View>
          ) : null }
          <TouchableOpacity onPress={checkAvailability} style={[Theme.styles.alignCenterCenter, styles.button, Theme.XL ? [Theme.styles.marginLeft16, Theme.styles.marginTop22] : Theme.styles.margin22]} disabled={loading || !rooms}>
            { loading || !rooms ? (
              <ActivityIndicator color={Theme.palette.primaryContrast} />
            ) : (
              <Text style={[Theme.styles.text, Theme.styles.buttonText, styles.buttonText]}>{translate('CHECK_AVAILABILITY')}</Text>
            )}
          </TouchableOpacity>
        </View>
        <View style={[Theme.styles.webContainer, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.paddingBottom32]} ref={setIslandView}>
          <View style={[Theme.styles.column, Theme.styles.alignCenterCenter]}>
            <Text style={[Theme.styles.text, Theme.styles.fontSize40, Theme.styles.marginBottom10, Theme.styles.textTransformNone]}>{context.hotelConfig?.data?.info?.island?.name}</Text>
            <Text style={[Theme.styles.text, Theme.styles.textTransformNone]}>{ context?.hotelConfig?.data?.info?.island?.subtitle }</Text>
          </View>
        </View>
        <View style={[Theme.styles.row, Theme.styles.alignCenterCenter]}>
          <View style={styles.backgroundImageContainer}>
            <Image
              style={styles.backgroundImage}
              source={Theme.XL ? Theme.islandBackground : Theme.islandBackgroundXS}
              width={Theme.width}
              height={Theme.height}
              resizeMode='cover'
            />
          </View>
          <View style={[Theme.styles.webContainer, Theme.styles.column, Theme.styles.alignCenterCenter, styles.backgroundImage]}>
            <View style={[Theme.XL ? Theme.styles.row : Theme.styles.column, Theme.styles.width, Theme.styles.flex, Theme.XL ? Theme.styles.alignCenterSpaceBetween : Theme.styles.alignCenterSpaceAround]}>
              <View style={[Theme.styles.flex, Theme.styles.row, Theme.styles.alignCenterCenter, styles.content]}>
                <Text style={[Theme.styles.text, Theme.styles.textContrast]}>{ context?.hotelConfig?.data?.info?.island?.textLeft }</Text>
              </View>
              <View style={[Theme.styles.flex, Theme.styles.row, Theme.styles.alignCenterCenter, styles.content]}>
                <Text style={[Theme.styles.text, Theme.styles.textContrast]}>{ context?.hotelConfig?.data?.info?.island?.textRight }</Text>
              </View>
            </View>
          </View>
        </View>
        { rooms ? (<ServicesComponent group={rooms} locale={locale} setRef={setStayView} onPress={() => setModalVisible(true)} />) : null }
        { restaurant ? (<ServicesComponent group={restaurant} locale={locale} setRef={setDiningView} onPress={() => setModalVisible(true)} />) : null }
        { activities ? (<ServicesComponent group={{ services: activities, title: translate('EXPERIENCES'), subtitle: translate('EXPERIENCES_SUBTITLE')}} locale={locale} setRef={setExperiencesView} style={styles.primaryBackgroundTransparency} onPress={() => setModalVisible(true)}/>) : null }
        <View style={styles.primaryBackground}>
          <View style={[Theme.styles.webContainer, Theme.XL ? Theme.styles.row : Theme.styles.column, Theme.styles.alignCenterCenter]}>
            <View>
              <Image
                style={styles.logoXL}
                source={Theme.logo}
                resizeMode='contain'
              />
            </View>
            <View style={[Theme.styles.column, Theme.styles.alignStartCenter]}>
              <View style={[Theme.styles.row, Theme.styles.padding10, Theme.styles.paddingLeft30]}>
                <MaterialCommunityIcons name='google-maps' color={Theme.palette.primaryContrast} size={23} />
                <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textContrast, Theme.styles.fontSize17, Theme.styles.paddingLeft10]}>{context.hotelConfig?.data?.info?.island?.address}</Text>
              </View>
              <View style={[Theme.styles.row, Theme.styles.padding10, Theme.styles.paddingLeft30]}>
                <MaterialCommunityIcons name='email' color={Theme.palette.primaryContrast} size={23} />
                <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textContrast, Theme.styles.fontSize17, Theme.styles.paddingLeft10]}>{contacts?.find(p => p.icon === 'email')?.value}</Text>
              </View>
              <View style={[Theme.styles.row, Theme.styles.padding10, Theme.styles.paddingLeft30]}>
                <MaterialCommunityIcons name='phone' color={Theme.palette.primaryContrast} size={23} />
                <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textContrast, Theme.styles.fontSize17, Theme.styles.paddingLeft10]}>{contacts?.find(p => p.icon === 'phone')?.value}</Text>
              </View>
            </View>
            <View style={Theme.styles.flex} />
            <View style={[Theme.styles.column, Theme.styles.alignCenterCenter, !Theme.XL ? [Theme.styles.width, Theme.styles.marginTop50] : null]}>
              <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textContrast, Theme.styles.fontSize17, Theme.styles.paddingLeft10, Theme.styles.paddingBottom10]}>{ translate('GET_THE_APP') }</Text>
              <View style={[Theme.XL ? Theme.styles.column : [Theme.styles.row, Theme.styles.width], Theme.styles.alignCenterSpaceAround]}>
                <TouchableOpacity onPress={() => Linking.openURL(`itms-apps://apps.apple.com/app/${process.env.APP_STORE_ID}`)}>
                  <Image
                    style={styles.storeLogo}
                    source={Theme.appStore}
                    resizeMode='contain'
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL(`http://play.google.com/store/apps/details?id=${process.env.BUNDLE_ID}`)}>
                  <Image
                    style={styles.storeLogo}
                    source={Theme.googlePlay}
                    resizeMode='contain'
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={[Theme.styles.webContainer, Theme.styles.webContainerExpanded, Theme.styles.row, Theme.styles.alignCenterCenter]}>
            <Text style={[Theme.styles.text, Theme.styles.textTransformNone, Theme.styles.textCenter, Theme.styles.textContrast]}>
              { process.env.APP_DISPLAY_NAME } { new Date().getFullYear() }, Developed with ♥ by Guest Tours Ltd
            </Text>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
