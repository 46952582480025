import moment from 'moment';
import React, { useLayoutEffect, useContext, useEffect, useState } from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { HeaderBackButton } from '@react-navigation/elements';
import Carousel from 'react-native-snap-carousel';
import StarRating from 'react-native-star-rating-widget';

import { Theme } from '../../theme';
import { translate, getCurrentLocale } from '../../lang';
import * as API from '../common/api.service';
import { fString, getDurationString, getPlanPrice, getPriceString, getNextRoute } from '../common/utils.service';
import { CarouselItem } from '../common/tile';
import { HeaderComponent } from '../common/header.component';
import { SelectorComponent } from '../common/selector.component';
import { ButtonComponent } from '../common/button.component';
import { ListItemComponent } from '../common/list-item.component';
import { Context } from '../Context';

const styles = StyleSheet.create({
  reviewsContainer: {
    backgroundColor: Theme.XL ? 'transparent' : 'white',
    paddingHorizontal: 0,
    paddingBottom: 6,
  },
  reviewsContainerMobile: {
    marginHorizontal: -8,
    width: Theme.width,
    paddingHorizontal: 16,
    marginBottom: -8,
    paddingBottom: 16
  },
  reviewsContent: {
    paddingHorizontal: 8
  },
  reviewsSummaryContainer: {
    paddingBottom: 16,
    marginBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.background
  },
  reviewsListItemComponent: {
    borderBottomWidth: 0,
    padding: 0,
    height: 50
  },
  reviewsDivider: {
    marginVertical: 16,
    width: '20%',
    height: 1,
    backgroundColor: Theme.palette.background,
    alignSelf: 'center'
  },
  responseContainer: {
    marginBottom: 10,
    paddingLeft: 16,
    marginLeft: 16,
    borderLeftWidth: 1,
    borderLeftColor: Theme.palette.background
  },
  starStyle: {
    marginHorizontal: .5
  },
  headerLeftButton: {
    marginLeft: -15
  },
  title: {
    fontSize: 30,
    color: Theme.palette.primary
  }
});

const CONTAINER_PADDING = Theme.XL ? 64 : 0;
const carouselWidth = Math.min(Theme.width - CONTAINER_PADDING, 1200 - CONTAINER_PADDING);

export const DetailComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const { group, service, plan, type } = route.params;
  const [selected, setSelected] = useState((service && service.plans || []).findIndex(p => p.id === plan));
  const [reviews, setReviews] = useState({ list: [], total: 0 });
  const locale = getCurrentLocale();

  const selectPlan = () => {
    const plan = service.plans[selected];
    const nextRoute = getNextRoute('ServiceDetail', { ...service, ...plan });
    return navigation.navigate(`${type}${nextRoute}`, {
      ...route.params,
      plan
    });
  };

  useEffect(() => {
    const loadReviews = async() => {
      const result = await API.crud({
        operation: '_read',
        table: 'reviews',
        query: {
          hotelService: [type, group.id, service.id].join('#'), // hotelId will be added by the backend
          // TODO ADD SORTING AND INDEX USAGE HEREE
          // TODO ADD SORTING AND INDEX USAGE HEREE
          // TODO ADD SORTING AND INDEX USAGE HEREE
        }
      });
      const rev = {
        rating: 0,
        list: [],
        total: result?.length || 0
      };
      result?.forEach?.(review => {
        rev.rating += review.rating / rev.total;
        rev.list.push(review);
      });
      setReviews(rev);
    };
    loadReviews();
  }, []);

  useLayoutEffect(() => {
    if (route.params.navigateTo === 'MyBookings' || (route.params.id && route.params.navigateTo === 'GuestDetail')) {
      navigation.setOptions({
        headerLeft: () => (
          <HeaderBackButton
            style={styles.headerLeftButton}
            label={translate(route.params.navigateTo.toUpperCase() + '_' + context.user.admin)}
            labelVisible={true}
            tintColor={Theme.palette.primaryContrast}
            onPress={navigation.goBack}
          />
        )
      });
    }
  }, [navigation, route]);

  return (
    <View style={[Theme.styles.height, Theme.styles.column, Theme.styles.alignStretchCenter, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
      { !Theme.XL ? (
        <HeaderComponent title={service.title[locale]} subtitle={getPriceString(service.plans[selected] && getPlanPrice(service.plans[selected]))} />
      ) : null}
      <ScrollView style={Theme.styles.flex} contentContainerStyle={Theme.XL ? Theme.styles.padding32 : null}>
        <Carousel
          data={service.media}
          renderItem={(renderProps) => <CarouselItem {...renderProps} />}
          sliderWidth={carouselWidth}
          itemWidth={carouselWidth}
          loop={true}
        />
        <View style={[Theme.styles.row, Theme.styles.width, Theme.styles.alignStartStart, Theme.XL ? Theme.styles.paddingTop32 : Theme.styles.padding8]}>
          <View style={[Theme.styles.flex, Theme.styles.alignStartStart]}>
            { Theme.XL ? (
              <Text style={[Theme.styles.text, Theme.styles.textBold, Theme.styles.paddingBottom16, styles.title]}>
                { service.title[locale] }
              </Text>
            ) : (
              <View style={[Theme.styles.container, Theme.styles.width, Theme.styles.alignStartStretch]}>
                <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.paddingBottom8]}>
                  { translate('PLANS') }
                </Text>
                { service.plans.map((plan, index) =>
                  <SelectorComponent
                    key={index}
                    onPress={() => setSelected(index)}
                    selected={selected === index}
                    titleIcon="timer"
                    title={fString(plan.title[locale], plan)}
                    subtitle={(plan.subtitle && (plan.subtitle[locale] && fString(plan.subtitle[locale], plan) + (plan.duration && ' | ' || '')) || '') + (getDurationString(plan.duration, plan.dateRange && plan.dateRangeUnit === 'night' ? 'NIGHT(S)' : null) || '')}

                    value={getPriceString(getPlanPrice(plan))}
                  />
                )}
              </View>
            )}
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textDark, !Theme.XL ? [Theme.styles.container, Theme.styles.paddingTop0] : null]}>
              { service.plans[selected]?.description && service.plans[selected].description[locale] || service.description && service.description[locale] || '' }
            </Text>
            <View style={[styles.reviewsContainer, Theme.styles.paddingTop16, Theme.styles.width, !Theme.XL ? styles.reviewsContainerMobile : null]}>
              <View style={[Theme.styles.row, Theme.styles.alignStartStart, Theme.styles.paddingBottom8]}>
                <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.paddingBottom16, Theme.XL ? Theme.styles.fontSize25 : Theme.styles.textBold]}>
                  { translate('REVIEW_SUMMARY') }
                </Text>
              </View>
              { reviews.list.length ? (
                <View>
                  <View style={[Theme.styles.column, Theme.styles.alignCenterCenter, styles.reviewsSummaryContainer]}>
                    <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.flex, Theme.styles.fontSize40]}>
                      {reviews.rating.toFixed(1)}
                    </Text>
                    <View style={[Theme.styles.paddingTop4, Theme.styles.paddingBottom8]}>
                      <StarRating
                        starSize={25}
                        color={Theme.palette.accent}
                        rating={reviews.rating}
                        onChange={() => {}}
                        animationConfig={{
                          scale: 1
                        }}
                      />
                    </View>
                    <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.flex]}>
                      { translate('TOTAL_REVIEWS', { ...reviews }) }
                    </Text>
                  </View>
                  <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.paddingBottom8]}>
                    <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.flex, Theme.styles.textBold, styles.reviewsContent]}>
                      { translate('REVIEWS') }
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={[Theme.styles.padding16, Theme.styles.flex, Theme.styles.backgroundLight, Theme.styles.alignCenterCenter, Theme.styles.column]}>
                  <View>
                    <StarRating
                      starSize={25}
                      color={Theme.palette.text}
                      rating={5}
                      onChange={() => {}}
                      animationConfig={{
                        scale: 1
                      }}
                    />
                  </View>
                  <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.flex, Theme.styles.padding16, Theme.styles.paddingBottom0]}>
                    { translate('NO_REVIEWS_YET') }
                  </Text>
                </View>
              )}
              { reviews.list.map((review, idx) => (
                <View key={idx}>
                  <View style={[Theme.styles.paddingBottom8, styles.reviewsContent]}>
                    <ListItemComponent
                      style={{ container: styles.reviewsListItemComponent, title: Theme.styles.description, subtitle: Theme.styles.description }}
                      title={review.fullName}
                      subtitle={ moment(review.date).format('ll') }
                      image={review.image ? {
                        uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID.replace('.admin', '') + (context.ref ? `.${context.ref}` : ''), review.image].join('/')
                      } : require('../../theme/assets/ProfilePlaceholder.png')}
                      ActionComponent={() => (
                        <View style={Theme.styles.row}>
                          <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.paddingRight5]}>{ review.rating }</Text>
                          <StarRating
                            starSize={15}
                            starStyle={styles.starStyle}
                            color={Theme.palette.accent}
                            rating={review.rating}
                            onChange={() => {}}
                            animationConfig={{
                              scale: 1
                            }}
                          />
                        </View>
                      )}
                    />
                    <View style={[Theme.styles.column, Theme.styles.alignStartCenter]}>
                      <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.flex, Theme.styles.textBold, Theme.styles.paddingTop8, Theme.styles.letterSpacing1]}>
                        { review.title }
                      </Text>
                      <Text style={[Theme.styles.paddingTop5, Theme.styles.text, Theme.styles.description, Theme.styles.textDark]}>
                        { review.comment }
                      </Text>
                    </View>
                  </View>
                  { review.response && review.response.comment ? (
                    <View style={styles.responseContainer}>
                      <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.flex, Theme.styles.textBold, Theme.styles.paddingTop8, Theme.styles.letterSpacing1]}>
                        { translate('RESPONSE_FROM_THE_OWNER') }
                      </Text>
                      <Text style={[Theme.styles.text, Theme.styles.flex, Theme.styles.letterSpacing1, Theme.styles.fontSize12]}>
                        { moment(review.response.date).format('ll') }
                      </Text>
                      <Text style={[Theme.styles.paddingTop5, Theme.styles.text, Theme.styles.description, Theme.styles.textDark]}>
                        { review.response.comment }
                      </Text>
                    </View>
                  ) : null
                  }
                  { idx < reviews.total - 1 ? (
                    <View style={styles.reviewsDivider} />
                  ) : null
                  }
                </View>
              ))}
            </View>
          </View>
          { Theme.XL ? (
            <View style={Theme.styles.padding32} />
          ) : null}
          { Theme.XL ? (
            <View style={[Theme.styles.flex, Theme.styles.alignStartStretch]}>
              <Text style={[Theme.styles.description, Theme.styles.textDark, Theme.styles.paddingBottom16, Theme.styles.fontSize25]}>
                { translate('PLANS') }
              </Text>
              { service.plans.map((plan, index) =>
                <SelectorComponent
                  key={index}
                  onPress={() => setSelected(index)}
                  selected={selected === index}
                  titleIcon="timer"
                  title={plan.title[locale]}
                  subtitle={(plan.subtitle && (plan.subtitle[locale] && fString(plan.subtitle[locale], plan) + (plan.duration && ' | ' || '')) || '') + (getDurationString(plan.duration) || '')}

                  value={getPriceString(getPlanPrice(plan))}
                />
              )}
              <View style={Theme.styles.padding8} />
            </View>
          ) : null}
        </View>
      </ScrollView>
      <View style={Theme.styles.actionButtonBorder}>
        <View style={[Theme.styles.width, Theme.styles.actionButtonContainer, Theme.XL ? [Theme.styles.padding16, Theme.styles.paddingRight32] : null]}>
          <ButtonComponent onPress={selectPlan} text={translate('NEXT')} disabled={selected == -1} />
        </View>
      </View>
    </View>
  );
};
