import moment from 'moment/min/moment-with-locales';
import React, { useEffect, useState, useContext } from 'react';
import {
  ActivityIndicator,
  View,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as API from '../common/api.service';
import { translate, getCurrentLocale } from '../../lang';
import { getBookingContext, getDateStringWithoutYear } from '../common/utils.service';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { BookingsListComponent } from './list';
import { BookingsFilterComponent } from './filter';

export const MyBookingsComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const isFocused = useIsFocused();
  const [bookings, setBookings] = useState([]);
  const [filter, setFilter] = useState(context.user.admin ? 'activity' : '');
  const [filteredBookings, setFilteredBookings] = useState(0);
  const [ today ] = new Date().toISOString().split('T');
  const locale = getCurrentLocale();

  useEffect(() => {
    getBookings();
  }, [filter]);

  const getBookings = async () => {
    let reservations = context?.user?.bookings || [];
    if (context.offline) {
      setFilteredBookings(0);
    } else {
      let bookingsQuery = {
        operation: '_read',
        table: 'bookings',
        query: filter ? {
          id: {
            ComparisonOperator: 'BEGINS_WITH',
            AttributeValueList: [filter]
          }
        } : {},
        filter: {
          dynamoDB: {
            FilterExpression: '#p <> :t OR (#p = :t AND #d >= :d)',
            ExpressionAttributeNames: {
              '#p': 'public',
              '#d': 'startDate',
            },
            ExpressionAttributeValues: {
              ':t': true,
              ':d': today,
            },
          }
        },
        sort: 'ASC',
        sortKey: 'startDate',
      };
      if (context.user.admin) {
        reservations = (await API.crud(bookingsQuery).catch(() => {})) || [];
      } else {
        bookingsQuery.filter.dynamoDB.FilterExpression = '#p = :t AND #d >= :d';
        reservations = (await API.crud(bookingsQuery).catch(() => {})) || [];
        delete bookingsQuery.filter;
        bookingsQuery.index = 'byAccountId';
        bookingsQuery.query.accountId = {
          ComparisonOperator: 'EQ',
          AttributeValueList: [context?.user?.id || 'all']
        };
        reservations = ((await API.crud(bookingsQuery).catch(() => {})) || []).concat(reservations).sort((a, b) => a.startDate > b.startDate ? 1 : -1);
      }

      if (!filter) {
        context.user.bookings = reservations;
        AsyncStorage.setItem('context.user', JSON.stringify(context.user));
      }

      setFilteredBookings((context?.user?.bookings?.length || 0) - (reservations?.length || 0));
    }
    let todaySectionIndex = 0;

    let reminders = [];
    const updatedBookings = reservations.reduce((agg, current) => {
      if (agg?.[agg.length - 1]?.date === current.startDate) {
        agg[agg.length - 1].data.push(current);
      } else {
        agg.push({
          date: current.startDate,
          data: [current]
        });
      }
      if (current.startDate < today) {
        todaySectionIndex++;
      } else {
        if (!current.public || current.bookings?.[context?.user?.id] ) {
          const ms = new Date(current.startDate + 'T' + (current.startTime?.split('T')[1] || '09:00:00.000Z')) - new Date();
          const hour = 3600 * 1000;
          const oneDayBefore = ms - (24 * hour);
          const oneHourBefore = ms - hour;
          const bookingContext = getBookingContext(current, context);
          const params = {
            status: translate(current.status),
            startDate: getDateStringWithoutYear(current.startDate),
            startTime: moment.utc(current.startTime).format('hh:mm a')
          };
          if (oneDayBefore > 0) {
            reminders.push({
              content: {
                title: translate('BOOKING_REMINDER_TITLE_1D', params),
                subtitle: bookingContext.plan?.title?.[locale] || bookingContext.service?.title?.[locale],
                body: translate('BOOKING_REMINDER_BODY_1D', params),
                data: current,
              },
              trigger: {
                seconds: parseInt((oneDayBefore/ 1000).toFixed(0))
              }
            });
          }
          if (oneHourBefore > 0 && current.startTime) {
            reminders.push({
              content: {
                title: translate('BOOKING_REMINDER_TITLE_1H', params),
                subtitle: bookingContext.plan?.title?.[locale] || bookingContext.service?.title?.[locale],
                body: translate('BOOKING_REMINDER_BODY_1H', params),
                data: current,
              },
              trigger: {
                seconds: parseInt((oneHourBefore/ 1000).toFixed(0))
              }
            });
          }
        }
      }
      return agg;
    }, []);
    Notifications.cancelAllScheduledNotificationsAsync().then(() =>
      Promise.all(reminders.map((reminder) => Notifications.scheduleNotificationAsync(reminder)))
    ).catch(e => console.error('Error scheduling reminders', e));
    setBookings(updatedBookings);
    return {
      sectionIndex: todaySectionIndex,
      bookings: updatedBookings
    };
  };

  const onBookingUpdated = (item) => {
    setBookings(bookings.map(booking => {
      if (booking.id === item.id) {
        return item;
      } else {
        return booking;
      }
    }));
  };

  return (
    <View style={Theme.styles.flex}>
      <BookingsListComponent navigation={navigation} route={route} bookings={bookings} onBookingUpdated={onBookingUpdated} getBookings={getBookings} />
      { isFocused ? (
        <BookingsFilterComponent navigation={navigation} onChange={setFilter} filteredBookings={filteredBookings} />
      ) : (
        <ActivityIndicator color={Theme.palette.primaryContrast} />
      )}
    </View>
  );
};

