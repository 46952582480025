import React from 'react';
import {
  Text,
  View
} from 'react-native';
import RNPickerSelect from 'react-native-picker-select';

import { Theme } from '../../../../theme';
import { styles } from './styles';

export const Picker = ({children, label, icon, hasIcon, ...props}) => {
  if (!props.style) delete props.style;
  return (
    <View style={[Theme.styles.row, Theme.styles.pickerInput]}>
      { icon }
      <View style={hasIcon ? Theme.styles.paddingLeft26 : icon ? Theme.styles.paddingLeft16 : null}>
        <Text style={[Theme.styles.text, Theme.styles.paddingBottom5]}>{label}</Text>
        <RNPickerSelect style={styles.selector} {...props}>{ children }</RNPickerSelect>
      </View>
    </View>
  );
};
