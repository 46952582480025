import React from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';

import { Theme } from '../../theme';
import { translate, getCurrentLocale } from '../../lang';
import { getOrderPrice, getPriceString, getDiscountPrice } from '../common/utils.service';

const styles = StyleSheet.create({
  bordered: {
    borderBottomWidth: 0.5,
    borderBottomColor: Theme.palette.background,
  }
});

export const OrderSummaryComponent = ({ order }) => {
  const locale = getCurrentLocale();

  return (
    <View style={Theme.styles.flex}>
      <View style={[Theme.styles.row, Theme.styles.alignStartStart, Theme.styles.padding8]}>
        <Text style={[Theme.styles.flex, Theme.styles.text, Theme.styles.description, Theme.styles.textDark]}>
          { translate('ITEM') }
        </Text>
        <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textDark]}>
          { translate('UNIT_PRICE') }
        </Text>
        <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textDark, Theme.styles.textRight, Theme.styles.width70]}>
          { translate('PRICE') }
        </Text>
      </View>
      { order.map(item => (
        <View key={item.id} style={styles.bordered}>
          <View style={[Theme.styles.row, Theme.styles.alignStartStart, Theme.styles.padding8]}>
            <Text style={[Theme.styles.flex, Theme.styles.text, Theme.styles.description]}>
              { item.amount } x  { item.title[locale] }
            </Text>
            <Text style={[Theme.styles.text, Theme.styles.description]}>
              { getPriceString(item.price) }
            </Text>
            <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textRight, Theme.styles.width70]}>
              { getPriceString(item.price * item.amount) }
            </Text>
          </View>
          { Object.values(item.extras || {}).map((extra, idx) => (
            <View key={idx} style={[Theme.styles.row, Theme.styles.alignStartStart, Theme.styles.padding8, Theme.styles.paddingLeft20, Theme.styles.paddingTop0]}>
              <Text style={[Theme.styles.flex, Theme.styles.text, Theme.styles.description]}>
                  -  { extra.label }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.description]}>
                { getPriceString(extra.extraCharge) }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textRight, Theme.styles.width70]}>
                { getPriceString(extra.extraCharge* item.amount) }
              </Text>
            </View>
          ))}
          { item.planRate?.amount ? (
            <View style={[Theme.styles.row, Theme.styles.alignStartStart, Theme.styles.padding8, Theme.styles.paddingLeft20, Theme.styles.paddingTop0]}>
              <Text style={[Theme.styles.flex, Theme.styles.text, Theme.styles.description]}>
                { item.planRate.amount } x { translate('APPLIED_DISCOUNT') }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.description]}>
                { getDiscountPrice(item.price, item.planRate) }
              </Text>
              <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textRight, Theme.styles.width70]}>
                { getDiscountPrice(item.price, item.planRate, item.planRate.amount) }
              </Text>
            </View>
          ) : null }
        </View>
      ))}
      <View style={[Theme.styles.row, Theme.styles.alignStartStart, Theme.styles.padding8]}>
        <Text style={[Theme.styles.flex, Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
          { translate('TOTAL') }
        </Text>
        <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textBold]}>
          { getOrderPrice(order) }
        </Text>
      </View>
    </View>
  );
};


