import React, { useContext } from 'react';
import {
  FlatList,
  Platform,
  View,
} from 'react-native';

import { Context } from '../Context';
import { Theme } from '../../theme';
import { translate } from '../../lang';
import { Tile } from '../common/tile';
import { AnimateService } from '../common/animate.service';
import { HeaderComponent } from '../common/header.component';
import { getColumnStyle } from '../common/utils.service';

export const ListComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const sections = [{
    id: 'content',
    title: translate('CONTENT'),
    media: [{
      type: 'image',
      source: require('../../theme/assets/ContentTile.png')
    }]
  }, {
    id: 'resources',
    title: translate('RESOURCES'),
    media: [{
      type: 'image',
      source: require('../../theme/assets/GenericResourceTile.png')
    }]
  }, {
    id: 'info',
    title: translate('GENERAL_INFO'),
    disabled: true,
    media: [{
      type: 'image',
      source: require('../../theme/assets/GenericInfoTile.png')
    }]
  }];
  const openSection = (section) => {
    //context.navigation.title = section.title;
    switch (section.id) {
    case 'content':
      navigation.navigate('ContentList', {
        ...route.params,
      });
      break;
    case 'resources':
      navigation.navigate('ConfigResourcesList', {
        ...route.params,
      });
      break;
    }
  };
  return (
    <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded] : null]}>
      { Theme.XL ? (
        <View style={Theme.styles.paddingLeft16}>
          <HeaderComponent title={translate('CONTENT')} />
        </View>
      ) : null}
      <FlatList
        data={sections}
        style={[Theme.styles.height, Theme.XL ? [Theme.styles.padding32, Theme.styles.paddingTop16] : null]}
        numColumns={Theme.numberOfColumns}
        keyExtractor={item => item.id}
        renderItem={({ item, index }) => (
          <AnimateService
            properties={['opacity', 'left', 'top']}
            style={getColumnStyle(index, sections.length, Theme.numberOfColumns, Theme.XL ? 32 : null)}
            delay={index * 50}
            duration={500}
            Component={() => (
              <Tile
                disabled={item.disabled}
                style={{ media: { height: (Theme.height / 3) - (Platform.OS !== 'web' ? 55 : 166 / 3)} }}
                media={item.media}
                title={item.title}
                onPress={() => openSection(item)}
              />
            )}
          />
        )}
      />
    </View>
  );
};

