import React from 'react';
import { Platform } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { translate } from '../../lang';
import { MoreListComponent } from './list';
import { AccountComponent } from './account';
import { TermsComponent } from './terms';
import { tabScreenOptions } from '../common/utils.service';
import { WebViewComponent } from '../common/templates/webview';
import { KeyValuesComponent } from '../common/templates/key-values';

const Stack = createNativeStackNavigator();

export const MoreComponent = () => {
  return (
    <Stack.Navigator
      screenOptions={tabScreenOptions[Platform.OS] || tabScreenOptions.mobile}
    >
      <Stack.Screen
        name="List"
        component={MoreListComponent}
        options={{ title: translate('MORE') }}
      />
      <Stack.Screen
        name="Account"
        component={AccountComponent}
        options={{
          title: translate('ACCOUNT'),
          headerShadowVisible: false
        }}
      />
      <Stack.Screen
        name="Terms"
        component={TermsComponent}
        options={{ title: translate('TERMS_AND_CONDITIONS') }}
      />
      <Stack.Screen
        name="MoreKeyValues"
        component={KeyValuesComponent}
      />
      <Stack.Screen
        name="MoreWebview"
        component={WebViewComponent}
      />
    </Stack.Navigator>
  );
};

