import React, { useLayoutEffect } from 'react';
import { TouchableOpacity, Platform, StyleSheet, ScrollView, Text, View } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { getCurrentLocale } from '../../../lang';
import { openLink } from '../utils.service';

import { Theme } from '../../../theme';
import { HeaderComponent } from '../header.component';

const styles = StyleSheet.create({
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.background,
    marginBottom: 16
  }
});

const links = {
  whatsapp: {
    web: 'https://wa.me/${value}',
    ios: 'whatsapp://send?phone=${value}',
    android: 'whatsapp://send?phone=${value}',
  },
  instagram: {
    web: 'https://www.instagram.com/${value}',
    ios: 'instagram://user?username=${value}',
    android: 'instagram://user?username=${value}'
  },
  twitter: {
    web: 'https://www.twitter.com/${value}',
    ios: 'twitter://user?screen_name=${value}',
    android: 'twitter://user?screen_name=${value}',
  },
  facebook: {
    web: 'https://facebook.com/${value}',
    ios: 'fb://page/${value}',
    android: 'fb://page/${value}',
  },
  email: 'mailto:${value}',
  phone: 'tel:${value}',
  sms: 'sms:${value}',
  website: 'https://${value}'
};

export const KeyValuesComponent = ({ navigation, route }) => {
  const { title, keyValues } = route.params;

  const locale = getCurrentLocale();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: title[locale] || title.en || title
    });
  }, [navigation, route]);

  const renderKeyValue = ({key, value, icon, divider}, idx) => {
    let linkKey = (key?.en || key)?.toLowerCase().trim().replace(/ /g, '');
    let valueKey;
    if (linkKey in links) {
      valueKey = value.toLowerCase().trim().replace(/ /g, '');
      if (linkKey === 'whatsapp') valueKey = valueKey.replace(/\+|-/g, '');
      if (['sms', 'phone'].includes(linkKey)) valueKey = valueKey.replace(/-/g, '');
      linkKey = (links[linkKey][Platform.OS] || links[linkKey]).replace('${value}', valueKey);
    }
    return (
      <TouchableOpacity key={idx} style={[Theme.styles.width, Theme.styles.row, Theme.styles.alignCenterStart, divider ? styles.divider : null]} disabled={!valueKey} onPress={() => openLink(linkKey)}>
        { icon ? (
          <MaterialCommunityIcons name={icon} color={Theme.palette.text} size={30} style={[Theme.styles.paddingRight10, Theme.styles.paddingBottom16]} />
        ) : null
        }
        <View styles={Theme.styles.flex}>
          { key ? (
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description, Theme.styles.paddingBottom5, Theme.styles.textBold]}>
              { key[locale] || key.en || key }
            </Text>
          ) : null
          }
          { value ? (
            <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.description, Theme.styles.paddingBottom16]}>
              { value[locale] || value.en || value }
            </Text>
          ) : null
          }
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <ScrollView contentContainerStyle={[Theme.styles.column, Theme.styles.alignStartStart, Theme.styles.padding16, Theme.XL ? {...Theme.styles.webContainer, ...Theme.styles.webContainerExpanded } : null]}>
      <View style={Theme.XL ? Theme.styles.paddingLeft16 : null}>
        { Theme.XL ? (
          <HeaderComponent title={title[locale] || title.en || title} />
        ) : null}
        <View style={Theme.XL ? Theme.styles.paddingHorizontal16 : null}>
          { keyValues.map(renderKeyValue) }
        </View>
      </View>
    </ScrollView>
  );
};
