import React from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';

import { Theme } from '../../theme';
import Image from './libs/image/image';

const styles = StyleSheet.create({
  container: {
    height: 72,
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.background,
    backgroundColor: Theme.palette.modeBackgroundColor,
  },
  headerContainer: {
    height: 102,
    paddingVertical: 32,
    paddingHorizontal: 16,
    borderBottomWidth: 0,
    backgroundColor: Theme.palette.primary
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 100,
    marginRight: 16
  },
  headerImage: {
    height: 54,
    width: 54
  },
  headerTextContainer: {
    height: 44,
  },
  title: {
    fontSize: 16,
    width: '100%',
    paddingRight: 16,
  },
  headerTitle: {
    color: Theme.palette.primaryContrast
  },
  subtitle: {
    fontSize: 12,
    opacity: .75,
    width: '100%',
    paddingRight: 16,
  },
  headerSubtitle: {
    color: Theme.palette.primaryContrast
  },
});

export const ListItemComponent = ({ title, subtitle, image, ActionComponent, header, style: customStyles = {} }) => {
  return (
    <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.width, styles.container, header ? styles.headerContainer : null, customStyles?.container ]}>
      { image ? (
        <Image
          style={[styles.image, header ? styles.headerImage : null, customStyles?.image ]}
          source={image}
        />
      ) : null}
      <View style={[Theme.styles.column, Theme.styles.flex, Theme.styles.alignStartSpaceBetween, header ? styles.headerTextContainer : null]}>
        <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textDark, styles.title, header ? styles.headerTitle : null, customStyles?.title]} numberOfLines={1}>{ title }</Text>
        <Text style={[Theme.styles.text, Theme.styles.letterSpacing1, Theme.styles.textDark, styles.subtitle, header ? styles.headerSubtitle : null, customStyles?.subtitle]}>{ subtitle }</Text>
      </View>
      { ActionComponent ? <ActionComponent /> : null }
    </View>
  );
};
