import { StyleSheet } from 'react-native';

import { Theme } from '../../theme';

export const styles = StyleSheet.create({
  backgroundVideoContainer: {
    height: Theme.height - 150,
  },
  backgroundVideo: {
    height: Theme.height - 150,
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'stretch',
    bottom: 0,
    right: 0
  },
  backgroundImageContainer: {
    position: 'absolute',
    top: 0,
  },
  backgroundImage: {
    width: Theme.width,
    height: 640,
  },
  backgroundImageText: {
    width: Theme.width,
    height: 'auto',
    padding: 16,
    paddingBottom: Theme.XL ? 90 : 16
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, .6)',
  },
  content: {
    backgroundColor: 'rgba(0, 0, 0, .6)',
    ...(Theme.XL ? {
      marginLeft: -20,
      marginRight: -20,
      padding: 40,
      minHeight: 250,
      maxWidth: 500
    } : {
      maxHeight: 200,
      padding: 16
    })
  },
  langSelector: {
    inputWeb: {
      ...Theme.styles.inputWeb,
      fontSize: 24,
      alignSelf: 'end',
      color: Theme.palette.primaryContrast,
      marginRight: 32
    }
  },
  langSelectorContainer: {
    marginTop: 50,
    paddingRight: 20,
    width: Theme.width
  },
  langSelectorText: {
    paddingRight: 16,
    color: Theme.palette.darkContrast,
    fontSize: 20
  },
  langSelectorIcon: {
    top: 53,
    right: 16,
    color: Theme.palette.darkContrast
  },
  logo: {
    width: 100,
    height: 100
  },
  logoXL: {
    width: 200,
    height: 200
  },
  title: {
    textAlign: 'center',
    marginBottom: 5,
    fontSize: 30,
    fontWeight: 'bold',
  },
  formWrapper: {
    marginTop: 60,
  },
  loginFormContainer: {
    width: 300,
    maxWidth: '100%'
  },
  inputLabel: {
    width: '100%',
    letterSpacing: 1,
    marginBottom: 6
  },
  input: {
    width: '100%',
    color: Theme.palette.dark,
    borderColor: Theme.palette.dark,
  },
  inputFieldError: {
    width: 250
  },
  button: {
    padding: 15,
    minWidth: 194,
    backgroundColor: Theme.palette.primary,
    color: Theme.palette.primaryContrast,
    borderRadius: 6,
    height: 40
  },
  reverseButton: {
    minWidth: 120,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Theme.palette.primary,
  },
  reverseButtonText: {
    color: Theme.palette.primary
  },
  dividerContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 32,
    paddingRight: 32
  },
  divider: {
    flex: 1,
    height: .5,
    backgroundColor: Theme.palette.dark
  },
  dividerText: {
    textTransform: 'uppercase',
    color: Theme.palette.dark,
    fontSize: 12,
    marginRight: 36,
    marginLeft: 36
  },
  header: {
    backgroundColor: Theme.palette.primaryContrast,
    height: 100,
    boxShadow: '0 0 27px 0 rgb(0 0 0 / 23%)',
    zIndex: 1
  },
  buttonText: {
    color: Theme.palette.primaryContrast,
  },
  primaryBackground: {
    backgroundColor: Theme.palette.primary
  },
  primaryBackgroundTransparency: {
    backgroundColor: Theme.palette.primary + '26' // 15% opacity
  },
  storeLogo: {
    width: 150,
    height: 60
  },
  closeButton: {
    position: 'absolute',
    right: Theme.XL ? 16 : 66,
    top: 16
  },
  modal: {
    minWidth: 500
  },
  checkAvailability: {
    shadowColor: Theme.palette.backgroundDark,
    shadowOffset: { width: 2, height: 4 },
    shadowOpacity: 0.8,
    shadowRadius: 3,
    padding: 32,
    backgroundColor: 'white',
    marginTop: -180,
    height: 210,
    marginBottom: 50,
    zIndex: 10
  },
  selector: {
    inputWeb: {
      ...Theme.styles.inputWeb,
      ...Theme.styles.flex,
      ...Theme.styles.text,
      ...Theme.styles.letterSpacing1
    },
    iconContainer: {
      position: 'relative'
    },
    viewContainer: {
      display: 'flex',
      flexDirection: 'row-reverse'
    }
  },
  formSelector: {
    inputWeb: {
      ...Theme.styles.inputWeb,
      ...Theme.styles.flex,
      ...Theme.styles.text,
      ...Theme.styles.letterSpacing1
    },
    iconContainer: {
      position: 'relative'
    },
    viewContainer: {
      borderWidth: 1,
      borderColor: Theme.palette.accent,
      padding: 10,
      display: 'flex',
      flexDirection: 'row-reverse'
    }
  }
});

