import React from 'react';
import {
  StyleSheet,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  View,
} from 'react-native';
import CheckBox from 'expo-checkbox';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import uuid from 'react-native-uuid';

import { Theme } from '../../theme';
import { translate } from '../../lang';
import { LocalizedInput } from '../common/localized-input.component';
import { HeaderComponent } from '../common/header.component';
import { ButtonComponent } from '../common/button.component';
import { Picker } from '../common/libs/picker/picker';
import { InlineInputComponent } from '../common/inline-input.component';

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: Theme.palette.background
  },
  media: {
    height: 200,
    paddingHorizontal: 8
  },
  actionColumn: {
    paddingRight: 16,
    width: 50,
    ...Theme.styles.alignEndCenter
  },
  extraChargeInput: {
    container: Platform.OS === 'web' ? {
      flex: 'none'
    } : {
      flex: 1,
      maxWidth: 80
    },
    label: {
      paddingRight: 0,
      marginTop: -7,
      letterSpacing: 0
    },
    input: {
      paddingHorizontal: 0,
    }
  }
});

/*
 * config = {
 *   key: 'size', // uuid
 *   label: { en: 'Size' },
 *   hint: { en: 'Choose a size from the list' },
 *   message: { en: 'A message to convey more information' },
 *   required: true,
 *   type: 'picker', // datepicker, number, picker, multi-picker, switch, numeric, textarea, text, phone, email
 *   options: [{ label: { en: 'M' }, value: 'M' }]
 * }
 */

export const DynamicFieldComponent = ({
  config,
  style,
  onChange,
  onDelete
}) => {
  config.key = config.key || uuid.v4();
  const _onChange = field => value => {
    config[field] = value;
    onChange(config[field]);
  };

  const addOption = () => {
    config.options = config.options || [];
    config.options.push({
      active: true,
      label: {},
      value: uuid.v4()
    });
    _onChange('options')(config.options);
  };
  const onChangeOption = (idx, field) => value => {
    config.options[idx][field] = value;
    _onChange('options')(config.options);
  };
  const removeOption = idx => {
    config.options.splice(idx, 1);
    _onChange('options')(config.options);
  };

  return (
    <View style={[styles.container, style?.container]}>
      <LocalizedInput value={config.title} onChange={_onChange('label')} label={translate('TITLE')} required />
      <LocalizedInput value={config.hint} onChange={_onChange('hint')} label={translate('HINT')} />
      <LocalizedInput value={config.message} onChange={_onChange('message')} label={translate('MESSAGE')} />
      <TouchableOpacity style={[Theme.styles.flex, Theme.styles.row, Theme.styles.paddingTop8, Theme.styles.paddingHorizontal16, Theme.styles.alignCenterStart]} onPress={() => _onChange('required')(!config.required)}>
        <CheckBox
          onValueChange={() => _onChange('required')(!config.required)}
          color={config.required ? Theme.palette.accent : null }
          style={Theme.styles.checkbox}
          value={config.required}
        />
        <Text style={[Theme.styles.width, Theme.styles.text, Theme.styles.textDark, Theme.styles.paddingLeft8, Theme.styles.description]}>
          { translate('REQUIRED') }
        </Text>
      </TouchableOpacity>
      <View style={Theme.styles.paddingHorizontal8}>
        <Picker
          placeholder={{}}
          value={config.type}
          onValueChange={_onChange('type')}
          label={translate('TYPE')}
          items={[{
            label: translate('TEXT'),
            value: 'text'
          }, {
            label: translate('NUMBER'),
            value: 'numeric'
          }, {
            label: translate('PICKER'),
            value: 'picker'
          }, {
            label: translate('MULTI_PICKER'),
            value: 'multi-picker'
          }, {
            label: translate('DATEPICKER'),
            value: 'datepicker'
          }, {
            label: translate('SWITCH'),
            value: 'switch'
          }]}
        >
          <View style={[Theme.styles.row, Theme.styles.paddingBottom8, Theme.styles.pickerInput]}>
            <View>
              <Text style={Theme.styles.inputLabel}>{translate('TYPE')}</Text>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.letterSpacing1, Theme.styles.paddingLeft8]}>{translate(config.type?.toUpperCase() || 'TEXT')}</Text>
            </View>
          </View>
        </Picker>
      </View>
      { ['picker', 'multi-picker'].includes(config.type) ? (
        <>
          <View style={Theme.styles.padding8} />
          <HeaderComponent title={translate('OPTIONS')} actionText={translate('ADD')} onPress={addOption} outlined />
          <View style={Theme.styles.paddingBottom8} />
          { config.options?.length ? (
            <View style={Theme.styles.width}>
              { config.options.map((option, idx) =>
                <View style={[Theme.styles.row, Theme.styles.flex]} key={idx}>
                  <LocalizedInput style={{container: Theme.styles.flex}} value={option.label} onChange={onChangeOption(idx, 'label')} required />
                  <InlineInputComponent label="+$" value={option.extraCharge} onChange={onChangeOption(idx, 'extraCharge')} keyboardType='numeric' inputComponent={TextInput} style={styles.extraChargeInput}/>
                  <Switch
                    style={Theme.styles.activeButton}
                    onValueChange={onChangeOption(idx, 'active')}
                    value={option.active}
                  />
                  <TouchableOpacity style={styles.actionColumn} onPress={() => removeOption(idx)}>
                    <MaterialCommunityIcons name='delete' color={Theme.palette.text} size={20} />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          ) : (
            <View style={[styles.media, Theme.styles.background, Theme.styles.row, Theme.styles.alignCenterCenter]}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.textCenter, Theme.styles.paddingHorizontal16]}>
                { translate('NO_OPTIONS_YET') }
              </Text>
            </View>
          )}
        </>
      ) : null }
      <View style={Theme.styles.padding8}>
        <ButtonComponent text={translate('DELETE_FIELD')} type='error' onPress={onDelete} outlined />
      </View>
    </View>
  );
};
