import moment from 'moment';
import React, { useContext, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Toast, { BaseToast } from 'react-native-toast-message';
import Carousel from 'react-native-snap-carousel';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { CarouselItem } from './tile';
import { getBookingContext } from './utils.service';
import { ListItemComponent } from './list-item.component';
import { StatusLabelComponent } from './status-label.component';
import { translate, getCurrentLocale } from '../../lang';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { ICONS } from '../bookings/detail';

export const HEIGHT = 60;
export const WIDTH = 340;
export const BORDER_RADIUS = 6;

const styles = StyleSheet.create({
  base: {
    flexDirection: 'row',
    width: WIDTH,
    borderRadius: BORDER_RADIUS,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.1,
    shadowRadius: BORDER_RADIUS,
    elevation: 2,
    backgroundColor: '#FFF',
    paddingTop: 10,
    paddingBottom: 10,
    height: 'auto'
  },
  leadingBorder: {
    borderLeftWidth: 5,
    borderLeftColor: Theme.palette.warning
  },
  contentContainer: {
    paddingHorizontal: 25,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start' // In case of RTL, the text will start from the right
  },
  text1: {
    fontWeight: 'bold',
    fontSize: 17,
    marginBottom: 4,
    color: Theme.palette.textDark,
    width: '100%' // Fixes: https://github.com/calintamas/react-native-toast-message/issues/130
  },
  text2: {
    fontSize: 15,
    color: Theme.palette.text,
    width: '100%' // Fixes: https://github.com/calintamas/react-native-toast-message/issues/130
  },
  actionContainer: {
    marginTop: 8,
    borderTopColor: Theme.palette.background,
    borderTopWidth: 1
  },
  actionDivider: {
    width: 1,
    height: 37,
    backgroundColor: Theme.palette.background
  },
  carouselContainer: {
    flexDirection: 'column',
  },
  carousel: {
    marginTop: -10,
    marginLeft: 0,
    marginBottom: 8,
  },
  carouselItem: {
    media: {
      borderTopRightRadius: 5
    }
  },
  booking: {
    marginLeft: -16,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: Theme.palette.backgroundLight,
  },
  bookingTitle: {
    paddingRight: 3,
  },
  bookingActionContainer: {
    marginTop: -15,
    marginBottom: -15
  }
});

export const toastConfig = {
  info: (props) => (
    <BaseToast
      {...props}
      style={{
        ...styles.base,
        borderLeftColor: Theme.palette.primary
      }}
      text1Style={styles.text1}
      text2Style={styles.text2}
      text2NumberOfLines={100}
    />
  ),

  accent: (props) => (
    <BaseToast
      {...props}
      style={{
        ...styles.base,
        borderLeftColor: Theme.palette.accent
      }}
      text1Style={styles.text1}
      text2Style={styles.text2}
      text2NumberOfLines={100}
    />
  ),

  success: (props) => (
    <BaseToast
      {...props}
      style={{
        ...styles.base,
        borderLeftColor: Theme.palette.success
      }}
      text1Style={styles.text1}
      text2Style={styles.text2}
      text2NumberOfLines={100}
    />
  ),

  warning: (props) => (
    <BaseToast
      {...props}
      style={{
        ...styles.base,
        borderLeftColor: Theme.palette.warning
      }}
      text1Style={styles.text1}
      text2Style={styles.text2}
      text2NumberOfLines={100}
    />
  ),

  error: (props) => (
    <BaseToast
      {...props}
      style={{
        ...styles.base,
        borderLeftColor: Theme.palette.error
      }}
      text1Style={styles.text1}
      text2Style={styles.text2}
      text2NumberOfLines={100}
    />
  ),

  push: ({
    props,
    text1,
    text1Style,
    text2,
    text2NumberOfLines,
    text2Style,
    onPress
  }) => {
    const [width, setWidth] = useState(Theme.width);
    const [loading, setLoading] = useState(null);
    const locale = getCurrentLocale();
    const context = useContext(Context);

    const wrapAction = (action, idx) => {
      return async () => {
        setLoading(idx);
        await action();
        setLoading(null);
      };

    };
    const carouselInterval = Math.floor((Math.random() * (props.minInterval || 5)) + 1);
    const onLayout = (event) => setWidth(event.nativeEvent.layout.width);

    if (props.bookingSubValue?.length === 1) {
      props.booking = {
        ...props.bookingSubValueContexts[0],
        ...getBookingContext({ id: props.bookingSubValue[0] }, context),
        discount: props.discount
      };
    }

    return (
      <TouchableOpacity
        onPress={() => onPress(Toast, context)}
        onLayout={onLayout}
        style={[styles.base, styles.leadingBorder, Theme.styles.paddingBottom0, props.media?.length ? styles.carouselContainer : null]}>
        { props.media?.length ? (
          <View style={styles.carousel}>
            <Carousel
              data={props.media}
              renderItem={(renderProps) => <CarouselItem {...renderProps} style={styles.carouselItem} />}
              sliderWidth={width - 5}
              itemWidth={width - 5}
              autoplay={true}
              autoplayDelay={carouselInterval * 1000}
              autoplayInterval={5000}
              loop={true}
            />
          </View>
        ) : null }
        <View
          style={Theme.styles.flex}>
          <View style={[styles.contentContainer, Theme.styles.paddingVertical6]}>
            { text1 ? (
              <Text
                style={[styles.text1, text1Style, Theme.styles.paddingBottom6]}
                numberOfLines={2}
                ellipsizeMode='tail'>
                { text1 }
              </Text>
            ) : null }
            { props.bookingSubValue?.length === 1 ? (
              <ListItemComponent
                title={props.booking.service.title[locale]}
                subtitle={props.booking.plan?.title?.[locale] || ''}
                image={ICONS[props.booking.service.icon]}
                style={{
                  title: styles.bookingTitle,
                  container: {
                    width: width - 25,
                    ...styles.booking
                  }
                }}
                ActionComponent={() => (
                  <View style={[Theme.styles.column, Theme.styles.alignEndCenter, styles.bookingActionContainer]}>
                    { props.discount ? (
                      <StatusLabelComponent status={'PENDING'} text={translate('DISCOUNT_OFF', { discount: props.discount })} icon='sale' />
                    ) : (
                      <StatusLabelComponent status={'JOIN_NOW'} />
                    ) }
                    <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, props.booking.startTime ? Theme.styles.paddingTop5 : Theme.styles.paddingTop10]}>
                      <MaterialCommunityIcons name='human-greeting' color={Theme.styles.text.color} size={16} />
                      <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize12]}> {props.booking.total || 0}{props.booking.maxAttendees ? ' / ' + props.booking.maxAttendees : ''}</Text>
                    </View>
                    { props.booking.startTime ? (
                      <View style={[Theme.styles.row, Theme.styles.alignCenterCenter, Theme.styles.paddingTop5]}>
                        <View style={[Theme.styles.row, Theme.styles.marginLeft5]}>
                          <MaterialCommunityIcons name='timer-outline' color={Theme.styles.text.color} size={16} />
                          <Text style={[Theme.styles.text, Theme.styles.letterSpacing0, Theme.styles.fontSize12]}> {moment.utc(props.booking.startTime).format('hh:mm a')}</Text>
                        </View>
                      </View>
                    ) : null }
                  </View>
                )}
              />
            ) : null }
            { text2 ? (
              <Text
                style={[styles.text2, text2Style, Theme.styles.paddingTop6]}
                numberOfLines={text2NumberOfLines || 100}
                ellipsizeMode='tail'>
                { text2 }
              </Text>
            ) : null }
          </View>
          <View style={[Theme.styles.row, styles.actionContainer]}>
            { props.actions?.map((action, idx) => (
              <View key={idx} style={[Theme.styles.row, Theme.styles.flex, Theme.styles.alignCenterStretch]}>
                <TouchableOpacity onPress={wrapAction(action.onPress, idx)} style={[Theme.styles.paddingTop10, Theme.styles.paddingBottom10, Theme.styles.flex]}>
                  { loading === idx ? (
                    <ActivityIndicator color={action && action.class && Theme.styles[action.class].color} size={10} />
                  ) : (
                    <Text style={[Theme.styles.textCenter, Theme.styles[action.class]]}> { action.text } </Text>
                  )}
                </TouchableOpacity>
                { idx < props.actions.length - 1 ? (
                  <View style={styles.actionDivider}></View>
                ) : null
                }
              </View>
            ))}
          </View>
        </View>
      </TouchableOpacity>
    );
  },
  action: ({
    props,
    text1,
    text1NumberOfLines,
    text1Style,
    text2,
    text2NumberOfLines,
    text2Style
  }) => {

    const [loading, setLoading] = useState(null);

    const wrapAction = (action, idx) => {
      return async () => {
        setLoading(idx);
        await action();
        setLoading(null);
      };
    };

    return (
      <View
        style={[styles.base, styles.leadingBorder, Theme.styles.paddingBottom0]}>
        <View
          style={Theme.styles.flex}>
          <View style={styles.contentContainer}>
            { text1 ? (
              <Text
                style={[styles.text1, text1Style]}
                numberOfLines={text1NumberOfLines || 1}
                ellipsizeMode='tail'>
                { text1 }
              </Text>
            ) : null }
            { text2 ? (
              <Text
                style={[styles.text2, text2Style]}
                numberOfLines={text2NumberOfLines || 100}
                ellipsizeMode='tail'>
                { text2 }
              </Text>
            ) : null }
          </View>
          <View style={[Theme.styles.row, styles.actionContainer]}>
            { props.actions.map((action, idx) => (
              <View key={idx} style={[Theme.styles.row, Theme.styles.flex, Theme.styles.alignCenterStretch]}>
                <TouchableOpacity onPress={wrapAction(action.onPress, idx)} style={[Theme.styles.paddingTop10, Theme.styles.paddingBottom10, Theme.styles.flex]}>
                  { loading === idx ? (
                    <ActivityIndicator color={action && action.class && Theme.styles[action.class].color} size={10} />
                  ) : (
                    <Text style={[Theme.styles.textCenter, Theme.styles[action.class]]}> { action.text } </Text>
                  )}
                </TouchableOpacity>
                { idx < props.actions.length - 1 ? (
                  <View style={styles.actionDivider}></View>
                ) : null
                }
              </View>
            ))}
          </View>
        </View>
      </View>
    );
  }
};