import React, { useContext, useCallback, useState } from 'react';
import {
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import ActionButton from 'react-native-action-button';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import * as API from '../common/api.service';
import { Theme } from '../../theme';
import { Context } from '../Context';
import { translate } from '../../lang';
import { ListItemComponent } from '../common/list-item.component';
import { AnimateService } from '../common/animate.service';
import { StatusLabelComponent } from '../common/status-label.component';

const styles = StyleSheet.create({
  newUserIcon: {
    fontSize: 20,
    height: 22,
    color: 'white',
  },
  sendNotificationIcon: {
    fontSize: 20,
    height: 22,
    color: 'white',
  }
});

export const ListComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [guests, setGuests] = useState([]);

  const getGuests = async () => {
    setLoading(true);
    if (!context.offline) {
      const response = await API.crud({
        operation: '_read',
        table: 'accounts',
        sort: 'DESC',
        query: {}
      }).finally(() => setLoading(false));
      if (!response.queued) {
        context.guests = response;
        setGuests(context.guests);
      }
      if (context.guets?.length) {
        await AsyncStorage.setItem('context.guests', JSON.stringify(context.guests || []));
      }
    } else {
      context.guests = JSON.parse(await AsyncStorage.getItem('context.guests') || '[]');
      setGuests(context.guests);
    }
  };

  const open = (item) => {
    if (route.params?.select) {
      route.params.select(item);
      navigation.setParams({ select: null });
    } else {
      navigation.navigate('GuestDetail', { guest: item });
    }
  };

  useFocusEffect(useCallback(() => { getGuests(); }, [route.params]));

  return (
    <>
      <View style={[Theme.styles.height, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper] : null]}>
        <FlatList
          data={guests}
          numColumns={Theme.numberOfColumns}
          style={Theme.XL ? Theme.styles.padding16 : null}
          contentContainerStyle={(!guests || !guests.length) && Theme.styles.flex}
          keyExtractor={(item, index) => index}
          onRefresh={getGuests}
          refreshing={loading}
          ListEmptyComponent={() => (
            <View style={[Theme.styles.flex, Theme.styles.column, Theme.styles.alignCenterCenter, Theme.styles.padding40]}>
              <Text style={[Theme.styles.text, Theme.styles.textDark, Theme.styles.fontSize20, Theme.styles.textCenter]}>{ translate('NOTHING_HERE_YET') }</Text>
              <MaterialCommunityIcons name='account-circle' color={Theme.styles.text.color} size={50} style={[Theme.styles.marginTop20, Theme.styles.marginBottom20]} />
              <Text style={[Theme.styles.text, Theme.styles.description, Theme.styles.textCenter]}>{ translate('NO_GUESTS_DESCRIPTION') }</Text>
            </View>
          )}
          renderItem={({ item, index }) => (
            <AnimateService
              properties={['opacity', 'left', 'top']}
              style={Theme.XL ? Theme.styles.flex48 : null}
              delay={index * 50}
              duration={500}
              Component={() => (
                <TouchableOpacity onPress={() => open(item)} >
                  <ListItemComponent
                    title={item.fullName}
                    subtitle={item.roomNumber || translate('NO_ROOM_NUMBER')}
                    image={item.image ? {
                      uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID.replace('.admin', '') + (context.ref ? `.${context.ref}` : ''), item.image].join('/')
                    } : require('../../theme/assets/ProfilePlaceholder.png')}
                    ActionComponent={() => (
                      <StatusLabelComponent status={item.status} />
                    )}
                  />
                </TouchableOpacity>
              )}
            />
          )}
        />
      </View>
      { !route.params?.select
        ? (
          <ActionButton buttonColor={Theme.palette.accent} hideShadow={Platform.OS === 'web'}>
            <ActionButton.Item buttonColor={Theme.palette.activity} title={translate('CREATE_USER')} onPress={() => navigation.navigate('CreateGuest') }>
              <MaterialCommunityIcons name="human-greeting" style={styles.newUserIcon} />
            </ActionButton.Item>
            <ActionButton.Item buttonColor={Theme.palette.notification} title={translate('SEND_NOTIFICATION')} onPress={() => navigation.navigate('SendNotification')}>
              <MaterialCommunityIcons name="bullhorn" style={styles.sendNotificationIcon} />
            </ActionButton.Item>
          </ActionButton>
        ) : null
      }
    </>
  );
};

