import React, { useContext, useState } from 'react';
import {
  StyleSheet,
  View,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as API from '../../common/api.service';
import { Theme } from '../../../theme';
import { Context, defaultContext } from '../../Context';
import { translate } from '../../../lang';
import { ListItemComponent } from '../../common/list-item.component';
import { StatusLabelComponent } from '../../common/status-label.component';
import { ButtonComponent } from '../../common/button.component';
import { AccountInfoComponent } from './info';

const styles = StyleSheet.create({
  primaryBackground: {
    backgroundColor: Theme.palette.primary
  }
});

export const AccountComponent = ({ navigation, route }) => {
  const context = useContext(Context);
  const [loading, setLoading] = useState(false);

  const logOut = async () => {
    setLoading(true);
    await Promise.all([
      AsyncStorage.removeItem('context.user'),
      AsyncStorage.removeItem('userName'),
      AsyncStorage.removeItem('password'),
    ]);
    navigation.navigate('Login', { reload: true });
    setLoading(false);
    for (const key in context) {
      delete context[key];
    }
    for (const defaultKey in defaultContext) {
      context[defaultKey] = defaultContext[defaultKey];
    }
    API.setContext(context);
  };

  route.params = {
    guest: context.user || {}
  };

  return (
    <View style={Theme.styles.flex}>
      <View style={styles.primaryBackground}>
        <View style={Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerExpanded, Theme.styles.paddingVertical0] : null}>
          <ListItemComponent
            title={context.user?.fullName || translate('GUEST_USER')}
            subtitle={context.user?.roomNumber || translate('NO_ROOM_NUMBER')}
            image={context.user?.image ? {
              uri: [process.env.CLOUDFRONT_URL, process.env.BUNDLE_ID.replace('.admin', '') + (context.ref ? `.${context.ref}` : ''), context.user?.image].join('/')
            } : require('../../../theme/assets/ProfilePlaceholder.png')}
            header={true}
            ActionComponent={() => {
              if (context.user && !context.user.guest) {
                return <StatusLabelComponent status={context.user?.status || 'PENDING'} />;
              } else {
                return (<View></View>);
              }
            }}
          />
        </View>
      </View>
      <View style={[Theme.styles.flex, Theme.XL ? [Theme.styles.webContainer, Theme.styles.webContainerWrapper, Theme.styles.padding32, Theme.styles.paddingBottom16] : null]}>
        <AccountInfoComponent navigation={navigation} route={route} onDelete={!context.user.guest && process.env.ALLOW_SIGNUP ? logOut : false} onSave={!context.user.guest ? () => navigation.navigate('List') : null}/>
        <ButtonComponent onPress={logOut} text={translate('LOG_OUT')} loading={loading} outlined={!!Theme.XL}/>
      </View>
    </View>
  );
};
