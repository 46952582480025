import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { translate } from '../../lang';
import { Context } from '../Context';
import { GroupsComponent } from './groups.js';
import { ListComponent } from './list';
import { DetailComponent } from './detail';
import { CalendarComponent } from './calendar';
import { AttendeesComponent } from './attendees';
import { TemplateComponent } from './template';
import { AdditionalInformationComponent } from './additional-info';
import { MenuComponent } from './menu';
import { OrderComponent } from './order';
import { tabScreenOptions } from '../common/utils.service';

const Stack = createNativeStackNavigator();

export const ServicesComponent = ({ route, type }) => {
  const context = useContext(Context);
  const groups = context[`${type}Groups`]?.data || [];
  return (
    <Stack.Navigator
      screenOptions={tabScreenOptions[Platform.OS] || tabScreenOptions.mobile}
    >
      <Stack.Screen
        name={`${type}ServiceGroups`}
        options={{ title: translate(type.toUpperCase() + '_TITLE') }}
      >
        { ({ navigation }) => <GroupsComponent navigation={navigation} route={route} groups={groups} type={type} /> }
      </Stack.Screen>
      <Stack.Screen
        name={`${type}ServiceList`}
        component={ListComponent}
        options={{ title: translate('LIST') }}
      />
      <Stack.Screen
        name={`${type}ServiceDetail`}
        component={DetailComponent}
        options={{ title: translate('DETAIL') }}
      />
      <Stack.Screen
        name={`${type}ServiceMenu`}
        component={MenuComponent}
        options={{ title: translate('MENU') }}
      />
      <Stack.Screen
        name={`${type}ServiceCalendar`}
        component={CalendarComponent}
        options={{ title: translate('DATE_AND_TIME') }}
      />
      <Stack.Screen
        name={`${type}ServiceAttendees`}
        component={AttendeesComponent}
        options={{ title: translate('ATTENDEES') }}
      />
      <Stack.Screen
        name={`${type}ServiceTemplate`}
        component={TemplateComponent}
        options={{ title: translate('DETAILS') }}
      />
      <Stack.Screen
        name={`${type}ServiceAdditionalInfo`}
        component={AdditionalInformationComponent}
        options={{ title: translate('ADDITIONAL_INFORMATION') }}
      />
      <Stack.Screen
        name={`${type}ServiceOrder`}
        component={OrderComponent}
        options={{ title: translate('ORDER_SUMMARY') }}
      />
    </Stack.Navigator>
  );
};
